import React, { Component } from "react";
import { Accordion, Icon } from "semantic-ui-react";

class BirthingBuddyAccordion extends Component {
	state = { activeIndex: -1 };

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	render() {
		const { activeIndex } = this.state;

		return (
			<Accordion>
				<Accordion.Title
					active={activeIndex === 0}
					index={0}
					onClick={this.handleClick}
				>
					<Icon name="dropdown" />
					<b>What is a parenting buddy and what is their function?</b>
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 0}>
					<p>
						A parenting buddy is someone you choose to help you share your parenting
						journey and communicate with us. It could be a spouse, friend or family
						member – just someone who can send replies to our SMSes so that you don’t
						have to ☺. And can also follow your journey with you on the microsite. You
						will get to choose your parenting buddy on the microsite anytime from
						registration.
					</p>
					<br />
				</Accordion.Content>
			</Accordion>
		);
	}
}

export default BirthingBuddyAccordion;
