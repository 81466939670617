import React, { useRef, useEffect } from 'react';
import { Form, Label } from 'semantic-ui-react';


const TextAreaInput = ({
                         input,
                         rows,
                         width,
                         labelWidth,
                         label,
                         setFocus,
                         placeholder,
                         disabled,
                         padding,
                         meta: { touched, error, active }
                       }) => {
  const labelWidthpx = labelWidth ? `${ labelWidth }px` : '170px';
  const widthpx = width ? `${ width }px` : '100%';

  const inputEl = useRef(null);

  useEffect(() => {
    if (setFocus) {
      inputEl.current.focus();
    }
  }, [setFocus]);

  const divStyle = {
    padding: padding ? padding : '10px 10px 0 10px',
    position: 'relative'
  };

  return (
    <div style={ divStyle }>
      <Form.Field>
        <div
          className={ `ui labeled input form-element ${ active &&
          ' element-focus' } ${ touched && error && ' element-error' }` }
          style={ { width: '100%' } }
        >
          { labelWidth !== '0' && (
            <div className='ui label label' style={ { width: labelWidthpx } }>
              { label }
            </div>
          ) }
          <textarea
            ref={ setFocus ? inputEl : null }
            { ...input }
            rows={ rows || 4 }
            style={ {
              maxWidth: widthpx,
              backgroundColor: disabled ? 'rgb(245,245,245)' : 'white',
              color: disabled ? 'rgb(180,180,180)' : 'black'
            } }
            placeholder={ placeholder }
            readOnly={ disabled }
          />
        </div>
        { touched && error && (
          <Label basic color='red' pointing='above'>
            { error }
          </Label>
        ) }
      </Form.Field>
    </div>
  );
};

export default TextAreaInput;
