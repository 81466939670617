import React, { useState } from 'react';
import { Dropdown, Form, Label, Popup } from 'semantic-ui-react';


const SelectInput = ({
                       input,
                       width,
                       fluid,
                       labelWidth,
                       label,
                       multiple,
                       options,
                       disabled,
                       padding,
                       search,
                       placeholder,
                       allowAdditions,
                       handleAddition,
                       meta: { dirty, error, active },
                       toolTipText,
                       noResultsMessage,
					   reasonChange
                     }) => {
  const labelWidthpx = labelWidth ? `${ labelWidth }px` : '120px';

  const [touched, hasTouched] = useState(false);

  const divStyle = {
    padding: padding ? padding : '10px 10px 0 10px',
    position: 'relative'
  };
  
  if (input.name === "DeliveryTypeReasonID" ){
		reasonChange(input.value)
	
  }

  return (
    <div style={ divStyle }>
      <Form.Field>
        <div
          className={ `ui labeled input form-element ${ active &&
          ' element-focus' } ${ touched && error && ' element-error' }` }
          style={ { width: width ? `${ width }px` : '100%' } }
        >
          { labelWidth !== '0' && (
            <div className='ui label label' style={ { width: labelWidthpx } }>
              { label }
            </div>
          ) }
          { toolTipText &&
          <Popup
            trigger={
              <Dropdown
                value={ input.value || null }
                onChange={ (e, data) => {
                  input.onChange(data.value);
                  hasTouched(true);
                } }
                onAddItem={ handleAddition }
                options={ options }
                multiple={ multiple || false }
                clearable
                selection 
                onFocus={ input.onFocus }
                fluid={ fluid }
                disabled={ disabled }
                search={ search }
                allowAdditions={ allowAdditions || false }
                placeholder={ placeholder }
                style={ { backgroundColor: disabled ? 'rgb(245,245,245)' : 'white' } }
                noResultsMessage={ noResultsMessage || null }
              />
            }
            content={ toolTipText }
            position='left center'
            inverted
          />
          }
          { !toolTipText &&
          <Dropdown
            value={ input.value || null }
            onChange={ (e, data) => {
              input.onChange(data.value);
              hasTouched(true);
            } }
            onAddItem={ handleAddition }
            options={ options }
            multiple={ multiple || false }
            clearable
            selection
            onFocus={ input.onFocus }
            fluid={ fluid }
            disabled={ disabled }
            search={ search }
            allowAdditions={ allowAdditions || false }
            placeholder={ placeholder }
            style={ { backgroundColor: disabled ? 'rgb(245,245,245)' : 'white' } }
          />
          }

        </div>
        { touched && error && (
          <Label basic color='red' pointing='above'>
            { error }
          </Label>
        ) }
      </Form.Field>
    </div>
  );
};

export default SelectInput;
