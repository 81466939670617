import {
	PERSON_LOADING,
	PERSON_LOADING_COMPLETE,
	LOAD_PERSON,
	PERSON_SUBMITTING,
	PERSON_SUBMITTING_COMPLETED,
	CHILD_SUBMITTING,
	CHILD_SUBMITTING_COMPLETED,
	LOAD_CHILD
  } from "./sideBarConstents";
  import { httpGet, httpPost } from "../../../../app/common/utils/http";
  import { toastr } from "react-redux-toastr";
  import { convertDate } from "../../../../app/common/utils/common";
  
  export const loadPerson = () => async dispatch => {
	try {
	  await dispatch({
		type: PERSON_LOADING
	  });
	  const person = await httpGet(`Person/GetPersonDetails`);
	  await dispatch({
		type: LOAD_PERSON,
		payload: { ...person }
	  });
	} catch (error) {
	  //toastr.error('Error', 'Could not load your details');
	} finally {
	  await dispatch({ type: PERSON_LOADING_COMPLETE });
	}
  };
  
  export const savePerson = sendValues => async (dispatch, getState) => {
	
	await dispatch({ type: PERSON_SUBMITTING });
	try {
	  sendValues.ExpectedDueDate = convertDate(sendValues.ExpectedDueDate);
	  sendValues.BirthingBuddyDOB = convertDate(sendValues.BirthingBuddyDOB);
	  sendValues.BabyDOB = convertDate(sendValues.BabyDOB);
	  sendValues.DOB =convertDate(sendValues.DOB);
	  
  
	  await httpPost("Person/SavePersonProfile",sendValues);
	  await dispatch({ type: LOAD_PERSON });
	  toastr.success("Success", "Your details have been updated");
	} catch (error) {
	  toastr.error("Error", error);
	  throw error;
	} finally {
	  await dispatch({ type: PERSON_SUBMITTING_COMPLETED });
	}
  };
  
  export const saveChild = child => async (dispatch, getState) =>{
	
	await dispatch({type: CHILD_SUBMITTING});
	try{
	  await httpPost("Person/personChildProfileSubmit", child);
	  await dispatch({type: LOAD_CHILD});
	}
	catch(error){
		toastr.error('Error', error)
	}
	finally{
	  await dispatch({type: CHILD_SUBMITTING_COMPLETED});
	}
  }
  