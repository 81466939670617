import React, { Fragment, Component } from "react";
import { Header, Icon, Segment, Accordion } from "semantic-ui-react";
import { isMobileView } from "../../app/common/utils/common";
import FullBanner from "../pageContent/FullBanner";

const headerStyle = {
	color: "#0cb2a6",
	fontSize: isMobileView() ? 18 : 32,
	// textShadow: "0.25px 0.25px black"
};

const foodVideos = [
	{
		title: "3 Ingredient, Chocolate Microwave Mug Cakes",
		video:
			"https://drive.google.com/file/d/1nH3vsNs3bU6GgCnG7_5uvRzBpzoq7nW6/preview",
		key: "Video 1",
	},
	{
		title: "Smoothie Bowls, Three Ways",
		video:
			"https://drive.google.com/file/d/1yZMfRxGJkcl-F-Hf_h9Ab1ehLLWBLd0Q/preview",
		key: "Video 2",
	},
	{
		title: "How to make lactation cookies",
		video:
			"https://drive.google.com/file/d/1CZUzeKjl2OCsYC_NaP5G42aYgEbnp7Hg/preview",
		key: "Video 3",
	},
	{
		title: "Pulled Chicken 3 Ways",
		video:
			"https://drive.google.com/file/d/1Bf3Hhu5IG94mcStFG8ery6jkjmBB9TNf/preview",
		key: "Video 4",
	},
];
const medicalVideos = [
	{
		title: "CPR for infants - presented by Survival CPR",
		video:
			"https://drive.google.com/file/d/1RVxAA3OVsZIr0coWjFoFlI4Ka7ZfQpvr/preview",
		key: "Video 1",
	},
	{
		title:
			"Postnatal depression- Risk factors, symptoms & why we need to talk about it - presented by psychologist Nicole Canin",
		video:
			"https://drive.google.com/file/d/1PiGVZ7puTiKthMWwpNlQVzdOi-Oq8IDy/preview",
		key: "Video 2",
	},
	{
		title:
			"Treating common illnesses in children & when you need to see a doctor - presented by Dr Enrico Maraschin",
		video:
			"https://drive.google.com/file/d/1fGIwu7p0mcnWn0IDgxzt66t2aVeXQSOr/preview",
		key: "Video 3",
	},
	{
		title:
			"Treating fevers in children & babies at home - presented by Dr Enrico Maraschin",
		video:
			"https://drive.google.com/file/d/1TrHWaow0Wudj2Nww_xc0z1SoVXxFKYQQ/preview",
		key: "Video 4",
	},
	{
		title:
			"Vaccines- separating fact from fiction - presented by #MomDoc Dr Carmen",
		video:
			"https://drive.google.com/file/d/19uReRHi-waa6zvddGRd9x130REfwjJ7u/preview",
		key: "Video 5",
	},
	{
		title: "Your baby's development week by week",
		video:
			"https://drive.google.com/file/d/1IaWF-tb_4bNc04Wv3VPIqucsz6CtkVFv/preview",
		key: "Video 20",
	},
];
const adviceVideos = [
	{
		title: "The best advice for new moms",
		video:
			"https://drive.google.com/file/d/1RQCBNfopFhdtVFCGZf8v_ry0aneiYvsg/preview",
		key: "Video 1",
	},
	{
		title: "An at-home workout for moms - presented by Juls Meintjes of JEFF",
		video:
			"https://drive.google.com/file/d/12SKi1jXXWj2PeBUEuZfJFS1ou7Z_tZ-A/preview",
		key: "Video 2",
	},

	{
		title: "Embarrassing birth & pregnancy questions answered",
		video:
			"https://drive.google.com/file/d/17Rk1QBsFrVRPbZJA_cmLcMFyNZnlxp5-/preview",
		key: "Video 3",
	},

	{
		title: "How to naturally boost your breast milk supply ",
		video:
			"https://drive.google.com/file/d/1cILtTK_gsJj6seMeprNj9jT5FhSZ6AV0/preview",
		key: "Video 4",
	},

	{
		title: "Sex after having a baby",
		video:
			"https://drive.google.com/file/d/1gdNfQjZYqRbTWTqSsalCVZep_kyK-FMm/preview",
		key: "Video 5",
	},
	{
		title:
			"Sleep training your baby - presented by Jolandi Becker from Good Night Baby",
		video:
			"https://drive.google.com/file/d/113DCD8hLX78XEc5NvNqL6ryE-KSA_pjN/preview",
		key: "Video 6",
	},
	{
		title:
			"The difference between a doula & a midwife - presented by doula & HypnoBirthing educator Tamryn Schmahl",
		video:
			"https://drive.google.com/file/d/1VyheqUE9ShSqrBBJJ1Dp73W83Hqaku0H/preview",
		key: "Video 7",
	},
	{
		title: "The Dunstan Method explained",
		video:
			"https://drive.google.com/file/d/1myJBPPBWE_ORovw0jFCIyO2Qk9MxSUrB/preview",
		key: "Video 8",
	},
	{
		title: "The most-asked breastfeeding questions answered",
		video:
			"https://drive.google.com/file/d/1TSgvwTR0fnSAW9e9BXUjBJBqkPIEIRWd/preview",
		key: "Video ",
	},

	{
		title: "What is HypnoBirthing®",
		video:
			"https://drive.google.com/file/d/1mBeJRpG6qkpqBOyormEtuHUVCTZKS0Ni/preview",
		key: "Video ",
	},
];

class VideoPage extends Component {
	backClickHandler = () => {
		this.props.history.push("/");
	};

	state = { activeIndex: -1 };

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};
	render() {
		const { activeIndex } = this.state;
		return (
			<Fragment>
				<container className='area'>
					<FullBanner />
				</container>
				<Segment>
					<container className='area'>
						<h1 className='title'>Resources</h1>
						<div className='accordion'>
							<Accordion>
								<Accordion.Title
									active={activeIndex === 0}
									index={0}
									onClick={this.handleClick}
								>
									<Icon name='dropdown' />
									<b className='mainHeadingVideos'>Food</b>
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 0}>
									{foodVideos.map((foodVideos, index) => (
										<Segment textAlign='center'>
											<Header textAlign='center' style={headerStyle}>
												{foodVideos.title}
											</Header>
											{!isMobileView() && (
												<iframe
													src={foodVideos.video}
													width='750'
													height='450'
													allowFullScreen={true}
												></iframe>
											)}
											{isMobileView() && (
												<iframe
													src={foodVideos.video}
													width='250'
													height='300'
													allowFullScreen={true}
												></iframe>
											)}
										</Segment>
									))}
								</Accordion.Content>

								<Accordion.Title
									active={activeIndex === 1}
									index={1}
									onClick={this.handleClick}
								>
									<Icon name='dropdown' />
									<b className='mainHeadingVideos'>Medical</b>
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 1}>
									{medicalVideos.map((medicalVideos, index) => (
										<Segment textAlign='center'>
											<Header textAlign='center' style={headerStyle}>
												{medicalVideos.title}
											</Header>
											{!isMobileView() && (
												<iframe
													src={medicalVideos.video}
													width='750'
													height='450'
													allowFullScreen={true}
												></iframe>
											)}
											{isMobileView() && (
												<iframe
													src={medicalVideos.video}
													width='250'
													height='300'
													allowFullScreen={true}
												></iframe>
											)}
										</Segment>
									))}
								</Accordion.Content>

								<Accordion.Title
									active={activeIndex === 2}
									index={2}
									onClick={this.handleClick}
								>
									<Icon name='dropdown' />
									<b className='mainHeadingVideos'>General Advice</b>
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 2}>
									{adviceVideos.map((adviceVideos, index) => (
										<Segment textAlign='center'>
											<Header textAlign='center' style={headerStyle}>
												{adviceVideos.title}
											</Header>
											{!isMobileView() && (
												<iframe
													src={adviceVideos.video}
													width='750'
													height='450'
													allowFullScreen={true}
												></iframe>
											)}
											{isMobileView() && (
												<iframe
													src={adviceVideos.video}
													width='250'
													height='300'
													allowFullScreen={true}
												></iframe>
											)}
										</Segment>
									))}
								</Accordion.Content>
							</Accordion>
						</div>
					</container>
				</Segment>
			</Fragment>
		);
	}
}
export default VideoPage;
