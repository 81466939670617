import React, { Component } from "react";
import { Accordion, Icon, Image } from "semantic-ui-react";

class PebbleAccordion extends Component {
	state = { activeIndex: -1 };

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	render() {
		const { activeIndex } = this.state;

		return (
			<Accordion>
				<Accordion.Title
					active={activeIndex === 1}
					index={1}
					onClick={this.handleClick}
				>
					<Icon name="dropdown" />
					<b className="FAQHeading">Baby Programme</b>
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 1}>
					<p>
						<b>Is the ProfmedTums2Tots BabyYumYum portal/programme free?</b>
						<br />
						<br />
						Yes. It is included in your ProfmedTums2Tots Programme and certain
						benefits are applicable to you depending on which plan you are on.
						<br />
						<br />
					</p>
					<p>
						<b>How do I access the portal/programme? </b>
						<br />
						<br />
						Once you have alerted Profmed that you are expecting a baby and have given
						them consent that you would like access to the portal (and the amazing
						benefits included), we will automatically activate your access to the
						portal and you will be sent a message from the ProfmedTums2Tots BabyYumYum
						team.
						<br />
						<br />
					</p>
					<p>
						<b>Can I opt out?</b>
						<br />
						<br />
						Yes, you may opt out at any time, but you will miss out on benefits should
						you wish to opt out of access to the portal.
						<br />
						<br />
					</p>
					<p>
						<b>Can I pick and choose what I want from the portal/programme?</b>
						<br />
						<br />
						Yes, you can, the benefit choice is entirely yours! As a Profmed member
						you receive a specific number of pebbles based on your medical plan. The
						benefits available can be redeemed by exchanging the specified number of
						pebbles. Once you select the redeem button at the bottom of the page, the
						benefit is allocated and where applicable delivery will be arranged. The
						pebble solution allows you to choose what you really want and not just
						take what you are given.
						<br />
						<br />
					</p>
					<p>
						<b>How long does the portal/programme run for?</b>
						<br />
						<br />
						Until your baby turns 2 years old. <br />
						<br />
					</p>
					<p>
						<b>How long does the pebble solution of ProfmedTums2Tots run for?</b>
						<br />
						<br />
						Each member has their pebble allocation for a period of 12 months from the
						date of registration on the programme. <br />
						<br />
					</p>
					<p>
						<b>
							Once my baby is born, will the ProfmedTums2Tots BabyYumYum
							portal/programme register the birth for me (with Profmed and at the
							Department of Health)?
						</b>
						<br />
						<br />
						No, you will need to register the birth of your baby with Home Affairs,
						but the ProfmedTums2Tots BabyYumYum portal/programme will remind you what
						to take with to hospital (when the birth happens), so that it can be done
						at the hospital for you. You will also need to alert Profmed that the
						birth has taken place.
						<br />
						<br />
					</p>
					<p>
						<b>
							I have informed the ProfmedTums2Tots BabyYumYum portal/programme that my
							baby is born. Do I still need to tell Profmed?
						</b>
						<br />
						<br />
						Yes, you must communicate with Profmed directly that the birth of your
						baby has occurred and register the birth with Profmed directly. We cannot
						do this for you.
						<br />
						<br />
					</p>
					<p>
						<b>If I have any claims to process who do I send them to?</b>
						<br />
						<br />
						You must send all claims directly to Profmed for processing.
						<br />
						<br />
					</p>
					<p>
						<b>
							If I have a medical emergency, do I contact ProfmedTums2Tots BabyYumYum?
						</b>
						<br />
						<br />
						No. You must contact your nearest emergency assistance provider.
						ProfmedTums2Tots BabyYumYum can only offer advice or refer your questions
						to experts. <br />
						<br />
					</p>
					<p>
						<b>How do I claim my Swag Bag?</b>
						<br />
						<br />
						We know how excited you are about receiving your ultra-stylish Swag Bag;
						if you opt to select the mommy Swag bag as one of your pebble solution
						products. You will log onto the microsite and use 3 of your pebbles from
						your allocation, these 3 pebbles will then be deducted and you will be
						alerted. We will ask you for and confirm your delivery address. We will
						also let you know once your bag has been dispatched. You may only redeem
						one of the Swag Bags.
						<br />
						<br />
					</p>
					<p>
						<b>How do I claim my Gift Hamper?</b>
						<br />
						<br />
						If you opt to select the gift hamper as one of your pebble solution
						products. You will log onto the microsite and use 3 of your pebbles from
						your allocation, these 3 pebbles will then be deducted and you will be
						alerted. We will ask you for and confirm your delivery address. We will
						also let you know once your gift hamper has been dispatched. You may only
						redeem one of the three gift hampers.
						<br />
						<br />
					</p>
					<p>
						<b>How do I claim the baby and child handbook? </b>
						<br />
						<br />
						If you opt to select the Baby and Child Care Handbook as one of your
						pebble solution products. You will log onto the microsite and use 1 of
						your pebbles from your allocation, this pebble will then be deducted and
						you will be alerted. We will ask you for and confirm your delivery
						address. We will also let you know once the handbook has been dispatched.
						<br />
						<br />
					</p>
					<p>
						<b>How do I claim for my meal voucher?</b>
						<br />
						<br />
						It is suggested that you use this benefit after the birth of your baby. If
						you opt to select the Mr D meal voucher as one of your pebble solution
						products. You will log onto the microsite and use 1 of your pebbles from
						your allocation, this pebble will then be deduced and you will be alerted.
						You will be prompted to download the Mr D app{" "}
						<a
							href=" https://www.mrdfood.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							{" "}
							https://www.mrdfood.com/{" "}
						</a>
						and you will be sent your meal voucher code (this is the code you use when
						you checkout on the app) via SMS. Your voucher is worth R350 and can only
						be redeemed with one order and is valid for 6 months from the date of
						issue.
						<br />
						<br />
					</p>
					<p>
						<b>How do I give back?</b>
						<br />
						<br />
						If you opt to select Giving back as one of your pebble solution products.
						You will log onto the microsite and use 1 of your pebbles from your
						allocation, this pebble will then be deducted and you will be alerted. The
						value of your pebble contribution will be given to the Glow Movement; a
						non-governmental organization (NGO) that supports and empowers women
						through various programmes.{" "}
						<b>
							Please note that ProActive plans do not qualify for the Giving back
							benefit.
						</b>
						<br />
						<br />
					</p>
					<p>
						<b>How do I access the Momcierge Club?</b>
						<br />
						<br />
						If you opt to select the Momcierge Club as one of your pebble solution
						products. You will log onto the microsite and use 1 of your pebbles from
						your allocation, this pebble will then be deducted and you will be
						alerted. You will be notified of your membership to this exclusive VIP
						Club and your amazing benefits that come with it.
						<br />
						<br />
					</p>
					<p>
						<b>How do I book my nurse for the baby’s vaccine at 6 weeks?</b>
						<br />
						<br />
						You or your birthing buddy will need to let ProfmedTum2Tots BabyYumYum
						know that the baby has been born via the microsite, SMS or the call centre
						on <a href="tel:087 092 9605 "> 087 092 9605 </a> to unlock this benefit.
						BabyYumYum will also send the mommy an SMS and ask on the microsite after
						birth if you want to setup the appointment, we will also call you if you
						do not respond to the SMS. After the above process/es, you will then
						receive an SMS asking if you are ready for a nurse visit. Once you have
						responded yes, you will be contacted to set up your appointment.The 6-week
						nurse visit in outlying areas is subject to the availability of nurses.
						<br />
						<br />
					</p>
				</Accordion.Content>
			</Accordion>
		);
	}
}

export default PebbleAccordion;
