import React, { Component, Fragment } from "react";
import { Segment, Form, Button } from "semantic-ui-react";
import {
	loadPerson,
	savePerson,
	saveChild,
} from "../../features/nav/Sidebar/data/sideBarActions";
import FullBanner from "../pageContent/FullBanner";
import "./MyProfile.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import MyProfileFields from "./MyProfileFields";
import { confirm } from "../../app/functionModals/functionModalsActions";
import LoadingComponent from "../../app/layout/LoadingComponent";
import ScrollTop from "../scrollTop/ScrollTop";
import PersonalInformationModal from "./myProfileModals/PersonalInformationModal";
import ChildInformationModal from "./myProfileModals/ChildInformationModal";
import DeliveryAddressModal from "./myProfileModals/DeliveryAddressModal";
import BirthingBuddyModal from "./myProfileModals/BirthingBuddyModal";
import scrollToComponent from "react-scroll-to-component";
import { IoLogoWindows } from "react-icons/io";
import BirthingBuddyPersonlInformation from "./myProfileModals/BirthingBuddyPersonalInformationModel";

// import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff';

const mapState = (state) => ({
	loading: state.sideBar.loading,
	person: state.sideBar.person,
	initialValues: state.sideBar.person,
});

const actions = {
	loadPerson,
	confirm,
	savePerson,
	saveChild,
};

class MyProfile extends Component {
	state = {
		userView: "",
	};

	async componentDidMount() {
		await this.props.loadPerson();

		const { person } = this.props;
		console.log(person);

		this.setState({ userView: person.PersonType });
		// this.setState({ userView: 'M' });
		//   this.setState({ userView: 'BB' });
	}

	//  async componentDidUpdate(){
	//   const {person} = this.props;
	//   this.setState({hasBaby: person.isBabyBornYN})
	//  }

	handleSavePerson = async (values) => {
		// try {
		// 	await this.props.savePerson(sendValues);
		// 	await this.props.loadPerson();
		// 	this.reset();
		// } catch (error) {
		// 	// An error occured - the toast was thrown in the action
		// } finally {
		// 	this.props.loadPerson();
		// }
	};

	handleCancel = () => {
		const { reset } = this.props;
		// this.props.confirm(
		//   'Are you sure you want to cancel your changes?',
		//   {
		//     onOk: () => {
		//       reset();
		//     }
		//   },
		//   'Yes',
		//   'No'
		// );
		window.location.reload();
		reset();
	};

	isLoading = () => {
		const { loading } = this.props;
		if (loading) {
			return true;
		}

		return false;
	};

	render() {
		const {
			person,
			loading,
			initialValues,
			submitting,
			handleSubmit,
			pristine,
		} = this.props;
		//console.log(person);

		// let bedd = new Date(person.BEDD);
		// let  weeks = Math.floor(40 - (localStorage.getItem("WeeksPregnant") / 7));

		// this.setState({cheacked: initialValues.isBabyBornYN});

		// if (person.isBabyBornYN === true) {
		//   this.setState({ hasBaby: true });
		// }

		return (
			<Fragment className="mainDivMyProfile">
				<FullBanner />
				{this.isLoading() && (
					<Segment attached style={{ minHeight: 418 }}>
						<LoadingComponent />
					</Segment>
				)}
				{!this.isLoading() && (
					<Form autoComplete="off" onSubmit={handleSubmit(this.handleSavePerson)}>
						<div style={{ paddingleft: "55px" }}>
							{this.state.userView === "M" && (
								<div ref={0}>
									<PersonalInformationModal userView={this.state.userView} />
								</div>
							)}
							{this.state.userView === "BB" && (
								<div ref={0}>
									<BirthingBuddyPersonlInformation userView={this.state.userView} />
								</div>
							)}
							<br />
							<div>
								{person !== undefined && (
									<MyProfileFields
										loading={loading}
										event={person._event}
										person={person}
										userView={this.state.userView}
									/>
								)}
							</div>
						</div>
					</Form>
				)}
				<ScrollTop />
			</Fragment>
		);
	}
}

export default compose(
	connect(mapState, actions),
	reduxForm({
		form: "MyProfile",
		enableReinitialize: true,
		destroyOnUnmount: false,
	})
)(MyProfile);
