import React, { Component } from "react";
import "./TermsConditionsPage.css";
import FullBanner from "../pageContent/FullBanner";
import { Accordion, Icon, Segment } from "semantic-ui-react";
import TermsConditionsText from "./TermsConditionsText";
import PrivacyPolicyText from "./PrivacyPolicyText";
class TermsConditions extends Component {
	state = { activeIndex: -1 };

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	render() {
		const { activeIndex } = this.state;
		return (
			<div>
				<container className='area'>
					<FullBanner />
				</container>
				<br />
				<Segment>
					<p className='title'>
						<b>Terms & Conditions</b>
						<br />
					</p>
					<Accordion>
						<Accordion.Title
							active={activeIndex === 0}
							index={0}
							onClick={this.handleClick}
						>
							<div className='privacyPolicy'>
								<Icon name='dropdown' />
								<b>BabyYumYum Privacy Policy</b>
							</div>
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 0}>
							<PrivacyPolicyText />
						</Accordion.Content>

						<Accordion.Title
							active={activeIndex === 1}
							index={1}
							onClick={this.handleClick}
						>
							<div className='mainHeadingTermsConditions'>
								<Icon name='dropdown' />
								<b>
									Terms and Conditions of Use – ProfmedTums2Tots BabyYumYum
									Portal
								</b>
							</div>
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 1}>
							<TermsConditionsText />
						</Accordion.Content>
					</Accordion>
				</Segment>
			</div>
		);
	}
}

export default TermsConditions;
