import React, { Component } from "react";
import { Segment, Grid } from "semantic-ui-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import DateInputMyProfile from "../../../app/common/form/DateInputMyProfile";
import {
  loadPerson,
  savePerson,
} from "../../../features/nav/Sidebar/data/sideBarActions";

const mapState = (state) => ({
  loading: state.sideBar.loading,
  person: state.sideBar.person,
  initialValues: state.sideBar.person,
});

const actions = {
  loadPerson,
};

class PersonalInformationModal extends Component {
  render() {
    const editable = false;
    const { userView } = this.props;
    return (
      <div className="mainDivMyProfile">
        <Segment>
          <p className="title">My Personal Information</p>
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column width={8}>
                <Field
                  name="FirstName"
                  component={TextInput}
                  label="First Name"
                  setFocus={editable}
                  disabled={!editable}
                />
              </Grid.Column>

              <Grid.Column width={8}>
                <Field
                  name="LastName"
                  component={TextInput}
                  label="Last Name"
                  disabled={!editable}
                />
              </Grid.Column>

              <Grid.Column width={8}>
                <Field
                  name="DOB"
                  component={DateInputMyProfile}
                  label="Date of Birth"
                  disabled={!editable}
                  dateFormat={"dd LLL yyyy"}
                  minDate={new Date().setFullYear(
                    new Date().getFullYear() - 100
                  )}
                  maxDate={new Date().setFullYear(
                    new Date().getFullYear() - 10
                  )}
                />
              </Grid.Column>
            
                <Grid.Column>
                  <Field
                    name="SchemeOption"
                    component={TextInput}
                    label="Medical Scheme Option"
                    disabled={!editable}
                  />
                </Grid.Column>
              
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

export default compose(
  connect(mapState, actions)
  // reduxForm({
  //   form: "PersonalInformationModal",
  //   enableReinitialize: true,
  //   destroyOnUnmount: false
  // })
)(PersonalInformationModal);
