import React, { Component } from "react";
import { Accordion, Icon, Image } from "semantic-ui-react";

class PebbleAccordion extends Component {
	state = { activeIndex: -1 };

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	render() {
		const { activeIndex } = this.state;

		return (
			<Accordion>
				<Accordion.Title
					active={activeIndex === 1}
					index={1}
					onClick={this.handleClick}
				>
					<Icon name="dropdown" />
					<b className="FAQHeading">Toddler Programme</b>
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 1}>
					<p>
						<b>Is the ProfmedTums2Tots BabyYumYum portal/programme free?</b>
						<br />
						<br />
						Yes. It is included in your ProfmedTums2Tots Programme and certain
						benefits are applicable to you depending on which plan you are on.
						<br />
						<br />
					</p>
					<p>
						<b>How do I access the portal/programme? </b>
						<br />
						<br />
						Once you have alerted Profmed that your have a toddler and have given
						consent that you would like access to the portal (and the amazing benefits
						included), we will automatically activate your access to the portal and
						you will be sent a message from the ProfmedTums2Tots BabyYumYum team.
						<br />
						<br />
					</p>
					<p>
						<b>Can I opt out?</b>
						<br />
						<br />
						Yes, you may opt out at any time, but you will miss out on benefits should
						you wish to opt out of access to the portal.
						<br />
						<br />
					</p>
					<p>
						<b>Can I pick and choose what I want from the portal/programme?</b>
						<br />
						<br />
						Yes, you can, the benefit choice is entirely yours! As a Profmed member
						you receive a specific number of pebbles based on your medical plan. The
						benefits available can be redeemed by exchanging the specified number of
						pebbles. Once you select the redeem button at the bottom of the page, the
						benefit is allocated and where applicable delivery will be arranged. The
						pebble solution allows you to choose what you really want and not just
						take what you are given.
						<br />
						<br />
					</p>
					<p>
						<b>How long does the portal/programme run for?</b>
						<br />
						<br />
						Until your toddler turns 3 years old. <br />
						<br />
					</p>
					<p>
						<b>How long does the pebble solution of ProfmedTums2Tots run for?</b>
						<br />
						<br />
						Each member has their pebble allocation for a period of 12 months from the
						date of registration on the programme. <br />
						<br />
					</p>
					<p>
						<b>
							I have informed the ProfmedTums2Tots BabyYumYum portal/programme that I
							would like to register for the Toddler programme. Do I still need to tell
							Profmed?
						</b>
						<br />
						<br />
						Yes, you must communicate with Profmed directly that you would like to
						join the programme and then give them consent that you would like to join
						the programme. Consent is required from the member directly to allow
						BabyYumYum to receive your file. We cannot do this for you.
						<br />
						<br />
					</p>

					<p>
						<b>If I have any claims to process who do I send them to?</b>
						<br />
						<br />
						You must send all claims directly to Profmed for processing.
						<br />
						<br />
					</p>
					<p>
						<b>
							If I have a medical emergency, do I contact ProfmedTums2Tots BabyYumYum?
						</b>
						<br />
						<br />
						No. You must contact your nearest emergency assistance provider.
						ProfmedTums2Tots BabyYumYum can only offer advice or refer your questions
						to experts. <br />
						<br />
					</p>
					<p>
						<b>How do I claim my Elite First Aid Kit</b>
						<br />
						<br />
						We know how excited you are about receiving your first aid kit; if you opt
						to select the Elite first aid kit as one of your pebble solution products.
						You will log onto the microsite and use 3 of your pebbles from your
						allocation, these 3 pebbles will then be deducted and you will be alerted.
						We will ask you for and confirm your delivery address. We will also let
						you know once your kit has been dispatched.
						<br />
						<br />
					</p>
					<p>
						<b>How do I claim the Bundle Up boxes (sensory boxes)?</b>
						<br />
						<br />
						If you opt to select the Bundle Up boxes as one of your pebble solution
						products. You will log onto the microsite and use 4 of your pebbles from
						your allocation, these 4 pebbles will then be deducted and you will be
						alerted. We will ask you for and confirm your delivery address. We will
						also let you know once the sensory box has been dispatched. You may only
						redeem one of these per annum.
						<br />
						<br />
					</p>
					<p>
						<b>How do I claim the baby and child handbook? </b>
						<br />
						<br />
						If you opt to select the Baby and Child Care Handbook as one of your
						pebble solution products. You will log onto the microsite and use 1 of
						your pebbles from your allocation, this pebble will then be deducted and
						you will be alerted. We will ask you for and confirm your delivery
						address. We will also let you know once the handbook has been dispatched.
						<br />
						<br />
					</p>
					<p>
						<b>How do I claim for the Online CPR Course?</b>
						<br />
						<br />
						If you opt to select the Online CPR Course as one of your pebble solution
						products. You will log onto the microsite and use 3 of your pebbles from
						your allocation, these 3 pebbles will then be deducted and you will be
						alerted.. You will be sent your voucher code (this is the code you use
						when you access the platform) via SMS. You can then go on to the Survival
						CPR platform:{" "}
						<a
							href="https://www.obami.com/portals/Survival_CPR/Infant-and-Child-CPR-and-First-Aid-Course/subscribe "
							target="_blank"
							rel="noopener noreferrer"
						>
							{" "}
							https://www.obami.com/portals/Survival_CPR/Infant-and-Child-CPR-and-First-Aid-Course/subscribe{" "}
						</a>
						to enter the unique code and sign yourself up to access the course. The
						course can only be redeemed with one order and is valid for 6 months from
						the date of issue. You will have access to your link for 2 months.
						<br />
						<br />
					</p>
					<p>
						<b>How do I give back?</b>
						<br />
						<br />
						If you opt to select Giving back as one of your pebble solution products.
						You will log onto the microsite and use 1 of your pebbles from your
						allocation, this pebble will then be deducted and you will be alerted. The
						value of your pebble contribution will be given to Afrika Tikkun
						Bambanani; a non-governmental organization (NGO) that provides children
						with access to quality education through the standardisation of the Early
						Childhood Development (ECD) curriculum throughout South Africa.{" "}
						<b>
							Please note that ProActive plans do not qualify for the Giving back
							benefit.
						</b>
						<br />
						<br />
					</p>
					<p>
						<b>How do I access the Momcierge Club?</b>
						<br />
						<br />
						If you opt to select the Momcierge Club as one of your pebble solution
						products. You will log onto the microsite and use 1 of your pebbles from
						your allocation, this pebble will then be deducted and you will be
						alerted. You will be notified of your membership to this exclusive VIP
						Club and your amazing benefits that come with it.
						<br />
						<br />
					</p>
				</Accordion.Content>
			</Accordion>
		);
	}
}

export default PebbleAccordion;
