import React, { Component } from "react";
import { Accordion, Icon, Image } from "semantic-ui-react";

class PebbleAccordion extends Component {
	state = { activeIndex: -1 };

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	render() {
		const { activeIndex } = this.state;

		return (
			<div className="accordion">
				{/* <div className='mainDivMyProfile'>
					<p className='title'>How it works?</p>
					<h3 className='byy-text-pink'>
						{" "}
						(As a Profmed member you receive a specific number of pebbles based
						on your medical plan. The benefits available can be redeemed by
						exchanging the specified number of pebbles. The benefit choice is
						entirely yours! <br /> Once you select the redeem button at the
						bottom of the page, the benefit is allocated and where applicable
						delivery will be arranged.)
						<br />
					</h3>
				</div> */}
				<Accordion>
					{/* <Accordion.Title
						active={activeIndex === 0}
						index={0}
						onClick={this.handleClick}
					>
						<Icon name='dropdown' />
						<b>How it works?</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 0}>
						<p>
							As a Profmed member you receive a specific number of pebbles based
							on your medical plan. The benefits available can be redeemed by
							exchanging the specified number of pebbles. The benefit choice is
							entirely yours! <br /> Once you select the redeem button at the
							bottom of the page, the benefit is allocated and where applicable
							delivery will be arranged.
						</p>
					</Accordion.Content> */}
					<b>
						<u>What are the benefits?</u>
					</b>
					<Accordion.Title
						active={activeIndex === 1}
						index={1}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Bundle Up Boxes</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 1}>
						<p>
							Bringing you THE ultimate toddler activity and educational milestone
							curated box. This resource-packed box allows you to have all the
							necessary items on hand to ensure that your toddler receives fun and
							educational sensory stimulation together with the suggested activities.
							<br />
							To redeem the Bundle Up Boxes, exchange 4 pebbles.
							<br />
							Please allow 21 - 30 working days for delivery.
						</p>
						<p>
							<Image src="../assets/Bundle_Box_Pic_4.png" size="medium" />
							Box 1 in our toddler collection- Appropriate for a 1 year old
							<br />
							{/* <Image src="../assets/Bundle_Box_Pic_2.jpg" size="medium" />
							Bundle box 2
							<br />
							<Image src="../assets/Bundle_Box_Pic_3.jpg" size="medium" />
							Bundle box 3
							<br /> */}
							<Image src="../assets/Bundle_Box_Pic_5.png" size="medium" />
							Box 2 in our toddler collection- Appropriate for a 2 year old
							<br />
							<Image src="../assets/Bundle_Box_Pic_1.png" size="medium" />
							Box 3 in our toddler collection- Appropriate for a 3 year old
							<br />
							{/* <Image src="../assets/Bundle_Box_Pic_6.jpg" size="medium" />
							Bundle box 6
							<br />
							<Image src="../assets/Bundle_Box_Pic_7.jpg" size="medium" />
							Bundle box 7
							<br />
							<Image src="../assets/Bundle_Box_Pic_8.jpg" size="medium" />
							Bundle box 8 */}
						</p>
					</Accordion.Content>

					<Accordion.Title
						active={activeIndex === 2}
						index={2}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Elite First Aid Kit</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 2}>
						<p>
							This Elite first aid kit exceeds the minimum contents of a First Aid kit
							set out in the National OHS Act.
							<br />
							This first aid kit is idea for domestic and heavy-duty vehicles, it also
							includes a blood/fluid spill kit which is used for cleaning and
							disinfecting sites where blood and /fluid has been spilled.
							<br />
							The bag which contains the contents is manufactured locally and is made
							of high-quality grade material, resulting in a bag that it is unlikely to
							tear and break easily.
							<br />
							When this first aid kit is selected, you will be required to provide a
							delivery address so that we can arrange for it to be delivered by
							courier.
							<br />
							To redeem the Elite first aid kit exchange 3 pebbles.
							<br />
							<Image src="../assets/First_Aid_Kit_pic_1.jpg" size="medium" />
							<br />
							<Image src="../assets/First_Aid_Kit_pic_2.jpg" size="medium" />
						</p>
					</Accordion.Content>

					<Accordion.Title
						active={activeIndex === 3}
						index={3}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Instant Access Baby and Child First Aid Course</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 3}>
						<p>
							Learn Baby and Child CPR and First Aid from your device at home! Start
							learning at your own pace with Sister Catherine Rodwell, owner and
							founder of Survival CPR and a Trauma and ICU medical professional.
							<br />
							Your purchase includes:
							<ol>
								<li>15 Modules</li>
								<li>Access for 2 months</li>
								<li>37 videos</li>
								<li>15 bonus downloads</li>
								<li>
									Feel confident and ready for a first aid emergency in under 2.5 hours!
								</li>
							</ol>
							<br />
							To redeem the Online CPR Course exchange 3 pebbles.
							<br />
							<Image src="../assets/CPR_Online_Course_pic_1.png" size="medium" />
							<br />
							<Image src="../assets/CPR_Online_Course_pic_2.png" size="medium" />
							<br />
							<Image src="../assets/CPR_Online_Course_pic_3.png" size="medium" />
						</p>
					</Accordion.Content>
					<Accordion.Title
						active={activeIndex === 4}
						//active={true}
						index={4}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Momcierge</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 4}>
						<p>
							Think back to your first “club”, maybe it was your Garbage Pail Kids Card
							Club at school or the first bank account card club you had. Whatever it
							was, it stirs up special feelings and a connection with a brand that is
							priceless. You felt a sense of belonging and connection; that is the
							vision behind The BabyYumYum Momcierge Club. This Momcierge Club provides
							the member with all sorts of goodies, a virtual membership badge to
							display on social media, monthly specials and vouchers, monthly swag,
							sampling opportunities, complimentary access to events, discounted
							products and services, informative and educational content and videos,
							and the opportunity to connect with like-minded people who are trying to
							raise a family the best way that they know how.
							<br />
							When the Momcierge Club service is selected, you will receive a call from
							one of our agents to assist you.
							<br />
							To redeem access to the Momcierge Club service exchange 1 pebble.
						</p>
					</Accordion.Content>
					<Accordion.Title
						active={activeIndex === 5}
						//active={true}
						index={5}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Giving Back</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 5}>
						<p>
							Afrika Tikkun Bambanani (ATB); a non-governmental organization which has
							brought together a team of teachers, theorists, educators, investors and
							therapists to create the best ECD curriculum available in South Africa.
							Their aim is to provide our nation’s children with access to quality
							education through the standardisation of the ECD curriculum across South
							Africa. ATB follows the Department of Education’s framework and ensures
							that all children have every opportunity to reach their full potential,
							completing the modules required to reach the age-appropriate milestones.
							At present, they have made an impact in implementing this programme in
							over 20 schools.
							<br />
							When Giving Back is selected, the value of your pebble will be converted
							to a donation, which BabyYumYum will ensure is received by this selected
							charity.
							<br />
							You can exchange from 1 pebble.
							<br />
							<Image src="../assets/Giving_Back_pic_1.png" size="medium" />
							<Image src="../assets/Giving_Back_pic_3.png" size="medium" />
							<Image src="../assets/Giving_Back_pic_2.png" size="medium" />
						</p>
					</Accordion.Content>
					<Accordion.Title
						active={activeIndex === 6}
						index={6}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Baby and Child Care Handbook</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 6}>
						<p>
							"This book is a veritable one-stop knowledge shop…not only does it seem
							to cover every single solitary freaky, benign, apparently stupid or
							incredibly bright question a fuzzy-headed mother might find herself
							asking, but it does so in a calm, credible, non-judgemental,
							non-patronising tone…" Karin Schimke, Cape Times
							<br />
							When the handbook is selected, you will be required to provide a delivery
							address so that we can arrange for it to be delivered by courier.
							<br />
							To redeem a Baby and Child Care Handbook exchange 1 pebble.
						</p>
					</Accordion.Content>
				</Accordion>
			</div>
		);
	}
}

export default PebbleAccordion;
