import React, { Fragment } from "react";
import { Image } from "semantic-ui-react";
import "./TopBanner.css";
//import { BrowserView, MobileView } from "react-device-detect";

// const mapState = state => ({
//   loading: state.sideBar.loading,
//
// });

// const actions = {
//   loadPerson
// };

const TopBanner = ({ weeks, event, babySize }) => {
	//console.log("size" + babySize )
	return (
		<Fragment>
			<div>
				<Image className="bannerImage" fluid src="../assets/banner-top-1.jpg" />
				<div
					className="byy-pink pinkBanner"
					style={{
						display: "flex",
						flexDirection: "row",
						flex: 1,
						justifyContent: "center",
					}}
				>
					<div className="pinkSircleLeft">
						<p className={"byy-text-white"}>
							<b>{weeks}</b>
						</p>
					</div>
					{event === "pregnant" && (
						<div>
							<div className="pinkSircleCenterPregnant" />
						</div>
					)}
					{event === "child" && (
						<div>
							<div className="pinkSircleCenterChild" />
						</div>
					)}
					{event === "toddler" && (
						<div>
							<div className="pinkSircleCenterToddler" />
						</div>
					)}
					<div className="pinkSircleRight">
						<Image src={"../assets/banner-blue-circle.png"}>
							<p className={"byy-text-white"}>{babySize}</p>
						</Image>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default TopBanner;
