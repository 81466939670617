import React from "react";
import "./TermsConditionsPage.css";
import FullBanner from "../pageContent/FullBanner";
import ScrollTop from "../scrollTop/ScrollTop";
import { Accordion, Icon } from "semantic-ui-react";

const TermsConditionsText = () => {
	return (
		<div>
			<p className="subHeadingTermsConditions">
				1. GENERAL TERMS AND CONDITIONS OF USE OF WEBSITES
			</p>
			<p>
				• These are the general terms and conditions (“terms and conditions”) of use
				of the websites conducted by BabyYumYum, a company incorporated in
				accordance with the laws of South Africa under registration number
				2013/057772/07, all of its stakeholders, subsidiaries, partners, divisions
				and affiliates, its directors, officers, employees or agents. These terms
				and conditions apply to all our websites, and any other entities and/or
				websites in which BabyYumYum holds an interest, and to which these terms and
				conditions have been extended.
				<br />• Please read the following terms and conditions very carefully, as
				your use of this website Sites and all other websites belonging to
				BabyYumYum (collectively “the Sites”), is subject to your compliance with
				these terms and conditions insofar as they relate to this specific website.
				These terms and conditions apply to both –
				<ul>
					<li>
						third party users of the Sites, which may advertise, publish or otherwise
						submit content onto the Sites; and
					</li>
					<li>
						users, who may browse the contents of the Sites and the third party
						content displayed thereon, or either party, as the context may indicate.
					</li>
				</ul>
				<br />
				• These terms and conditions are to be read with BabyYumYum’s Privacy Policy
				(set out below) and any other policies, rules, disclaimers or terms and
				conditions which may be published by BabyYumYum from time to time and/or
				published on the Sites (all of which are included in the definition of
				“terms and conditions”). By accessing and/or using the Sites, you
				acknowledge that you have read, understood and accepted these terms and
				conditions. If you do not agree with all these terms and conditions, in
				their entirety, or if you do not wish to be bound by these terms and
				conditions, you may not access, copy or download any content on the Sites
				and you are not entitled to use the Sites.
				<br />
				• Further to the aforementioned, BabyYumYum may, in its sole and absolute
				discretion, amend these terms and conditions from time to time.
				<br />
				• Reference to BabyYumYum in these terms and conditions shall be deemed to
				be a reference to the relevant legal entity which operates the website
				concerned.
				<br />
			</p>
			<p className="subHeadingTermsConditions">
				2. SUBMISSION OF CONTENT AND USE OF THE SITE
			</p>
			<p>
				You agree to use the Sites for lawful purposes only. Should you choose to
				access or use the Sites from locations other than the Republic of South
				Africa, you do so at your own initiative and you are responsible for
				compliance with applicable laws.
				<br />
				• You are prohibited from submitting, by means of reviews, comments,
				suggestions, ideas, questions, advertisements, promotional material,
				directory listings or any other information through the Sites, or in any
				other manner, any content which is, unlawful, harmful, threatening, abusive,
				harassing, defamatory, vulgar, obscene, sexually explicit, profane, hateful,
				racially, ethnically or otherwise objectionable, including but not limited
				to –
				<br />
				<ul>
					<li>
						any content that encourages conduct that would constitute a criminal
						offence or give rise to civil liability, or otherwise violate any
						applicable local, provincial, national, or international law;
					</li>
					<li>any content that constitutes an invasion of privacy;</li>
					<li>
						any content that is an infringement of any intellectual property right;
					</li>
					<li>
						any content that contains software viruses, Trojan horses, or other
						computer programming routines that may damage, detrimentally interfere
						with, surreptitiously intercept or expropriate any system, data or
						personal information; or
					</li>
					<li>
						any content that constitutes a political statement, commercial
						solicitation, or “Spam”.
					</li>
				</ul>
				<br />
				• Although BabyYumYum does not purport to review (nor is it under any
				obligation to do so) any submitted content, it reserves the right to remove
				any content from the Sites that it deems, in its sole discretion, to be an
				infringement of any of the provisions of these terms and conditions or
				harmful in anyway whatsoever.
				<br />
				• You agree that in using the Sites in any way, and/or in any content you
				provide to BabyYumYum, you will not-
				<br />
				<ul>
					<li>
						create liability for BabyYumYum or cause BabyYumYum to lose (in whole or
						in part) the services of our ISPs or other suppliers;
					</li>
					<li>
						link directly or indirectly to or include descriptions of goods or
						services that are prohibited under the and conditions and that you do not
						have a right to link to or include;
					</li>
					<li>
						consummate any transaction that was initiated using our site that, by
						paying to us a fee, could cause us to violate any applicable law, statute,
						ordinance or regulation. Furthermore, you may not resell or make any
						commercial use of BabyYumYum’s system or the content on the site without
						BabyYumYum’s prior written consent; and
					</li>
					<li>provide content to the Sites which is competitive with BabyYumYum.</li>
				</ul>
				• At any time, without notice, for any or no reason, BabyYumYum reserves the
				right to refuse service to anyone, to modify and discontinue any portion or
				all of the service, and to restrict, suspend and terminate transactions
				<br />
				• Should you breach this clause or any other clause in these terms and
				conditions, BabyYumYum may immediately terminate and/or suspend your access
				to all or parts of the Sites, without any notice to you, and you may be held
				liable to criminal or civil prosecution.
				<br />
				• In using the Sites, you warrant that –<br />
				<ul>
					<li>
						you own or otherwise control all rights to the content that you may submit
						to the Sites;
					</li>
					<li>
						any use of such content will not cause injury or harm to any person or
						entity; and
					</li>
					<li>
						you indemnify BabyYumYum, its directors, officers and employees, for all
						claims resulting from any submitted content or any breach by you of these
						terms and conditions.
					</li>
				</ul>
				<br />
				• By submitting reviews, comments, advertising, directory and event
				information and/or any other content (other than information protected by
				the Protection of Personal Information Act, 2013) to BabyYumYum for posting
				on the Sites, you automatically grant BabyYumYum a non-exclusive,
				royalty-free, perpetual, irrevocable right and license to use, reproduce,
				publish, translate, sublicense, copy and distribute such content in whole or
				in part worldwide, and to incorporate it in other works in any form, media,
				or technology now known or hereinafter developed for the full term of any
				copyright that may exist in such content. Notwithstanding the
				aforementioned, you retain any and all rights that may exist in such
				content.
				<br />
				• The following activities on or through the Sites are expressly prohibited
				–<br />
				<ul>
					<li>
						any non-personal or commercial use of any robot, spider, other automatic
						device or technology, or manual process to monitor or copy portions of the
						Sites, or the content contained therein, or to data-mine the same without
						the prior written authority of BabyYumYum;
					</li>
					<li>
						the collection or use of any listings, descriptions, or price lists from
						the Sites, for the benefit of a competing merchant that supplies products
						comparable to those offered on the Sites or for any other non-personal
						purpose that is not expressly authorised;
					</li>
					<li>
						any use or action that imposes an unreasonable or disproportionately large
						load of traffic on the Sites, or otherwise interferes with its proper and
						timely functioning; and
					</li>
					<li>
						incorporating any e-mail addresses, names, telephone numbers, and fax
						numbers published on the Sites in any database used for electronic
						marketing or similar purposes. No permission is given or should be implied
						that information on the Sites may be used to communicate unsolicited
						communications to BabyYumYum and all rights detailed in Section 45 of the
						Electronic Communications and Transactions Act No. 25 of 2002 (as amended)
						(“ECT Act”) are reserved.
					</li>
				</ul>
				<br />
				• You are responsible for maintaining the confidentiality and security of
				your User Name and Password for access to the Sites, and you accept full
				responsibility for all activities that occur under your User Name.
				<br />
				• Some of the services offered on the Sites, specifically those relating to
				subscribing to any of BabyYumYum’s publications and participating in
				e-commerce activities and the purchase and driving of vehicles are only
				available to persons 18 years or older. By registering with BabyYumYum for
				such services, you warrant that you are 18 years or older. <br />
				• From time to time BabyYumYum will run competitions, free prize draws, and
				promotions on the Sites. These competitions, free prize draws, and
				promotions are subject to applicable laws, BabyYumYum’s General Terms and
				Conditions for Promotional Competitions, and any other rules which may be
				published in relation thereto.
				<br />
			</p>

			<p p className="subHeadingTermsConditions">
				3. THE USE OF THIRD PARTY CONTENT
			</p>
			<p className="bulletPointsTermsConditions">
				• BabyYumYum hosts information, pricing, opinions, advertising, directory
				services, event information, property inventory, vehicle inventory and other
				content supplied by third parties (“Third Party Content”) on the Sites.
				BabyYumYum has no editorial or other control, over such content.
				<br />
				• Opinions, statements, offers or any other information that may constitute
				Third Party Content, belong to the respective third party and not to
				BabyYumYum. BabyYumYum does not guarantee the accuracy, completeness, and/or
				usefulness of any Third Party Content. All Third Party Content is provided
				as received by BabyYumYum by the applicable third party. Any Third Party
				Content should not be construed as an express or implied endorsement by
				BabyYumYum unless otherwise expressly stated. You use Third Party Content at
				your own risk.
				<br />
				• It is your responsibility to evaluate Third Party Content available on and
				through the Sites. BabyYumYum is not liable for any loss, damage or harm
				caused by your reliance on Third Party Content obtained on or through the
				Sites. Before making any decision or placing any reliance on Third Party
				Content provided on or through the Sites, you should take all reasonable
				steps to ensure and verify the accuracy of such content.
				<br />
				• In addition to the aforementioned, BabyYumYum is not a supplier of any
				products which may be advertised through Third Party Content on the Sites.
				Accordingly, BabyYumYum is not liable for any products, purchased by you due
				to your reliance on the Third Party Content, which are not ‘safe, good
				quality goods’ in terms of section 56 of the Consumer Protection Act, No. 68
				of 2008. BabyYumYum does, however, provide the contact details of all third
				party suppliers on the Sites. You may contact the third party suppliers by
				using such contact details in such an event.
				<br />
				• BabyYumYum does not review (nor is it under any obligation to do so) or
				control any third party website that links to or from the Sites. BabyYumYum
				is not responsible for the content of any third party website linked to or
				from the Sites. The third party websites are provided solely for your
				convenience. Any information, endorsements of products or services,
				materials or personal opinions appearing on a third party website should not
				be construed as an express or implied endorsement by BabyYumYum unless
				otherwise expressly stated. You use third party websites at your own risk.
				<br />• If you believe that your work has been copied and is accessible on
				the Sites in a way that constitutes copyright infringement, or that the
				Sites contain links or other references to another online location that
				contains material or activity that infringes your copyright, please contact
				BabyYumYum.
			</p>
			<p className="subHeadingTermsConditions">
				4. DIRECTORY SERVICES, SPECIALS AND LISTINGS
			</p>
			<p className="bulletPointsTermsConditions">
				• BabyYumYum provides the Sites for the convenience of buyers, sellers and
				users interested in directory services, events and deal listings, and
				collects content in the form of advertisements from these users.
				<br />
				• Further to the provisions of 3 above, the details of products and services
				available on the Sites are provided to BabyYumYum by third parties, private
				sellers and BabyYumYum’s customers, for your information only. BabyYumYum
				cannot verify these details and therefore makes no warranties or
				representations as to their accuracy or completeness. If you rely on these
				details, you do so at your own risk. The provisions of 4 apply mutatis
				mutandis.
				<br />
			</p>

			<p className="subHeadingTermsConditions">5. CHARGES</p>
			<p className="bulletPointsTermsConditions">
				• Using BabyYumYum’s Sites are generally free, but occasionally a fee may be
				charged, entirely at our sole discretion, for certain services. If the
				service you use incurs a fee, you’ll be able to review and accept terms that
				will be clearly disclosed at the time you post your advertisement.
				<br />
				• Our fees are quoted in South African Rands, and we may change them from
				time to time. We’ll notify you of changes to our fee policy by posting such
				changes on the site. We may choose to temporarily change our fees for
				promotional events or new services; these changes are effective when we
				announce the promotional event or new service.
				<br />
				• Our fees are non-refundable, and you are responsible for paying them when
				they are due. If you do not pay, we may limit your ability to use the
				services. We reserve the right to recover unpaid fees from you.
				<br />
			</p>
			<p className="subHeadingTermsConditions">6. VOUCHERS AND COUPONS</p>
			<p className="bulletPointsTermsConditions">
				• You may use promotional coupons as payment for regular priced products on
				the Sites.
				<br />
				• We may email gift and promotional vouchers to you. We accept no liability
				for errors in the email address of the voucher recipient.
				<br />
				• If you have a gift or promotional voucher, that voucher can be used by
				someone other than you and you can assign your rights to use that voucher.
				<br />
				• In the event of fraud, an attempt at deception or in the event of the
				suspicion of other illegal activities in connection with a gift/promotional
				coupon purchase or redemption of that voucher on the Sites, we are entitled
				to close your account and/or require a different means of payment.
				<br />
				• We assume no liability for the loss, theft or illegibility of gift or
				promotional coupons.
				<br />
				• Conditions for the redemption of promotional coupons are as follows-
				<br />
				<ul>
					<li>
						from time to time we may release, at our discretion, promotional coupons
						that may be used on the Sites, which we will send to you by email.
						Promotional coupons can only be redeemed on the Sites;
					</li>
					<li>
						promotional coupons are valid for the specified period stated on them
						only, can only be redeemed against regular priced products and cannot be
						used in conjunction with other promotional coupons; and
					</li>
					<li>individual brands may be excluded from coupon promotions.</li>
				</ul>
			</p>

			<p className="subHeadingTermsConditions">7. ADVERTISING</p>
			<p className="bulletPointsTermsConditions">
				• You agree, upon requesting any display or classified advertising space,
				that in the event of failure to publish an advertisement or publication of
				an incorrect advertisement, the liability of BabyYumYum shall be limited to
				the amount paid by the advertiser for the advertisement that did not appear,
				or for that portion of the advertising space occupied by the incorrect item
				only (as the case may be), and that there shall be no liability in any event
				beyond the amount paid for such advertisement.
				<br />
				• BabyYumYum shall not be liable for slight changes or typographical errors
				that do not lessen the value of an advertisement.
				<br />
				• BabyYumYum cannot be responsible for errors after the first day of
				publication of any advertisement. Notice of errors on the first day should
				immediately be called to the attention of BabyYumYum.
				<br />
			</p>
			<p className="subHeadingTermsConditions">8. INTELLECTUAL PROPERTY RIGHTS</p>
			<p className="bulletPointsTermsConditions">
				• All content included on the Sites by BabyYumYum, such as text, graphics,
				logos, buttons, icons, images, photographs, audio clips, databases and
				software, is the property of BabyYumYum or its content suppliers and is
				protected by South African and international copyright laws. Furthermore,
				the compilation (meaning the collection, arrangement, and assembly) of all
				content on the Sites is the exclusive property of BabyYumYum and is
				protected by South African and international copyright laws. <br />
				• None of the material may be copied, reproduced, distributed, republished,
				downloaded, displayed, posted or transmitted in any form or by any means,
				including, but not limited to, electronic, mechanical, photocopying,
				recording, or otherwise, without the prior written permission of BabyYumYum
				or the copyright owner, except as permitted by the fair use exception or
				other similar provisions under the South African copyright laws or without
				the prior written permission of BabyYumYum or the copyright owner. ©
				Copyright reserved in all content.
				<br />
				• You are expressly prohibited to “mirror” any content, contained on the
				Sites, on any other server unless with the prior written permission of
				BabyYumYum.
				<br />
				• You are granted a limited, revocable, and non-exclusive right to create a
				hyperlink to the home page of the Sites, so long as the link does not
				portray BabyYumYum, or its products or services in a false, misleading,
				derogatory, or otherwise offensive manner. You may not use any BabyYumYum
				logo or other proprietary graphic or trademark as part of the link without
				the express permission of BabyYumYum or its content suppliers.
				<br />
				• All trademarks are the exclusive property of BabyYumYum.
				<br />
				• The unauthorised submission, removal, modification or distribution of
				copyrighted or other proprietary content is illegal and could subject you to
				criminal prosecution as well as personal liability for damages.
				<br />
			</p>
			<p className="subHeadingTermsConditions">9. LIMITATION OF LIABILITY</p>
			<p className="bulletPointsTermsConditions">
				• The information, content, services, products and materials published on
				the Sites, including without limitation, any texts, graphics and links are
				provided on an “as is” basis. BabyYumYum makes no representations or
				warranties of any kind, express or implied, as to the operation of the Sites
				or the accuracy, correctness or completeness of the information, contents,
				materials, or products included on the Sites. Without limiting the
				generality of the aforegoing –
				<ul>
					<li>
						BabyYumYum does not warrant that the Sites, will be error free, or will
						meet any particular criteria of accuracy, completeness or reliability of
						information, performance or quality or that it will be continuously
						available or that you will have uninterrupted access to the Sites; and
					</li>
					<li>
						whilst BabyYumYum has taken reasonable measures to ensure the integrity of
						the Sites and its contents, no warranty, whether express or implied, is
						given that any files, downloads or applications available via the Sites
						are free of viruses, Trojans, bombs, time-locks or any other data or code
						which has the ability to corrupt or affect the operation of your system.
					</li>
				</ul>
				<br />
				• To the full extent permissible by applicable law, BabyYumYum disclaims all
				warranties, express or implied, including, but not limited to, implied
				warranties of merchantability and fitness for a particular purpose.
				BabyYumYum will not be liable for any damages of any kind arising from the
				use of the Sites or your reliance on any information on the Sites, or the
				products and services offered through the Sites from third parties or a
				linked site, or user’s reliance on any product or service obtained from a
				third party or a linked site, including, but not limited to, direct,
				indirect, incidental, punitive, and consequential damages.
				<br />
				• Use of is the Sites are at the user’s sole risk.
				<br />
				• No advice or information, whether oral or written, obtained by you from
				BabyYumYum or its services shall create any warranty
				<br />
				• If you make unlawful use of this Sites and as a result of this a third
				party sues or has a claim against us, you indemnify and hold us harmless
				against that claim. This means that you will become a party to that claim
				and will have to pay it if the claim is successful. Unlawful use includes
				without limitation, any act in breach of these terms and conditions.
				<br />
			</p>
			<p className="subHeadingTermsConditions">10. JURISDICTION</p>
			<p className="bulletPointsTermsConditions">
				These terms and conditions shall be governed by and construed in accordance
				with the laws of the Republic of South Africa without giving effect to the
				principles of conflicts of laws. You hereby consent to the exclusive
				jurisdiction of the High Court of the Republic of South Africa (Gauteng
				Division, Johannesburg) in respect of any disputes arising in connection
				with the Sites.
			</p>
			<p className="subHeadingTermsConditions">11. TERMINATION</p>
			<p className="bulletPointsTermsConditions">
				• BabyYumYum may terminate your account at any time for any reason,
				including any improper use of the Sites or your failure to comply with these
				terms and conditions.
				<br />
				• Such termination shall not affect any right to relief to which BabyYumYum
				may be entitled.
				<br />
				• Upon termination of these terms and conditions, all rights granted to you
				will terminate and revert to BabyYumYum.
				<br />
			</p>
			<p className="subHeadingTermsConditions">
				12. OTHER PROVISIONS AND APPLICATION OF THE ECT ACT
			</p>
			<p className="bulletPointsTermsConditions">
				• In the event that any of the terms of these terms and conditions are found
				to be invalid, unlawful or unenforceable, such terms will be severable from
				the remaining terms of these terms and conditions, which will continue to be
				valid and enforceable.
				<br />
				• When you visit the Sites or send BabyYumYum e-mails, you are communicating
				with us electronically. You consent to receive communications from us
				electronically. We will communicate with you by e-mail or by posting notices
				on this site. You agree that all agreements, notices, disclosures and other
				communications that we provide to you electronically satisfy any legal
				requirement that such communications be in writing.
				<br />
				• BabyYumYum may at any time modify these terms and conditions and your
				continued use of the Sites will be conditional upon the terms and conditions
				in force at the time of your use. Changes shall automatically be effective
				upon posting on the Sites. Your continued use of the service provided by the
				Sites shall signify your acceptance of the changes. If you do not accept the
				changes, your sole and exclusive remedy is to discontinue using the Sites.
				The latest terms and conditions will be posted on the Sites, and you should
				always review them prior to using the site.
				<br />
				• Data Messages (as defined in the ECT Act) will be deemed to have been
				received by BabyYumYum if and when BabyYumYum responds to the Data Messages.
				<br />
				• Data Messages sent by BabyYumYum to you will be deemed to have been
				received by you in terms of the provisions specified in section 23(b) of the
				ECT Act.
				<br />
				• You acknowledge that electronic signatures, encryption and/or
				authentication are not required for valid electronic communications between
				you and BabyYumYum.
				<br />
				• You warrant that Data Messages sent to BabyYumYum by you from any
				electronic device, used by you from time to time, or owned by you, were sent
				by you and/or were personally authorised.
				<br />
				• Subject to the Regulation of Interception of Communications Act No 70 of
				2002 (“RICA”), you agree that we may intercept, block, read, delete,
				disclose and use all communications between you and us, our employees,
				directors and/or agents. You agree that this consent satisfies the
				requirements of ECT Act and RICA for consent in “writing”. You also
				understand that communications by means of email and the Internet are
				inherently unsecure unless encrypted and can be intercepted. We cannot be
				responsible if a third party intercepts communications to or from us and
				this causes damage to you. Please use caution when communicating in this
				way.
				<br />
				• To the extent that we are required under s43 of the ECT Act to provide you
				with additional details for the purposes of undertaking electronic
				transactions on the Sites, this information will be separately provided in
				the relevant sections of the Sites.
				<br />
			</p>
			<p className="subHeadingTermsConditions">13. GENERAL</p>
			<p className="bulletPointsTermsConditions">
				• The terms and conditions constitute the complete and exclusive statement
				of the agreement between you and BabyYumYum. It supersedes any and all prior
				or contemporaneous agreement, oral or written, and any other communications,
				representations, warranties and understanding relating to the subject matter
				of the terms and conditions. If there is any conflict between an oral or
				written representation of any BabyYumYum’s employee or agent and the terms
				and conditions (other than modifications to the terms and conditions
				executed in writing by BabyYumYum), the terms and conditions will prevail.
				These terms and conditions, related agreements and disclaimers will prevail
				over FAQs, and other rules and policies on the Sites.
				<br />
				• BabyYumYum’s failure to exercise or enforce any of the terms and
				conditions shall not constitute a waiver of BabyYumYum’s right to exercise
				or enforce the terms and conditions as to the same or another instance.
				Headings in these terms and conditions and related agreements are for
				purposes of reference only and shall not limit or otherwise affect the
				meaning of the terms and conditions. BabyYumYum shall not be deemed to have
				waived any of its rights or remedies unless such waiver is in writing and
				signed by BabyYumYum.
				<br />
				• You agree that BabyYumYum may assign the terms and conditions to any other
				entity of its choosing, with or without notice to you. You may not assign
				the terms and conditions to any other party.
				<br />
			</p>
			<p className="bulletPointsTermsConditions">
				PLEASE NOTE: THE CONTENTS OF THIS WEBSITE ARE PROPRIETARY TO BABYYUMYUM AND
				MAY NOT BE COPIED, REPRODUCED OR OTHERWISE UTILISED IN ANY MANNER WHATSOEVER
				WITHOUT THE PRIOR WRITTEN CONSENT OF BABYYUMYUM. © 2018
			</p>
		</div>
	);
};

export default TermsConditionsText;
