import React, { Component } from "react";
import { Segment, Form, Button, Grid } from "semantic-ui-react";
import {
	loadPerson,
	savePerson,
	saveChild,
} from "../../features/nav/Sidebar/data/sideBarActions";
import FullBanner from "../pageContent/FullBanner";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import PebblesFields from "./PebblesFields";
import { alert, confirm } from "../../app/functionModals/functionModalsActions";
import LoadingComponent from "../../app/layout/LoadingComponent";
import {
	loadBenefits,
	submitBenefits,
	submitFirstLogin,
} from "./data/pebblesAction";
import DeliveryAddressModal, {
	validate,
} from "../myProfile/myProfileModals/DeliveryAddressModal";
import { SecondaryButton } from "../../styledComponents";
import { toastr } from "react-redux-toastr";

const mapState = (state) => ({
	loading: state.pebbles.loading,
	person: state.sideBar.person,
	initialValues: state.sideBar.person,
	pebbles: state.pebbles,
});

const actions = {
	loadPerson,
	confirm,
	savePerson,
	saveChild,
	loadBenefits,
	submitBenefits,
	submitFirstLogin,
};

class Pebbles extends Component {
	initialBasket = [];
	state = {
		totalPebblesUsed: 0,
		pebblesLeft: 0,
		accordionIndicator: "",
		maxCounter: 10,
		buttonColor: ["#0cb2a6", "White"],
		buttonTextColor: ["White", "#0cb2a6"],
		buttonBorderColor: ["White", "#0cb2a6"],
		currentEventID: 0,
		totalToddlers: 0,
		totalBabies: 0,
		deliveryCheck: false,
		loopChecker: true,
	};

	async componentDidMount() {
		await this.props.submitFirstLogin();
		await this.props.loadPerson();
		await this.props.loadBenefits(this.props.person._event[0].EventID);
		const { pebbles, person } = this.props;
		this.setState({ currentEventID: this.props.person._event[0].EventID });
		this.setState({ pebblesLeft: pebbles.benefitsDetails.PebbleBalance });
		this.setState({
			totalPebblesUsed: 0,
		});
		this.setState({
			accordionIndicator: this.props.person._event[0].EventTypeCode,
		});
		let giftMax = 0;
	}

	isLoading = () => {
		const { loading } = this.props;
		if (loading) {
			return true;
		}
		return false;
	};

	getNewFormValues = async () => {
		//this.reset();
		this.setState({ totalPebblesUsed: 0 }, { pebblesLeft: 0 });
		this.initialBasket = [];
		await this.props.loadPerson();
		await this.props.loadBenefits();
		const { pebbles } = this.props;
		this.setState({ pebblesLeft: pebbles.benefitsDetails.PebbleBalance });
		this.setState({
			totalPebblesUsed: 0,
		});
	};
	handleSkip = () => {
		const { reset, person } = this.props;
		this.props.history.push(`/content/${person._cpInfo[0].ContentPageID}`);
		reset();
	};

	handleSubmitBenefits = async (values) => {
		try {
			//console.log(this.state);
			if (this.state.totalPebblesUsed <= 0 || this.state.pebblesLeft < 0) {
				this.props.confirm(
					"You do not have enough pebbles for this transaction",
					{
						onOk: async () => {
							window.location.reload();
						},
					},
					"Okay"
				);
			} else {
				let submitValues;
				const { pebbles } = this.props;
				if (this.state.deliveryCheck) {
					console.log(pebbles);
					submitValues = {
						Basket: this.initialBasket,
						//ActionTypeCode: pebbles.benefitsDetails.QualifiedBenefitTypes[1].ActionTypeCode,
						PebbleBalance:
							pebbles.benefitsDetails.PebbleBalance - this.state.totalPebblesUsed,
						PebbleSpend: this.state.totalPebblesUsed,
						PebbleStartBalance: pebbles.benefitsDetails.PebbleStartBalance,
						DeliveryAddress:
							values.DeliveryAddressLine1.replace(
								/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,
								" "
							) +
							", " +
							values.DeliveryAddressLine2.replace(
								/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,
								" "
							) +
							", " +
							values.DeliveryPostalCode +
							", " +
							values.DeliveryCity +
							", " +
							values.DeliveryProvince +
							", " +
							values.MomEmailAddress,
						EventID: this.state.currentEventID,
					};
				} else {
					submitValues = {
						Basket: this.initialBasket,
						//ActionTypeCode: pebbles.benefitsDetails.QualifiedBenefitTypes[1].ActionTypeCode,
						PebbleBalance:
							pebbles.benefitsDetails.PebbleBalance - this.state.totalPebblesUsed,
						PebbleSpend: this.state.totalPebblesUsed,
						PebbleStartBalance: pebbles.benefitsDetails.PebbleStartBalance,
						DeliveryAddress: "",
						EventID: this.state.currentEventID,
					};
				}
				this.setState({ submitting: true, loadedInfo: false });

				const { reset, person } = this.props;

				this.props.confirm(
					"Are you sure you want to redeem your pebbles?",
					{
						onOk: async () => {
							//console.log("hit");
							await this.props.submitBenefits(submitValues);
							//await this.getNewFormValues();
							this.props.history.push(`/content/${person._cpInfo[0].ContentPageID}`);
							// reset();
						},
					},
					"Yes",
					"No"
				);
			}
		} catch {
			toastr.error("Error", "Something went wrong");
		}
	};

	changePebbleValue = async (values) => {
		const { pebbles } = this.props;
		await this.setState({
			pebblesLeft: pebbles.benefitsDetails.PebbleBalance,
		});

		let ActionTypeCode = values.ActionTypeCode;
		let added = false;
		let i = 0;
		let j = 0;
		let BenefitTotal = values.BenefitPebbleCost * values.BenefitQty;
		let BenefitID = values.BenefitID;
		let basketOptions = await {
			...values,
			BenefitTotal,
			ActionTypeCode,
		};

		if (this.initialBasket.length === 0) {
			this.initialBasket.push(basketOptions);
		} else {
			if (BenefitID !== undefined) {
				for (i = 0; i < this.initialBasket.length; i++) {
					if (BenefitID === this.initialBasket[i].BenefitID) {
						this.initialBasket[i].BenefitQty = values.BenefitQty;
						this.initialBasket[i].BenefitTotal =
							this.initialBasket[i].BenefitPebbleCost *
							this.initialBasket[i].BenefitQty;
						this.initialBasket[i].ActionTypeCode = values.ActionTypeCode;

						added = true;
						break;
					}
				}
			}

			if (!added) {
				this.initialBasket.push(basketOptions);
			}
		}
		let x = true;
		for (i = 0; i < this.initialBasket.length; i++) {
			if (this.initialBasket[i].BenefitQty > 0) {
				if (
					this.initialBasket[i].BenefitID === 1013 ||
					this.initialBasket[i].BenefitID === 11 ||
					this.initialBasket[i].BenefitID === 10 ||
					this.initialBasket[i].BenefitID === 8
				) {
					this.setState({ deliveryCheck: false });
					for (j = 0; j < this.initialBasket.length; j++) {
						if (this.initialBasket[j].BenefitQty > 0) {
							if (
								!(
									this.initialBasket[j].BenefitID === 1013 ||
									this.initialBasket[j].BenefitID === 11 ||
									this.initialBasket[j].BenefitID === 10 ||
									this.initialBasket[j].BenefitID === 8
								)
							) {
								this.setState({ deliveryCheck: true });
							}
						}
					}
				} else {
					this.setState({ deliveryCheck: true });
				}
			}
		}

		let totalPebblesUsed = 0;
		for (let j = 0; j < this.initialBasket.length; j++) {
			totalPebblesUsed += this.initialBasket[j].BenefitTotal;
		}
		this.setState({ totalPebblesUsed: totalPebblesUsed });
		this.setState({
			pebblesLeft: this.state.pebblesLeft - this.state.totalPebblesUsed,
		});

		this.setState({ maxCounter: values.BenefitQty });
	};

	handleChangeBabyClickHandler = async (EventID, idx) => {
		this.setState({ deliveryCheck: false });
		this.initialBasket = [];
		let values = {
			EventID: EventID,
		};
		if (idx === 1) {
			let newButtonColor = ["White", "#0cb2a6"];
			let newButtonTextColor = ["#0cb2a6", "White"];
			let newButtonBorderColor = ["#0cb2a6", "White"];
			this.setState({ buttonColor: newButtonColor });
			this.setState({ buttonTextColor: newButtonTextColor });
			this.setState({ buttonBorderColor: newButtonBorderColor });
		} else {
			let newButtonColor = ["#0cb2a6", "White"];
			let newButtonTextColor = ["White", "#0cb2a6"];
			let newButtonBorderColor = ["White", "#0cb2a6"];
			this.setState({ buttonColor: newButtonColor });
			this.setState({ buttonTextColor: newButtonTextColor });
			this.setState({ buttonBorderColor: newButtonBorderColor });
		}

		await this.props.loadBenefits(EventID);
		const { pebbles, person } = this.props;
		this.setState({ currentEventID: EventID });
		//console.log(this.props.pebbles.benefitsDetails.PebbleBalance);

		this.setState({ pebblesLeft: pebbles.benefitsDetails.PebbleBalance });
		this.setState({
			totalPebblesUsed: 0,
		});
		let giftMax = 0;

		if (pebbles.benefitsDetails.QualifiedBenefitTypes[0].BenefitID == 1009) {
			this.setState({ accordionIndicator: "T" });
		} else {
			this.setState({ accordionIndicator: "P" });
		}
	};

	render() {
		const {
			pebbles,
			loading,
			handleSubmit,
			submitting,
			//pristine,
			initialValues,
			values,
			person,
			pebblesLeft,
		} = this.props;

		return (
			<div>
				<div className="fullBannerHomePage">
					<FullBanner />
				</div>

				<Form onSubmit={handleSubmit(this.handleSubmitBenefits)}>
					{pebbles.benefitsDetails === undefined && (
						<Segment attached style={{ minHeight: 418 }}>
							<LoadingComponent />
						</Segment>
					)}
					{pebbles.benefitsDetails !== undefined && (
						<div>
							{person._event !== undefined && (
								<div>
									<Grid columns="equal">
										<Grid.Row>
											{person._event.map((event, idx) => (
												<Grid.Column>
													<Segment
														style={{
															color: this.state.buttonTextColor[idx],
															backgroundColor: this.state.buttonColor[idx],
															borderWidth: "2px",
															borderColor: this.state.buttonBorderColor[idx],
															textAlign: "Center",
														}}
														onClick={() =>
															this.handleChangeBabyClickHandler(event.EventID, idx)
														}
													>
														{event.EventTypeCode == "P" && <div>Baby</div>}
														{event.EventTypeCode == "C" && <div>Baby</div>}
														{event.EventTypeCode == "T" && <div>Toddler</div>}
													</Segment>
												</Grid.Column>
											))}
										</Grid.Row>
									</Grid>
								</div>
							)}

							<PebblesFields
								pebblesLeft={pebblesLeft}
								person={person}
								accordionIndicator={this.state.accordionIndicator}
								initialBasket={this.initialBasket}
								codeIndicator={this.state.accordionIndicator}
								pebbles={pebbles}
								loading={loading}
								values={1}
								changePebbleValue={this.changePebbleValue}
								pebbleAvailable={this.state.pebblesLeft}
								maxCounter={this.state.maxCounter}
								val={values}
								deliveryCheck={this.state.deliveryCheck}
							/>
							{this.state.deliveryCheck && (
								<DeliveryAddressModal
									deliveryCheck={this.state.deliveryCheck}
									initialValues={initialValues}
									codeIndicator={this.state.accordionIndicator}
								/>
							)}
						</div>
					)}

					<Button.Group floated="right">
						<SecondaryButton
							disabled={submitting}
							loading={submitting}
							size="large"
							color="byy-teal"
							icon="save"
							content="Redeem now"
							style={{ width: 180 }}
						/>
						<SecondaryButton
							// disabled={submitting || pristine}
							onClick={this.handleSkip}
							size="large"
							icon="close"
							content="Skip"
							color="grey"
							style={{ width: 180 }}
						/>
					</Button.Group>
				</Form>
			</div>
		);
	}
}
export default compose(
	connect(mapState, actions),
	reduxForm({
		validate,
		form: "Pebbles",
		enableReinitialize: true,
	})
)(Pebbles);
