import React, { Component } from "react";
import FullBanner from "../pageContent/FullBanner";
import "./VerifyDueDate.css";
import VerifyQuestion from "./VerifyQuestion";

class VerifyDueDate extends Component {
  render() {
    return (
      <div className="mainDivVerifyDueDate" style={{ position: "relative" }}>
        <FullBanner />
        <br />
        <div
          className="main-background"
          style={{ backgroundImage: "url('../assets/loginpagebaby2.jpg')" }}
        >
          <VerifyQuestion />
        </div>
      </div>
    );
  }
}

export default VerifyDueDate;
