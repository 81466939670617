import { createReducer } from "../../../../app/store/createReducer";

import {
	PERSON_LOADING,
	PERSON_LOADING_COMPLETE,
	LOAD_PERSON,
	PERSON_SUBMITTING,
	PERSON_SUBMITTING_COMPLETED,
	LOAD_CHILD,
	CHILD_SUBMITTING,
	CHILD_SUBMITTING_COMPLETED,
} from "./sideBarConstents";

function getInitialState() {
	return {
		loading: false,
		person: {
			PersonType: null,
			IsBabyBornYN: null,
			BabyDOB: null,
			DeliveryType: null,
			IsBoysYN: null,
			IsGirlsYN: null,
			FirstName: null,
			LastName: null,
			DOB: null,
			AddressCombined: null,
			AddressLine1: null,
			AddressLine2: null,
			City: null,
			Province: null,
			PostalCode: null,
			DeliveryAddressLine1: null,
			DeliveryAddressLine2: null,
			DeliveryCity: null,
			DeliveryProvince: null,
			DeliveryPostalCode: null,
			EstimatedBirthDate: null,
			EstimatedDeliveryDate: null,
			SchemeOption: null,
			isPregnancyHighRiskYN: null,
			HasBirthingBuddyYN: null,
			BirthingBuddyName: null,
			BirthingBuddySurname: null,
			BirthingBuddyMobileNum: null,
			BirthingBuddyDOB: null,
			ProfileSectionCode: null,
			MomMobileNum: null,
			EmailAdress: null,
		},
	};
}

const initialState = getInitialState();

const loading = (state) => {
	return {
		...state,
		loading: true,
	};
};

const loadingCompleted = (state) => {
	return {
		...state,
		loading: false,
	};
};

const loadPerson = (state, payload) => {
	return {
		...state,
		person: payload,
	};
};

const clearPerson = () => {
	return getInitialState();
};

const submitting = (state) => {
	return {
		...state,
		submitting: true,
	};
};

const submittingComplete = (state) => {
	return {
		...state,
		submitting: false,
	};
};

const loadChild = (state, payload) => {
	return {
		...state,
		child: payload,
	};
};

const childSubmitting = (state) => {
	return {
		...state,
		childSubmitting: true,
	};
};

const childSubmittingComplete = (state) => {
	return {
		...state,
		childSubmitting: false,
	};
};

export default createReducer(initialState, {
	[PERSON_LOADING]: loading,
	[PERSON_LOADING_COMPLETE]: loadingCompleted,
	[LOAD_PERSON]: loadPerson,
	[PERSON_SUBMITTING]: submitting,
	[PERSON_SUBMITTING_COMPLETED]: submittingComplete,
	[CHILD_SUBMITTING]: childSubmitting,
	[CHILD_SUBMITTING_COMPLETED]: childSubmittingComplete,
});
