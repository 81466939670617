import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import TextInput from "../../../app/common/form/TextInput";
import { Segment, Grid } from "semantic-ui-react";
import { combineValidators, isRequired } from "revalidate";
import { Field } from "redux-form";

const mapState = (state) => ({
	loading: state.sideBar.loading,
	person: state.sideBar.person,
});

const required = (value) => (value ? undefined : "Required");
export const validate = combineValidators({
	DeliveryAddressLine1: isRequired({
		message: "Number and street is required",
	}),
	DeliveryAddressLine2: isRequired({ message: "Suburb is required" }),
	DeliveryCity: isRequired({ message: "City is required" }),
	DeliveryProvince: isRequired({ message: "Province is required" }),
	DeliveryPostalCode: isRequired({ message: "Postal Code is required" }),

	//dob: isRequired({ message: 'Date of Birth is required' })
});

const DeleveryAddressModal = ({
	initialValues,
	codeIndicator,
	deliveryCheck,
}) => {
	const editable = false;
	//console.log(deliveryCheck);
	return (
		<div className="mainDivMyProfile">
			<Segment attached disabled={!deliveryCheck}>
				{codeIndicator == "P" && (
					<p className="title">
						Delivery Address For Swag Bag, Gift Hamper or Handbook
					</p>
				)}
				{codeIndicator == "T" && (
					<p className="title">
						Delivery address for Bundle Box, First Aid Kit or Handbook
					</p>
				)}
				{/* <h3 className='byy-text-pink'>
						{" "}
						(Applicable to Summit, Extender & Incentive scheme options from week
						20 of pregnancy)
					</h3>
					<div style={{ textAlign: "left" }}>
						<SwagBagAccordion />
					</div>
					<br />
					<h4 className='byy-text-pink'>
						Please provide the daytime address for the courier delivery
					</h4> */}
				<Grid stackable>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Field
								name="DeliveryAddressLine1"
								component={TextInput}
								label="Number and Street"
								setFocus={editable}
								disabled={editable}
								validate={required}
							/>
						</Grid.Column>

						<Grid.Column>
							<Field
								name="DeliveryAddressLine2"
								component={TextInput}
								label="Suburb"
								setFocus={editable}
								disabled={editable}
								validate={required}
							/>
						</Grid.Column>

						<Grid.Column>
							<Field
								name="DeliveryCity"
								component={TextInput}
								label="City/Town"
								setFocus={editable}
								disabled={editable}
								validate={required}
							/>
						</Grid.Column>

						<Grid.Column>
							<Field
								name="DeliveryProvince"
								component={TextInput}
								label="Province"
								setFocus={editable}
								disabled={editable}
								validate={required}
							/>
						</Grid.Column>

						<Grid.Column>
							<Field
								name="DeliveryPostalCode"
								component={TextInput}
								label="Postal Code"
								setFocus={editable}
								disabled={editable}
								validate={required}
							/>
						</Grid.Column>
						<Grid.Column>
							<Field
								name="MomEmailAddress"
								component={TextInput}
								label="Email Address"
								setFocus={editable}
								disabled={editable}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</div>
	);
};

export default compose(
	connect(mapState)
	// reduxForm({
	//   form: "PersonalInformationModal",
	//   enableReinitialize: true,
	//   destroyOnUnmount: false
	// })
)(DeleveryAddressModal);
