import React from "react";
import { Form, Label } from "semantic-ui-react";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { parseISO } from "date-fns";
//import { isMobileView } from "../utils/common";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";

const DropdownDateInput = ({
	input: { value, onChange, onBlur },
	width,
	label,
	disabled,
	padding,
	year,
	month,
	day,
	minDate,
	maxDate,
	meta: { touched, error, active },
	...rest
}) => {
	//const widthpx = width ? `${width}px` : "100%";

	const divStyle = {
		padding: padding ? padding : "0 10px 0 0",
		top: 0,
	};

	return (
		<div style={divStyle}>
			<Form.Field>
				<div
					// className={`ui ${active && " element-focus"} ${touched &&
					//   error &&
					//   " element-error"}`}
					style={{ width: "170%", top: 0 }}
				>
					{label !== null && label !== undefined && (
						<div style={{ textAlign: "left", paddingBottom: 4 }}>{label}</div>
					)}
					<div>
						<div style={{ display: "flex" }}>
							<div style={{ maxWidth: 90 }}>
								<DayPicker
									defaultValue={"Day"}
									// mandatory
									year={year}
									// mandatory
									month={month}
									// mandatory if end={} is given in YearPicker
									endYearGiven
									// default is false
									// required={true}
									// default is false
									//disabled={true}
									// mandatory
									value={day}
									// mandatory
									onChange={(days) => {
										day = days;
										if (day < 10) {
											day = "0" + day;
										}

										localStorage.setItem("loginDay", day);
									}}
									id={"day"}
									name={"day"}
									classes={"classes"}
									optionClasses={"option classes"}
								/>
							</div>

							<div style={{ maxWidth: 90 }}>
								<MonthPicker
									defaultValue={"Month"}
									// to get months as numbers
									//numeric
									// default is full name
									short
									// default is Titlecase
									caps
									// mandatory if end={} is given in YearPicker
									endYearGiven
									// mandatory
									year={year}
									// default is false
									//required={true}
									// default is false
									//disabled={true}
									// mandatory
									value={month}
									// mandatory
									onChange={(months) => {
										let theMonth = parseInt(months) + 1;
										month = theMonth;
										if (month < 10) {
											month = "0" + month;
										}
										localStorage.setItem("loginMonth", month);
									}}
									id={"month"}
									name={"month"}
									classes={"classes"}
									optionClasses={"option classes"}
								/>
							</div>

							<div style={{ maxWidth: 82 }}>
								<YearPicker
									defaultValue={"Year"}
									// default is 1900
									//start={minDate}
									// default is current year
									//end={maxDate}
									// default is ASCENDING
									reverse
									// default is false
									// required={true}
									// default is false
									//disabled={true}
									// mandatory
									value={year}
									type='reset'
									onChange={onChange}
									// mandatory
									onChange={(years) => {
										year = years;
										localStorage.setItem("loginYear", year);
									}}
									id={"year"}
									name={"year"}
									classes={"classes"}
									optionClasses={"option classes"}
								/>
							</div>
						</div>
					</div>
				</div>
				{touched && error && (
					<Label basic color='red' pointing='above'>
						{error}
					</Label>
				)}
			</Form.Field>
		</div>
	);
};

export default DropdownDateInput;
