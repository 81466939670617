import React from "react";
import { Image } from "semantic-ui-react";

const FullBanner = () => {
	return (
		<div>
			<Image
				src='../assets/t2t-babyyumyum-page-header-banner.png'
				size='massive'
				verticalAlign='middle'
			/>
		</div>
	);
};
export default FullBanner;
