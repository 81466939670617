import React, { Component } from "react";
//import Pdf1 from "./../pdfDocuments/Useful_Forms_New_Born_Registration_form.pdf";
//import Pdf2 from "./../pdfDocuments/Useful_Forms_Health4Me_Registration.pdf";
import Pdf3 from "./../pdfDocuments/Useful_Forms_DHA_Birth_Registration.pdf";
import Pdf4 from "./../pdfDocuments/New-Born_New-Adopted-Baby-Registration.pdf";
import "./UsefulForms.css";
import FullBanner from "../pageContent/FullBanner";
import ScrollTop from "../scrollTop/ScrollTop";

class UsefulForms extends Component {
	render() {
		return (
			<div>
				<container className='area'>
					<FullBanner />
				</container>
				<br />

				{/* <div className="mainHeadingUseful">
          <h1 className="headingUseful">Useful Forms</h1><br/>
        </div>

        <h2>Newborn Registration Form - Momentum Medical Scheme</h2>
        <p>
          <iframe className="pdfViewer" title="New Born Registration " src={ Pdf1 } width="100%"
                  height='500px'></iframe>
          <br/>
          <a href={ Pdf1 } target="_blank" rel="noopener noreferrer"> Download Here</a><br/><br/>
        </p>
        <h2>Newborn Registration Form - Health4Me</h2>
        <p>
          <iframe className="pdfViewer" title="New Born Registration " src={ Pdf2 } width="100%"
                  height='500px'></iframe>
          <br/>
          <a href={ Pdf2 } target="_blank" rel="noopener noreferrer"> Download Here</a><br/><br/>
        </p> */}
				<h2>Profmed New Born Registration Form</h2>
				<p>
					<iframe
						title='New Born Registration '
						src={Pdf4}
						width='100%'
						height='500px'
					></iframe>
					<br />
					<a href={Pdf4} target='_blank' rel='noopener noreferrer'>
						{" "}
						Download Here
					</a>
					<br />
				</p>
				<br />
				<h2>DHA Registration Form</h2>
				<p>
					<iframe
						title='New Born Registration '
						src={Pdf3}
						width='100%'
						height='500px'
					></iframe>
					<br />
					<a href={Pdf3} target='_blank' rel='noopener noreferrer'>
						{" "}
						Download Here
					</a>
					<br />
				</p>
				<br />
				<ScrollTop />
			</div>
		);
	}
}

export default UsefulForms;
