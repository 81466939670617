import React from "react";
import CounterInput from "react-counter-input";
import { initialize } from "redux-form";
import { Form, Label } from "semantic-ui-react";

const Counter = ({
	width,
	labelWidth,
	label,
	disabled,
	padding,
	changePebbleValue,
	index,
	id,
	actionTypeCode,
	pebbleCost,
	pebbleAvailable,
	bagCheckMax,
	giftCheckMax,
	boxCheckMax,
	firstAidCheck,
	val,
	initialBasket,
	ballanceInsufficient,
	accordionIndicator,
	pebblesLeft,
	pebbles,
	name,
	//deliveryCheck,
	meta: { touched, error, active },
}) => {
	let BenefitID = id;
	let BenefitDesc = label;
	let BenefitPebbleCost = pebbleCost;
	let max = pebbleAvailable;
	let checkMax = pebbleAvailable;
	let ActionTypeCode = actionTypeCode;
	let BenefitQty;
	let disableButton;
	let bagMax = pebbleAvailable;
	let giftMax = pebbleAvailable;
	let boxMax = pebbleAvailable;
	let deliveryCheck = false;
	let indicatorCheck = accordionIndicator;
	let saftyCheck;
	//let deliveryCheck = false;
	let giftCheck = label.includes("Gift");
	let bagCheck = label.includes("Bag");
	if (initialBasket.length != 0) {
		for (let i = 0; i < initialBasket.length; i++) {
			if (pebbleCost > pebbleAvailable) {
				//console.log("hit", index);
				max = 0;
			} else {
				if (initialBasket[i].BenefitID != undefined) {
					if (giftCheck) {
						if (pebbleCost > pebbleAvailable) {
							giftCheckMax = true;
						}
						if (
							initialBasket[i].BenefitDesc.includes("Gift") &&
							initialBasket[i].BenefitQty > 0
						) {
							giftCheckMax = true;
						} else {
							giftCheckMax = false;
						}
					}

					if (bagCheck) {
						if (pebbleCost > pebbleAvailable) {
							bagCheckMax = true;
						}
						if (
							initialBasket[i].BenefitDesc.includes("Bag") &&
							initialBasket[i].BenefitQty > 0
						) {
							bagCheckMax = true;
						} else {
							bagCheckMax = false;
						}
					}
				}
				if (pebblesLeft <= 0) {
					max = 0;
				}
			}
		}
	}
	console.log(id);
	const divStyle = {
		padding: padding ? padding : "10px 10px 0 10px",
		position: "relative",
	};
	if (bagCheckMax) {
		max = 0;
	}
	if (bagCheckMax == false) {
		max = pebbleAvailable;
	}

	if (giftCheckMax) {
		max = 0;
	}
	if (giftCheckMax == false) {
		max = pebbleAvailable;
	}

	if (pebbleAvailable === 0) {
		checkMax = 0;
	} else {
		if (pebbleAvailable - pebbleCost < 0) {
			checkMax = 0;
		}
	}
	// if (bagMax != undefined) {
	// 	checkMax = bagMax;
	// }
	// if (giftMax != undefined) {
	// 	checkMax = giftMax;
	// }
	if (checkMax === 0) {
		max = 0;
	}

	if (accordionIndicator == "P") {
		indicatorCheck = "P";
	}

	if (accordionIndicator == "C") {
		indicatorCheck = "P";
	}
	if (id == 7) {
		saftyCheck = "P";
	}

	// console.log(accordionIndicator);
	// console.log(index);
	return (
		<div style={divStyle}>
			<Form.Field>
				<div
					className={`ui labeled input ${active && " element-focus"} ${touched &&
						error &&
						" element-error"}`}
					style={{ width: "100%" }}
				>
					<div className="ui label label" style={{ width: "230px" }}>
						{label}
					</div>
					<div
						style={{
							borderStyle: "solid",
							borderWidth: 1,
							borderTopRightRadius: 5,
							borderBottomRightRadius: 5,
							borderColor: "#f5f5f6",
						}}
					>
						{/* Bag Rendering */}
						{bagCheck && indicatorCheck == "P" && (
							<CounterInput
								style={{
									maxWidth: width,
									backgroundColor: disabled ? "rgb(245,245,245)" : "white",
									color: disabled ? "rgb(180,180,180)" : "black",
								}}
								onCountChange={(BenefitQty) =>
									changePebbleValue({
										pebbleCost,
										pebbleAvailable,
										BenefitID,
										BenefitQty,
										BenefitDesc,
										BenefitPebbleCost,
										ActionTypeCode,
										index,
										deliveryCheck,
									})
								}
								min={0}
								max={max}
							/>
						)}
						{/*Gift rendering*/}
						{giftCheck && indicatorCheck == "P" && (
							<CounterInput
								style={{
									maxWidth: width,
									backgroundColor: disabled ? "rgb(245,245,245)" : "white",
									color: disabled ? "rgb(180,180,180)" : "black",
								}}
								onCountChange={(BenefitQty) =>
									changePebbleValue({
										pebbleCost,
										pebbleAvailable,
										BenefitID,
										BenefitQty,
										BenefitDesc,
										BenefitPebbleCost,
										ActionTypeCode,
										index,
										deliveryCheck,
									})
								}
								min={0}
								max={max}
							/>
						)}

						{!giftCheck && !bagCheck && indicatorCheck == "P" && (
							<CounterInput
								style={{
									maxWidth: width,
									backgroundColor: disabled ? "rgb(245,245,245)" : "white",
									color: disabled ? "rgb(180,180,180)" : "black",
								}}
								onCountChange={(BenefitQty) =>
									changePebbleValue({
										pebbleCost,
										pebbleAvailable,
										BenefitID,
										BenefitQty,
										BenefitDesc,
										BenefitPebbleCost,
										ActionTypeCode,
										index,
										deliveryCheck,
									})
								}
								min={0}
								max={max}
							/>
						)}
						{indicatorCheck == "T" && (
							<CounterInput
								style={{
									maxWidth: width,
									backgroundColor: disabled ? "rgb(245,245,245)" : "white",
									color: disabled ? "rgb(180,180,180)" : "black",
								}}
								onCountChange={(BenefitQty) =>
									changePebbleValue({
										pebbleCost,
										pebbleAvailable,
										BenefitID,
										BenefitQty,
										BenefitDesc,
										BenefitPebbleCost,
										ActionTypeCode,
										index,
										deliveryCheck,
									})
								}
								min={0}
								max={max}
							/>
						)}
					</div>
					{id === 7 && (
						<div>
							<b style={{ color: "red" }}>
								Please note, you may select this item, however it will only be available
								for delivery from 1 October 2024
							</b>
						</div>
					)}
				</div>

				<div style={{ position: "absolute" }}>
					Pebble value: {pebbleCost} <br />
				</div>

				{touched && error && (
					<Label basic color="red" pointing="above">
						{error}
					</Label>
				)}
			</Form.Field>
		</div>
	);
};
export default Counter;
