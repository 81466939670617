import {
	BENEFITS_LOADING,
	BENEFITS_LOADING_COMPLETE,
	LOAD_BENEFITS,
	SUBMITTING_PEBBLES,
} from "./pebbleConstants";

import { httpGet, httpPost } from "../../../app/common/utils/http";
import { toastr } from "react-redux-toastr";

export const loadBenefits = (values) => async (dispatch, getState) => {
	let sendValues = {
		EventID: values,
	};
	//console.log(sendValues);
	await dispatch({ type: BENEFITS_LOADING });
	try {
		let benefits = await httpGet(`benefits/GetBenefits?EventID=${values}`);
		await dispatch({
			type: LOAD_BENEFITS,
			payload: benefits,
		});
	} catch (error) {
		toastr.error("Error", "Could not load this Benefit details");
	} finally {
		await dispatch({ type: BENEFITS_LOADING_COMPLETE });
	}
};

// const submitting = (benefits) => {
// 	localStorage.setItem("BenefitID", localStorage.getItem("QuestionID"));
// 	localStorage.setItem("BenefitDesc", benefits.SurveyAnswerID);
// 	localStorage.setItem("BenefitPebbleCost", benefits.SurveyAnswerDesc);
// 	localStorage.setItem("BenefitPebbleCost", benefits.SurveyAnswerDesc);
// };

export const submitBenefits = (submitDto) => async (dispatch) => {
	//console.log(submitDto);
	await dispatch({ type: BENEFITS_LOADING });
	try {
		const answer = await httpPost("Benefits/RedeemPebbles", submitDto);

		await dispatch({ type: SUBMITTING_PEBBLES });
		return answer.ContentPageID;
	} catch (error) {
		toastr.error("Error", error);

		throw error;
	} finally {
		await dispatch({ type: BENEFITS_LOADING_COMPLETE });
	}
};

export const submitFirstLogin = () => async (dispatch) => {
	try {
		const answer = await httpPost("Benefits/PersonUpdateFirstLogin");
	} catch (error) {
		toastr.error("Error", error);

		throw error;
	} finally {
	}
};
