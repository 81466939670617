import React, { Component } from "react";
import { Segment, Form, Button, Grid, Radio } from "semantic-ui-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import RadioInput from "../../../app/common/form/RadioInput";
import CheackBoxInput from "../../../app/common/form/CheackBoxInput";
import DateInputMyProfile from "../../../app/common/form/DateInputMyProfile";
import DropdownList from "../../../app/common/form/DropdownList";
import { loadProfileInfo } from "../data/myProfileActions";
import {
	collapseTextChangeRangesAcrossMultipleVersions,
	textSpanIsEmpty,
} from "typescript";
import SelectInput from "../../../app/common/form/SelectInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";

const mapState = (state) => ({
	myProfileInformation: state.profileInfo.profileInformation,
	loading: state.profileInfo.loading,
	initialValues: state.profileInfo.profileInformation,
});

const actions = {
	loadProfileInfo,
};

const Reasons = [
	{
		DeliveryTypeReasonDesc: "Doctor’s recommendation",
		DeliveryTypeReasonID: 1,
	},
	{ DeliveryTypeReasonDesc: "My preference", DeliveryTypeReasonID: 2 },
	{ DeliveryTypeReasonDesc: "Birthing complications", DeliveryTypeReasonID: 3 },
	{ DeliveryTypeReasonDesc: "Other", DeliveryTypeReasonID: 4 },
];

class ChildInformationModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasBabyYes: false,
			hasBabyNo: false,
			hasBabyLable: "No",
			boy: false,
			girl: false,
			ceaserSection: false,
			naturalBirth: true,
			deliveryType: "",
			checkedC: false,
			checkedN: false,
			loaded: false,
			reason: "",
			showOtherReason: false,
		};
	}
	componentDidMount() {
		const { myProfileInformation } = this.props;

		this.setState({
			//hasBaby: person.IsBabyBornYN,
			boy: myProfileInformation.IsBoyYN,
			girl: myProfileInformation.IsGirlYN,
			deliveryType: myProfileInformation.DeliveryTypeCode,
		});
		if (myProfileInformation.DeliveryTypeReasonID === 4) {
			this.setState({
				showOtherReason: true,
			});
		}
		if (myProfileInformation.IsBabyBornYN === true) {
			this.setState({
				hasBabyYes: true,
				hasBabyNo: false,
			});
		}
		if (myProfileInformation.IsBabyBornYN === false) {
			this.setState({
				hasBabyYes: false,
				hasBabyNo: true,
			});
		}
		if (myProfileInformation.DeliveryTypeCode === "C") {
			this.setState({
				checkedC: true,
				checkedN: false,
			});
		} else if (myProfileInformation.DeliveryTypeCode === "N") {
			this.setState({
				checkedN: true,
				checkedC: false,
			});
		} else {
			this.setState({
				checkedN: false,
				checkedC: false,
			});
		}
	}

	handleChange = (event) => {
		if (this.state.deliveryType === "N") {
			this.setState({
				checkedN: true,
				checkedC: false,
			});
		}
		if (this.state.deliveryType === "C") {
			this.setState({
				checkedC: true,
				checkedN: false,
			});
		}
	};

	handleChangeBabyBornYesClickHandler = () => {
		if (this.state.hasBabyYes === true) {
			this.setState({ hasBabyYes: false });
		}
		if (this.state.hasBabyYes === false) {
			this.setState({ hasBabyYes: true });
		}
		if (this.state.hasBabyNo === true) {
			this.setState({ hasBabyNo: false });
		}
	};

	handleChangeBabyBornNoClickHandler = () => {
		// this.setState(prevState => {
		//   return { checkedC: !prevState.checkedC };
		// });

		if (this.state.hasBabyNo === true) {
			this.setState({ hasBabyNo: false });
		}
		if (this.state.hasBabyNo === false) {
			this.setState({ hasBabyNo: true });
		}
		if (this.state.hasBabyYes === true) {
			this.setState({ hasBabyYes: false });
		}
	};

	handleBoyClickHandler = () => {
		this.setState((prevState) => {
			return { boy: !prevState.boy };
		});
	};

	handleGirlClickHandler = () => {
		this.setState((prevState) => {
			return { girl: !prevState.girl };
		});
	};

	handleNaturalBirthChange = () => {
		// this.setState(prevState => {
		//   return { checkedN: !prevState.checkedN };
		// });
		if (this.state.checkedN === true) {
			this.setState({ checkedN: false });
		}
		if (this.state.checkedN === false) {
			this.setState({ checkedN: true });
		}
		if (this.state.checkedC === true) {
			this.setState({ checkedC: false });
		}
	};

	handleCesareanSectionChange = () => {
		// this.setState(prevState => {
		//   return { checkedC: !prevState.checkedC };
		// });

		if (this.state.checkedC === true) {
			this.setState({ checkedC: false });
		}
		if (this.state.checkedC === false) {
			this.setState({ checkedC: true });
		}
		if (this.state.checkedN === true) {
			this.setState({ checkedN: false });
		}
	};

	handleChange = (e, { value }) => this.setState({ value });

	checkReason = (value) => {
		if (value === 4) {
			this.setState({ showOtherReason: true });
		} else {
			this.setState({ showOtherReason: false });
		}
	};

	render() {
		const editable = false;

		let reasonsList = () => {
			return Reasons.map((reason) => {
				return {
					key: reason.DeliveryTypeReasonID,
					text: reason.DeliveryTypeReasonDesc,
					value: reason.DeliveryTypeReasonID,
				};
			});
		};

		return (
			<div className="mainDivMyProfile">
				<Segment>
					<p className="title">Our new bundle of joy</p>

					<Grid stackable>
						<div style={{ textAlign: "left" }}>
							<h4 className="byy-text-pink">Has baby been born?</h4>
							<Field
								name="IsBabyBornYN"
								component={RadioInput}
								// label={this.state.hasBabyLable}
								label={"Yes"}
								setFocus={editable}
								// toggle={true}
								//checked={person.IsBabyBorn}
								checked={this.state.hasBabyYes}
								onChange={this.handleChangeBabyBornYesClickHandler}
								value={true}
								setvalue={true}
								//  disabled={!editable}
							/>
							<Field
								name="IsBabyBornYN"
								component={RadioInput}
								// label={this.state.hasBabyLable}
								label={"No"}
								setFocus={editable}
								//toggle={true}
								//checked={person.IsBabyBorn}
								checked={this.state.hasBabyNo}
								onChange={this.handleChangeBabyBornNoClickHandler}
								value={false}
								setvalue={false}
								//  disabled={!editable}
							/>
							<br />
						</div>
						<Grid.Column width={8} floated="right">
							<Field
								name="ExpectedDueDate"
								component={DateInputMyProfile}
								label="Due Date"
								dateFormat={"dd LLL yyyy"}
								minDate={new Date().setFullYear(new Date().getFullYear())}
								maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
								//disabled={!editable}
							/>
						</Grid.Column>

						{this.state.hasBabyYes && (
							<Grid.Row columns={2}>
								<Grid.Column width={8}>
									<Grid>
										<Grid.Column width={4}>
											<h4>Gender</h4>
										</Grid.Column>
										<Grid.Column width={6}>
											<div style={{ textAlign: "left" }}>
												<Field
													name="IsBoyYN"
													component={CheackBoxInput}
													label="Boy(s)"
													checked={this.state.boy}
													onChange={this.handleBoyClickHandler}
													setFocus={editable}
													value={this.state.boy}
													setvalue={this.state.boy}
													//  disabled={!editable}
												/>
												<Field
													name="IsGirlYN"
													component={CheackBoxInput}
													label="Girl(s)"
													setFocus={editable}
													checked={this.state.girl}
													value={this.state.girl}
													setvalue={this.state.girl}
													onChange={this.handleGirlClickHandler}
													//  disabled={!editable}
												/>
											</div>
										</Grid.Column>
									</Grid>
								</Grid.Column>

								<Grid.Column width={8}>
									<Grid>
										<Grid.Column width={4}>
											<h4>Delivery was...</h4>
										</Grid.Column>
										<Grid.Column width={8}>
											<div style={{ textAlign: "left" }}>
												<Field
													name="NaturalBirth"
													component={RadioInput}
													// type = {"radio"}
													label=" Natural birth"
													setFocus={editable}
													value={this.state.checkedN}
													setvalue={this.state.checkedN}
													checked={this.state.checkedN}
													onChange={this.handleNaturalBirthChange}
													//  disabled={!editable}
												/>

												<Field
													name="CesareanSection"
													component={RadioInput}
													// type = {"radio"}
													label=" Cesarean section"
													setFocus={editable}
													value={this.state.checkedC}
													setvalue={this.state.checkedC}
													checked={this.state.checkedC}
													onChange={this.handleCesareanSectionChange}

													//  disabled={!editable}
												/>
											</div>
											<br />
										</Grid.Column>
									</Grid>
								</Grid.Column>

								<Grid.Column width={8}>
									<div style={{ alignItems: "left" }}>
										<Field
											name="BabyDOB"
											component={DateInputMyProfile}
											label="Birth Date"
											dateFormat={"dd LLL yyyy"}
											minDate={new Date().setFullYear(new Date().getFullYear() - 2)}
											maxDate={new Date().setFullYear(new Date().getFullYear())}
											// setFocus={editable}
											// disabled={!editable}
										/>
									</div>
								</Grid.Column>
								<Grid.Column width={8}>
									{this.state.checkedC && (
										<Field
											name="DeliveryTypeReasonID"
											label="C-Section Reason"
											component={SelectInput}
											options={reasonsList()}
											reasonChange={this.checkReason}
											setFocus={editable}
											placeholder={
												reasonsList().length === 0
													? "No reasons available"
													: "Select a Reason"
											}
										/>
									)}
								</Grid.Column>
								{this.state.showOtherReason && (
									<Grid.Column width={16}>
										<Field
											name="DeliveryTypeReason_Other"
											label="Motivation"
											component={TextAreaInput}
										/>
									</Grid.Column>
								)}
							</Grid.Row>
						)}
					</Grid>
				</Segment>
			</div>
		);
	}
}

export default compose(
	connect(mapState, actions)
	// reduxForm({
	//   form: "PersonalInformationModal",
	//   enableReinitialize: true,
	//   destroyOnUnmount: false
	// })
)(ChildInformationModal);
