import { createReducer } from "../../../app/store/createReducer";
import {
	BENEFITS_LOADING,
	BENEFITS_LOADING_COMPLETE,
	LOAD_BENEFITS,
} from "./pebbleConstants";

function getInitialState() {
	return {
		loading: false,
	};
}

const initialState = getInitialState();

const loading = (state, payload) => {
	return {
		...state,
		loading: true,
	};
};

const loadingCompleted = (state, payload) => {
	return {
		...state,
		loading: false,
	};
};

const loadBenefitDetails = (state, payload) => {
	return {
		...state,
		benefitsDetails: payload,
	};
};

// const clearAmbassador = (state, payload) => {
//   return getInitialState();
// };

export default createReducer(initialState, {
	[BENEFITS_LOADING]: loading,
	[BENEFITS_LOADING_COMPLETE]: loadingCompleted,
	[LOAD_BENEFITS]: loadBenefitDetails,
});
