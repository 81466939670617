import { isLoggedIn, loginUser } from "../../auth/authActions";
import React, { Component } from "react";
import { Form, Grid, Header } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { combineValidators, isRequired } from "revalidate";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import { withRouter } from "react-router";
import { isMobileView } from "../../../app/common/utils/common";
import { loadPerson } from "../../nav/Sidebar/data/sideBarActions";
import { loadQuestion } from "../../progressiveProfiling/data/progressiveProfilingQuestionsActions";
import DropdownDateInput from "../../../app/common/form/DropdownDateInput";
import { parseISO } from "date-fns";
import { SecondaryButton } from "../../../styledComponents";

const mapState = (state) => ({
	person: state.sideBar.person,
	questions: state.progressiveProfiling.questions,
});

const actions = {
	loginUser,
	loadPerson,
	loadQuestion,
};

export const validate = combineValidators({
	cellNumber: isRequired({ message: "Cell Number is required" }),
	//dob: isRequired({ message: 'Date of Birth is required' })
});

class LoginForm extends Component {
	constructor(props) {
		super(props);
		this.state = { year: null, month: null, day: null };
	}
	state = {
		submitting: false,
	};

	//
	// componentDidMount() {
	//   this.props.loadPerson();
	//   this.props.loadQuestion();
	// }

	async componentDidUpdate() {
		const { person, questions } = this.props;

		if (isLoggedIn() && person._event !== undefined) {
			var buttonColor = ["#0cb2a6", "White"];
			var buttonTextColor = ["White", "#0cb2a6"];
			var buttonBorderColor = ["White", "#0cb2a6"];
			localStorage.setItem("buttonColor", JSON.stringify(buttonColor));
			localStorage.setItem("buttonTextColor", JSON.stringify(buttonTextColor));
			localStorage.setItem("buttonBorderColor", JSON.stringify(buttonBorderColor));

			localStorage.setItem("FirstName", person.FirstName);
			localStorage.setItem("LastName", person.LastName);
			localStorage.setItem("DOB", person.DOB);

			localStorage.setItem("Address", person.Address);

			localStorage.setItem("SchemeOption", person.SchemeOption);
			if (
				person.PersonType === "M" ||
				(person.PersonType === "BB" && person._bbLinkedEvent.length > 1)
			) {
				localStorage.setItem("ContentPageID", person._cpInfo[0].ContentPageID);
				localStorage.setItem("WeeksPregnant", person._cpInfo[0].WeeksPregnant);
				localStorage.setItem("BabySize", person._cpInfo[0].BabySize);
				for (let i = 0; i < person._event.length; i++) {
					//this.props.history.push(`/content/${ person.ContentPageID }`);

					// localStorage.setItem("BEDD", "2020-02-25T00:00:00");
					//use for current baby
					localStorage.setItem("EDB", person._event[0].EstimatedBirthDate);
					localStorage.setItem("EDD", person._event[0].EstimatedDueDate);
					localStorage.setItem("BEDD", person._event[0].BenefitExpectedDueDate);
					//set total babies
					// localStorage.setItem("EDB" + i, person._event[i].EstimatedBirthDate);
					// localStorage.setItem("EDD" + i, person._event[i].EstimatedDeliveryDate);
					// localStorage.setItem(
					// 	"BEDD" + i,
					// 	person._event[i].BenefitExpectedDueDate
					//);
				}
			} else {
				let EstimatedBirthDate;
				let EstimatedDeliveryDate;
				let BenefitExpectedDueDate;
				let ContentPageID;
				let WeeksPregnant;
				//console.log(person._event);
				for (let i = 0; i < person._event.length; i++) {
					if (person._event[i].EventID === person._bbLinkedEvent[i].EventID) {
						EstimatedBirthDate = person._event[i].EstimatedBirthDate;
						EstimatedDeliveryDate = person._event[i].EstimatedDueDate;
						BenefitExpectedDueDate = person._event[i].BenefitExpectedDueDate;
						ContentPageID = person._cpInfo[i].ContentPageID;
						WeeksPregnant = person._cpInfo[i].WeeksPregnant;
					}
				}

				localStorage.setItem("EDB", EstimatedBirthDate);
				localStorage.setItem("EDD", EstimatedDeliveryDate);
				localStorage.setItem("BEDD", BenefitExpectedDueDate);
				localStorage.setItem("ContentPageID", ContentPageID);
				localStorage.setItem("WeeksPregnant", WeeksPregnant);
			}

			try {
				if (questions.ProfilingQuestions.length > 0) {
					localStorage.setItem(
						"questionAmount",
						questions.ProfilingQuestions.length
					);
					localStorage.setItem("currentQuestionAmmount", 0);
				}
			} catch {}
		}
	}

	handleLogin = async (values) => {
		let loginDate =
			localStorage.getItem("loginYear") +
			localStorage.getItem("loginMonth") +
			localStorage.getItem("loginDay");
		let convertLoginDate = loginDate
			? Object.prototype.toString.call(loginDate) !== "[object Date]"
				? parseISO(loginDate)
				: loginDate
			: null;
		let toSubmit;
		toSubmit = {
			cellNumber: values.cellNumber,
			dob: convertLoginDate,
		};

		try {
			const contentPageID = await this.props.loginUser(toSubmit);
			//console.log(contentPageID);
			let contentPageIDNOW = await localStorage.getItem("ContentPageID");
			await this.props.loadPerson();
			// localStorage.removeItem("loginYear");
			// localStorage.removeItem("loginMonth");
			// localStorage.removeItem("loginDay");

			try {
				await this.props.loadQuestion(localStorage.getItem("BEDD"));
				// await this.props.loadQuestion("2020-02-26T00:00:00");
			} catch {
				this.props.history.push(`/content/${contentPageIDNOW}`);
			}

			const { person, questions } = this.props;
			//console.log(person._event);
			localStorage.setItem("currentPersonType", person._event[0].EventTypeCode);

			this.setState({ submitting: true });

			if (
				person.MonthHasPassed &&
				person.PersonType === "M" &&
				person.EventTypeCode === "P"
			) {
				this.props.history.push("/verifyDueDate");
			} else if (
				!person.MonthHasPassed &&
				questions.ProfilingQuestions.length > 0
			) {
				this.props.history.push("/progressiveProfiling");
			} else if (
				person.MonthHasPassed &&
				questions.ProfilingQuestions.length > 0
			) {
				this.props.history.push("/progressiveProfiling");
			} else {
				if (
					person.PersonType === "M" ||
					(person.PersonType === "BB" && person._bbLinkedEvent.length > 1)
				) {
					this.props.history.push(`/content/${contentPageID}`);
				} else {
					this.props.history.push(`/content/${contentPageIDNOW}`);
					// this.props.history.push(`/content/${contentPageID}`);
				}
			}
		} catch (error) {
			// An error occured - the toast was thrown in the action
		} finally {
			this.setState({ submitting: false });
		}
	};

	render() {
		const { handleSubmit } = this.props;

		return (
			<div style={{ position: "center" }}>
				<div
					className="byy-white"
					style={{
						borderRadius: "50%",
						position: "absolute",
						width: 400,
						height: 400,
						margin: isMobileView() ? "20px 0 10 10px" : "50px 0 0 50px",
					}}
				/>
				<div
					style={{
						borderRadius: "50%",
						position: "absolute",
						width: 300,
						height: 300,
						padding: "35px 0 20px 100px",
						margin: isMobileView() ? "20px 0 0 -35px" : "50px 0 0 50px",
					}}
				>
					<Grid
						textAlign="center"
						verticalAlign="middle"
						alignContent="center"
						stackable
					>
						<Grid.Column style={{ maxWidth: 300 }}>
							<Header
								as="h2"
								// className='byy-text-pink'
								textAlign="center"
								style={{ fontWeight: "normal", width: 220 }}
							>
								SUBSCRIBE <b className="login2">2</b> <br />
								<b>YOUR PARENTING JOURNEY</b>
							</Header>
							<Form
								autoComplete="off"
								onSubmit={handleSubmit(this.handleLogin)}
								// className='byy-text-pink'
								style={{
									textAlign: "center",
									alignContent: "center",
									fontWeight: "bold",
									margin: "2",
								}}
							>
								<Field
									name="cellNumber"
									component={PhoneNumberInput}
									// placeholder='Cell Number'
									// placeholderTextColor='black'
									label="Cell Number"
									setFocus
									defaultValue="Cell Number"
								/>
								<Field
									style={{
										textAlign: "center",

										fontWeight: "bold",
										margin: "2",
										alignContent: "center",
									}}
									name="dob"
									component={DropdownDateInput}
									label="My date of birth"
									year={this.state.year}
									month={this.state.month}
									day={this.state.day}
									minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
									maxDate={new Date().setFullYear(new Date().getFullYear() - 10)}
								/>
								<br />
								<SecondaryButton color="byy-pink" size="medium" content="Login" />
							</Form>
						</Grid.Column>
					</Grid>
				</div>
				<br />
			</div>
		);
	}
}

export default compose(
	withRouter,
	connect(mapState, actions),
	reduxForm({
		form: "loginForm",
		validate,
		enableReinitialize: true,
		destroyOnUnmount: false,
	})
)(LoginForm);
