import React from "react";
import FullBanner from "../pageContent/FullBanner";
import "./ProgressiveProfile.css";
import ProgressiveProfilingQuestions from "./ProgressiveProfilingQuestions";

const ProgressiveProfiling = () => {
  return (
    <div>
      <div
        className="mainDivProgressiveProfiling"
        style={{ position: "relative" }}
      >
        <FullBanner />
        <br />
        <div
          className="main-background"
          style={{ backgroundImage: "url('../assets/loginpagebaby2.jpg')" }}
        >
          <ProgressiveProfilingQuestions />
        </div>
      </div>
    </div>
  );
};

export default ProgressiveProfiling;
