import React, { Component } from "react";
import { Accordion, Icon, Image, Grid } from "semantic-ui-react";

class PebbleAccordionBaby extends Component {
	state = { activeIndex: -1 };

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	render() {
		const { activeIndex } = this.state;

		return (
			<div className="accordion">
				{/* <div className='mainDivMyProfile'>
					<p className='title'>How it works?</p>
					<h3 className='byy-text-pink'>
						{" "}
						(As a Profmed member you receive a specific number of pebbles based
						on your medical plan. The benefits available can be redeemed by
						exchanging the specified number of pebbles. The benefit choice is
						entirely yours! <br /> Once you select the redeem button at the
						bottom of the page, the benefit is allocated and where applicable
						delivery will be arranged.)
						<br />
					</h3>
				</div> */}
				<Accordion>
					{/* <Accordion.Title
						active={activeIndex === 0}
						index={0}
						onClick={this.handleClick}
					>
						<Icon name='dropdown' />
						<b>How it works?</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 0}>
						<p>
							As a Profmed member you receive a specific number of pebbles based
							on your medical plan. The benefits available can be redeemed by
							exchanging the specified number of pebbles. The benefit choice is
							entirely yours! <br /> Once you select the redeem button at the
							bottom of the page, the benefit is allocated and where applicable
							delivery will be arranged.
						</p>
					</Accordion.Content> */}
					<b>
						<u>What are the benefits?</u>
					</b>
					<Accordion.Title
						active={activeIndex === 1}
						index={1}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Designer Swag Bag</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 1}>
						<p>
							We've selected a luxury designer bag by The Lily Rose Collection
							<a
								href="https://lilyrosecollection.co.za"
								target="_blank"
								rel="noopener noreferrer"
							>
								{" "}
								(lilyrosecollection.co.za)
							</a>{" "}
							just for you, with a choice of 5 gorgeous prints. Plus you get, as a
							bonus, a great selection of products included to support moms journey
							from a women to a mom. Please note that the product inserts differ based
							on seasonal and stock availability. <br />
							You will be required to provide a delivery address so that we can arrange
							for it to be delivered by courier. To redeem a designer Lily Rose
							Collection swag bag exchange 3 pebbles.
							<br />
							Please note that only one Mommy Swag bag is redeemable per member.
						</p>
						<p>Please allow 21 - 30 working days for delivery.</p>
						<p>
							<b>Swag Bag Warranty :</b>
							<br />
							<ul>
								<li>
									All bags carry a 30 day warranty from delivery, after which no
									exchanges or returns are permitted.
								</li>
								<li>
									Upon receiving the Swag Bag, members should notify BabyYumYum
									immediately and arrange to return the bag, complete with all contents,
									within 30 days of delivery if there are any faults with it.
								</li>
								<li>
									If a fault occurs after use, within 30 days of receiving the bag, the
									member must return the bag, at their cost, for inspection, upon which
									the supplier will determine if the bag is to be repaired or replaced
									due to a manufacturing default.
								</li>
								<li>
									When exchanging, the member cannot change the print. It remains the
									same as on the original received bag.
								</li>
								<li>
									Prints are seasonal, unfortunately print selection cannot be made.
								</li>
							</ul>
						</p>
						<Image src="../assets/BagBanner.jpg" fluid />
					</Accordion.Content>

					<Accordion.Title
						active={activeIndex === 2}
						index={2}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Gift Hamper</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 2}>
						<p>
							Specially curated to support you and your little one from birth, we have
							a selection of 3 exquisite gift hampers for you to choose from. Ranging
							from a hamper that helps you monitor your baby while they sleep to a
							hamper with all the breastfeeding essentials; each including premium
							quality, trusted baby brands.
						</p>
						<p>
							<b>
								Gift Hamper 1 <br />
								<b style={{ color: "red" }}>
									Please note, you may select this item, however it will only be
									available for delivery from 1 October 2024
								</b>
								<br />
								Safety First <br />o R1200 voucher code (emailed to you directly)
								towards any Bebcare Monitoring product purchased at{" "}
								<a
									href="https://bebcare.co.za"
									target="_blank"
									rel="noopener noreferrer"
								>
									{" "}
									bebcare.co.za
								</a>{" "}
							</b>
							<br />
							o Multi-award-winning monitoring systems
							<br />
							o Patented DSR Ultra-low radiation technology
							<br />
							o Emitting 93% less electromagnetic emissions than any other monitor on
							the market
							<br />
							o Incredible range
							<br />
							o Quality audio and video
							<br />
							o Excellent battery life
							<br />
							o Easy to set up
							<br />
							o Two-way talk back
							<br />
							o Temperature sensor
							<br />
							o White noise cancelling
							<br />
							o Music and lullabies
							<br />
							Please note you may only redeem one of the three gift hampers
							<br />
							<Image
								src="../assets/StorkBrands_Profmed_SafetyFirst.jpg"
								size="medium"
							/>
						</p>
						<p>
							<b>
								Gift Hamper 2 <br /> Breastfeeding
							</b>
							<br />
							This hamper provides all the important breastfeeding essentials from
							award winning Tommee Tippee.
							<br />
							Included:
							<br />
							o Tommee Tippee Manual Brestpump
							<br />
							o Tommee Tippee Bottle 150ml (2pk)
							<br />
							o Tommee Tippee Breastmilk Storage Bags (pk)
							<br />
							o Tommee Tippee Breastpads (20pk)
							<br />
							Please note you may only redeem one of the three gift hampers
							<br />
							<Image
								src="../assets/StorkBrands_Profmed_Breastfeeding.jpg"
								size="medium"
							/>
						</p>
						<p>
							<b>
								Gift Hamper 3 <br />
								Changing Stations
							</b>
							<br />
							This hamper provides all the essentials needed for nappy changing.
							<br />
							Included:
							<br />
							o Ruby Melon Comfort Change Mat
							<br />
							o Ruby Melon Change Mat Cover
							<br />
							o Tommee Tippee Nappy Bin
							<br />
							o Oh Lief Wipes
							<br />
							o Oh Lief Bum Balm
							<br />
							o Oh Lief Aqueous Cream
							<br />
							Please note you may only redeem one of the three gift hampers
							<br />
							<Image
								src="../assets/StorkBrands_Profmed_ChangingStations.jpg"
								size="medium"
							/>
							<br />
							When gift hamper 2 or 3 is selected, you will be required to provide a
							delivery address so that we can arrange for it to be delivered by
							courier. To redeem one of the StorkBrands Gift Hampers, exchange 3
							pebbles.{" "}
							<b>
								Please allow 21 - 30 working days for all gift hamper deliveries (Via
								courier or email).
							</b>
						</p>
					</Accordion.Content>

					<Accordion.Title
						active={activeIndex === 3}
						index={3}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Mr D Meal Voucher</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 3}>
						<p>
							Get a helping hand with getting dinner on the table on the nights where
							there isn't enough time to make a meal. Redeem your pebbles for a
							discount meal voucher valued at R350 to be used on the Mr D App.
							<br />
							The voucher will be sent to you by SMS
							<br />
							To redeem a MrD Meal Voucher exchange 1 pebble.
						</p>
					</Accordion.Content>

					<Accordion.Title
						active={activeIndex === 4}
						index={4}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Baby and Child Care Handbook</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 4}>
						<p>
							“This book is a veritable one-stop knowledge shop…not only does it seem
							to cover every single solitary freaky, benign, apparently stupid or
							incredibly bright question a fuzzy-headed mother might find herself
							asking, but it does so in a calm, credible, non-judgemental,
							non-patronising tone…” Karin Schimke, Cape Times
							<br />
							When the handbook is selected, you will be required to provide a delivery
							address so that we can arrange for it to be delivered by courier.
							<br />
							To redeem a Baby and Child Care Handbook exchange 1 pebble.
						</p>
					</Accordion.Content>
					<Accordion.Title
						active={activeIndex === 5}
						//active={true}
						index={5}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Momcierge</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 5}>
						<p>
							Think back to your first “club”, maybe it was your Garbage Pail Kids Card
							Club at school or the first bank account card club you had. Whatever it
							was, it stirs up special feelings and a connection with a brand that is
							priceless. You felt a sense of belonging and connection; that is the
							vision behind The BabyYumYum Momcierge Club. This Momcierge Club provides
							the member with all sorts of goodies, a virtual membership badge to
							display on social media, monthly specials and vouchers, monthly swag,
							sampling opportunities, complimentary access to events, discounted
							products and services, informative and educational content and videos,
							and the opportunity to connect with like-minded people who are trying to
							raise a family the best way that they know how.
							<br />
							When the Momcierge Club service is selected, you will receive a call from
							one of our agents to assist you.
							<br />
							To redeem access to the Momcierge Club service exchange 1 pebble.
						</p>
					</Accordion.Content>
					<Accordion.Title
						active={activeIndex === 6}
						//active={true}
						index={6}
						onClick={this.handleClick}
					>
						<Icon name="dropdown" />
						<b>Giving Back</b>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 6}>
						<p>
							The Glow movement is a non-profit organisation that supports and empowers
							women through various programmes. BabyYumYum has joined forces with Glow
							movement to support a number of their initiatives, including the Moments
							mom programme. This programme supports women from disadvantaged
							communities with antenatal, emotional and mental-health support; while
							also advocating for an environment that ensures basic provision of
							emergency obstetric care for mothers from all walks of life. <br />
							When Giving Back is selected, the value of your pebble will be converted
							to a donation, which BabyYumYum will ensure is received by the selected
							charity.
							<br />
							You can exchange from 1 pebble.
						</p>
					</Accordion.Content>
				</Accordion>
			</div>
		);
	}
}

export default PebbleAccordionBaby;
