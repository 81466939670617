import React from "react";
import { Form } from "semantic-ui-react";

const RadioInput = ({
	input,
	height,
	width,
	type,
	label,
	toggle,
	id,
	setvalue,
	checked,
	disabled,
	labelStyle,
}) => {
	const styles = {
		radioPink: {
			border: "10px solid #EF959D",
		},
	};

	// pink on click
	styles.radioPink["backgroundColor"] = "#EF959D";
	return (
		<Form.Field>
			<div className={`ui ${toggle ? "checkbox toggle" : "radio"}`}>
				<input
					toggle
					id={id}
					{...input}
					value={setvalue}
					type='radio'
					checked={checked}
					disabled={disabled}
					readonly=''
					tabindex='0'
					height={height}
					width={width}
				/>
				<label>
					<span style={labelStyle}>{label}</span>
				</label>
			</div>
		</Form.Field>
	);
};

export default RadioInput;
