import React, { Component } from "react";
import { Segment, Grid } from "semantic-ui-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import DateInputMyProfile from "../../../app/common/form/DateInputMyProfile";
import PhoneNumberInputMyProfile from "../../../app/common/form/PhoneNumberInputMyProfile";
import {
	loadPerson,
	savePerson,
} from "../../../features/nav/Sidebar/data/sideBarActions";
import BirthingBuddyAccordion from "../../Accordions/BirthingBuddyAccordion";
import ParentingBuddyAccordion from "../../Accordions/ParentingBuddyAccordion";

const mapState = (state) => ({
	loading: state.sideBar.loading,
	person: state.sideBar.person,
	initialValues: state.sideBar.person,
});

const actions = {
	loadPerson,
};

class BirthingBuddyModal extends Component {
	state = {
		userView: "",
	};

	async componentDidMount() {
		const { person } = this.props;
		this.setState({ userView: person.PersonType });
		// this.setState({ userView: 'M' });
		// this.setState({ userView: 'BB' });
	}

	render() {
		const editable = false;
		const { selectedEventType } = this.props;
		return (
			<div className="mainDivMyProfile">
				<Segment>
					{this.state.userView === "M" && (
						<div>
							{selectedEventType === "P" && (
								<p className="title"> Birthing Buddy Information</p>
							)}
							{selectedEventType === "T" && (
								<p className="title"> Parenting Buddy Information</p>
							)}
							{selectedEventType === "P" && (
								<div style={{ textAlign: "left" }}>
									<BirthingBuddyAccordion />
								</div>
							)}
							{selectedEventType === "T" && (
								<div style={{ textAlign: "left" }}>
									<ParentingBuddyAccordion />
								</div>
							)}

							<br />
							{selectedEventType === "P" && (
								<h4 className="byy-text-pink">
									I have asked the following person to be my birthing buddy and they are
									happy for me to submit their personal information to BabyYumYum for
									this maternity programme.
									<br />
									Don't worry if you don't have this info now, you can always come back
									later to fill it in
								</h4>
							)}
							{selectedEventType === "T" && (
								<h4 className="byy-text-pink">
									<b>
										I have asked the following person to be my parenting buddy and they
										are happy for me to submit their personal information to BabyYumYum
										for this toddler programme.
									</b>
									<br />
									This is optional, so don't worry if you don't have this info now, you
									can always come back later to fill it in
								</h4>
							)}
						</div>
					)}
					{this.state.userView === "BB" && (
						<div>
							<p className="title"> My Personal Information</p>
						</div>
					)}
					<Grid stackable>
						<Grid.Row columns={2}>
							<Grid.Column>
								<Field
									name="BirthingBuddyName"
									component={TextInput}
									label="First Name"
									setFocus={editable}
									// disabled={!editable}
								/>
							</Grid.Column>

							<Grid.Column>
								<Field
									name="BirthingBuddySurname"
									component={TextInput}
									label="Last Name"
									// disabled={!editable}
								/>
							</Grid.Column>

							<Grid.Column>
								<Field
									name="BirthingBuddyMobileNum"
									component={PhoneNumberInputMyProfile}
									label="Cell Number"
									// disabled={!editable}
								/>
							</Grid.Column>

							<Grid.Column>
								<Field
									name="BirthingBuddyDOB"
									component={DateInputMyProfile}
									label="Date of Birth"
									// disabled={!editable}
									dateFormat={"dd LLL yyyy"}
									minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
									maxDate={new Date().setFullYear(new Date().getFullYear() - 10)}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			</div>
		);
	}
}

export default compose(
	connect(mapState, actions)
	// reduxForm({
	//   form: "PersonalInformationModal",
	//   enableReinitialize: true,
	//   destroyOnUnmount: false
	// })
)(BirthingBuddyModal);
