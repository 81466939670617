import React, { Component } from "react";
import "./contactBabyYumYum.css";
import FullBanner from "../pageContent/FullBanner";
import ScrollTop from "../scrollTop/ScrollTop";
import { Segment, Icon, Accordion } from "semantic-ui-react";

class ContactBabyYumYum extends Component {
	state = { activeIndex: -1 };
	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};
	render() {
		const { activeIndex } = this.state;
		let ussd =
			"tel:" + encodeURI("*") + "134" + encodeURI("*") + "677" + encodeURI("#");
		return (
			<div className="area">
				<div className="contactBabyBanner">
					<FullBanner />
				</div>

				<Segment>
					<p className="title">
						<b>Important Contact Details</b>
						<br />
					</p>
					<Accordion className="accordion">
						<b className="leftInfo">Profmed Tums2Tots</b>
						<Accordion.Title
							active={activeIndex === 1}
							index={1}
							onClick={this.handleClick}
						>
							<Icon name="dropdown" />
							<b className="contactheading">Profmed member call centre</b>
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 1}>
							<a
								className="contactText"
								name="format-detection"
								content="telephone=no"
								href="tel:0860 776 363"
							>
								0860 776 363
							</a>
						</Accordion.Content>
						<Accordion.Title
							active={activeIndex === 2}
							index={2}
							onClick={this.handleClick}
						>
							<Icon name="dropdown" />
							<b className="contactheading">Profmed pre-authorisation call centre</b>
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 2}>
							<a
								className="contactText"
								name="format-detection"
								content="telephone=no"
								href="tel:0860 776 363"
							>
								0860 776 363
							</a>
						</Accordion.Content>
						<Accordion.Title
							active={activeIndex === 3}
							index={3}
							onClick={this.handleClick}
						>
							<Icon name="dropdown" />
							<b className="contactheading">Profmed queries email</b>
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 3}>
							<a
								className="contactText"
								href="mailto:tums2tots@profmed.co.za"
								target="_blank"
								rel="noopener noreferrer"
							>
								tums2tots@profmed.co.za
							</a>
						</Accordion.Content>
						<Accordion.Title
							active={activeIndex === 4}
							index={4}
							onClick={this.handleClick}
						>
							<Icon name="dropdown" />
							<b className="contactheading">Profmed Health Claims Email</b>
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 4}>
							<a
								className="contactText"
								href="mailto:claims@profmed.co.za"
								target="_blank"
								rel="noopener noreferrer"
							>
								claims@profmed.co.za
							</a>
						</Accordion.Content>
						<Accordion.Title
							active={activeIndex === 5}
							index={5}
							onClick={this.handleClick}
						>
							<Icon name="dropdown" />
							<b className="contactheading">Profmed website</b>
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 5}>
							<a
								className="contactText"
								href="https://profmed.co.za/member-services/profmed-baby/"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://profmed.co.za/member-services/profmed-baby/
							</a>
						</Accordion.Content>
					</Accordion>

					<Accordion className="accordion">
						<br />
						<b className="leftInfo">BabyYumYum</b>
						<br />
						<br />
						<b className="byyInfo">Baby programme</b>
						<br />
						<Accordion.Title
							active={activeIndex === 6}
							index={6}
							onClick={this.handleClick}
						>
							<Icon name="dropdown" />
							<b className="contactheading">BabyYumYum Call Centre</b>
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 6}>
							<a className="contactText" href="tel:087 092 9606">
								087 092 9606
							</a>
						</Accordion.Content>
						<Accordion.Title
							active={activeIndex === 7}
							index={7}
							onClick={this.handleClick}
						>
							<Icon name="dropdown" />
							<b className="contactheading">BabyYumYum Email</b>
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 7}>
							<a className="contactText" href="mailto:ProfmedT2T@babyyumyum.co.za">
								ProfmedT2T@babyyumyum.co.za
							</a>
						</Accordion.Content>
						<Accordion className="accordion">
							<b className="byyInfo">Toddler programme</b>
							<br />
							<Accordion.Title
								active={activeIndex === 8}
								index={8}
								onClick={this.handleClick}
							>
								<Icon name="dropdown" />
								<b className="contactheading">BabyYumYum Call Centre</b>
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 8}>
								<a className="contactText" href="tel:087 897 0369">
									087 897 0369
								</a>
							</Accordion.Content>
							<Accordion.Title
								active={activeIndex === 9}
								index={9}
								onClick={this.handleClick}
							>
								<Icon name="dropdown" />
								<b className="contactheading">BabyYumYum Email</b>
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 9}>
								<a
									className="contactText"
									href="mailto:profmedtoddler@babyyumyum.co.za"
								>
									Profmedtoddler@babyyumyum.co.za
								</a>
							</Accordion.Content>
						</Accordion>
						<b className="byyInfo">ProfmedTums2Tots BabyYumYum Portal</b>
						<br />
						<Accordion.Title
							active={activeIndex === 10}
							index={10}
							onClick={this.handleClick}
						>
							<Icon name="dropdown" />
							<b className="contactheading">ProfmedTums2Tots BabyYumYum Portal</b>
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 10}>
							<a
								className="contactText"
								href="ProfmedT2T.babyyumyum.co.za"
								target="_blank"
								rel="noopener noreferrer"
							>
								ProfmedT2T.babyyumyum.co.za
							</a>
						</Accordion.Content>
					</Accordion>
				</Segment>
				<ScrollTop />
			</div>
		);
	}
}

export default ContactBabyYumYum;
