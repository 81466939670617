import React from "react";
import "./FAQs.css";
import FullBanner from "../pageContent/FullBanner";
import ScrollTop from "../scrollTop/ScrollTop";
import { Segment } from "semantic-ui-react";
import { Accordion, Icon, Image } from "semantic-ui-react";
import ToddlerFAQ from "./ToddlerFAQ";
import BabyFAQ from "./BabyFAQ";

const FAQs = () => {
	return (
		<container className="area">
			<FullBanner />
			<Segment>
				<ScrollTop />
				<p className="title">
					<b>Frequently Asked Questions</b>
					<br />
				</p>
				<container style={{ textAlign: "left" }}>
					<p className="FAQHeading">
						<b>FAQ's</b>
						<br />
					</p>
					<BabyFAQ />
					<br />
					<ToddlerFAQ />
				</container>
			</Segment>
		</container>
	);
};

export default FAQs;
