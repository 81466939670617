import React, { Component, Fragment } from "react";
import { Container, Icon } from "semantic-ui-react";
import { openModal } from "../../../app/modals/modalActions";
import { compose } from "redux";
import { connect } from "react-redux";
import "./homePage.css";
import { Helmet } from "react-helmet";
import LoginForm from "../loginModal/LoginForm";
import { isMobileView } from "../../../app/common/utils/common";
import FullBanner from "../../pageContent/FullBanner";

const actions = {
	openModal,
};

class HomePage extends Component {
	async componentDidMount() {
		// if (isLoggedIn()) {
		//   const role = getRole();
		//   this.props.history.push(`/${role.toLowerCase()}`);
		// }
	}

	handleShowLogin = () => {
		this.props.openModal("LoginModal", { hasLoggedIn: this.handleLoggedIn });
	};

	// handleLoggedIn = () => {
	//   const role = getRole();
	//   this.props.history.push(`/${role.toLowerCase()}`);
	// };

	render() {
		return (
			<Fragment>
				<Helmet>
					<title>BabyYumYum - Login</title>
				</Helmet>
				<div className="homepage">
					<Container>
						<div className="fullBannerHomePage">
							<FullBanner />
							<br />
						</div>
						<div
							className="main-background"
							style={{ backgroundImage: "url('../assets/loginpagebaby2.jpg')" }}
						>
							<LoginForm />
						</div>
						<div className="roundedContainer">
							We look forward to being with you one baby step at a time.
						</div>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default compose(connect(null, actions))(HomePage);
