import React, { Component } from "react";
import "./Benefits.css";
import FullBanner from "../pageContent/FullBanner";
import ScrollTop from "../scrollTop/ScrollTop";
import { Segment, Accordion, Icon } from "semantic-ui-react";

class Benefits extends Component {
	state = { activeIndex: -1 };
	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};
	render() {
		const { activeIndex } = this.state;

		return (
			<container className="area">
				<FullBanner />

				<Segment>
					<p className="title">
						<b>Option Benefits</b>
						<br />
					</p>
					<Accordion className="accordion">
						<container style={{ textAlign: "left" }}>
							<p className="mainHeading">
								<u>Understanding your maternity benefits:</u>
								<br />
							</p>
							<Accordion.Title
								active={activeIndex === 1}
								index={1}
								onClick={this.handleClick}
							>
								<Icon name="dropdown" />
								<b className="benefitheading">Important Contact Information</b>
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 1}>
								<p>
									Profmed member call centre: <a href="tel:0860 776 363">0860 776 363</a>
									<br />
									Profmed pre-authorisation call centre:{" "}
									<a href="tel:0860 776 363">0860 776 363</a>
									<br />
									Profmed queries email:{" "}
									<a href="mailto:tums2tots@profmed.co.za">tums2tots@profmed.co.za</a>
									<br />
									Profmed claims email:{" "}
									<a href="mailto:claims@profmed.co.za">claims@profmed.co.za</a>
									<br />
									Profmed website:{" "}
									<a
										href="https://profmed.co.za/member-services/profmed-baby/"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://profmed.co.za/member-services/profmed-baby/
									</a>
									<br />
								</p>
							</Accordion.Content>
						</container>
					</Accordion>

					<Accordion className="accordion">
						<container style={{ textAlign: "left" }}>
							<p className="mainHeading">
								<u>Understanding your ProfmedTums2Tots BabyYumYum benefits:</u>
								<br />
							</p>
							<Accordion.Title
								active={activeIndex === 2}
								index={2}
								onClick={this.handleClick}
							>
								<Icon name="dropdown" />
								<b className="benefitheading">Important Contact Information</b>
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 2}>
								<p>
									<b>Baby programme</b>
									<br />
									<br />
									BabyYumYum call centre: <a href="tel:087 092 9605">087 092 9605</a>
									<br />
									BabyYumYum email:{" "}
									<a href="mailto:profmedt2t@babyyumyum.co.za">
										profmedt2t@babyyumyum.co.za
									</a>
									<br />
									<br />
									<b>Toddler programme</b>
									<br />
									<br />
									BabyYumYum call centre: <a href="tel:087 897 0369">087 897 0369</a>
									<br />
									BabyYumYum email:{" "}
									<a href="mailto:profmedtoddler@babyyumyum.co.za">
										profmedtoddler@babyyumyum.co.za
									</a>
									<br />
									<br />
									ProfmedTums2Tots BabyYumYum Baby & Toddler Portal:{" "}
									<a
										href="https://profmedt2t.babyyumyum.co.za"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://profmedt2t.babyyumyum.co.za
									</a>
									<br />
								</p>
							</Accordion.Content>
							<Accordion.Title
								active={activeIndex === 3}
								index={3}
								onClick={this.handleClick}
							>
								<Icon name="dropdown" />
								<b className="benefitheading">Scheme Option number of pebbles</b>
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 3}>
								<p>
									<b>ProPinnacle: 10</b>
									<br />
									<b>ProPinnacle Savvy: 10</b>
									<br />
									<b>ProSecure Plus: 6</b>
									<br />
									<b>ProSecure Plus Savvy: 6</b>
									<br />
									<b>ProSecure: 6</b>
									<br />
									<b>ProSecure Savvy: 6</b>
									<br />
									<b>ProActive Plus: 3</b>
									<br />
									<b>ProActive Plus Savvy: 3</b>
									<br />
									<b>ProSelect: 3</b>
									<br />
									<b>ProSelect Savvy: 3</b>
									<br />
									<br />
								</p>
							</Accordion.Content>
						</container>
					</Accordion>
				</Segment>
				<ScrollTop />
			</container>
		);
	}
}

export default Benefits;
