import styled from "styled-components";
import { Button, Segment, Step } from "semantic-ui-react";

export const ColourPalette = {
	dark_green: "#106434",
	light_green: "#84c556",
	orange: "#FAA431",
	black: "#000000",
	transparent: "rgba(0,0,0,0)",
	pink: "#f05159",
	white: "#ffffff!important",
	teal: "#0cb2a6",
};

export const ColourPaletteNB = {
	dark_green: "#106434!important",
	light_green: "#84C556!important",
	orange: "#FAA431!important",
	white: "#ffffff!important",
	black: "#000000!important",
	transparent: "rgba(0,0,0,0)!important",
	teal: "#0cb2a6",
};

/**********************
 BUTTON STYLES
 ***********************/
/* Example that allows you to override components more
 */
export const ExampleButton = styled.button`
	/* Adapt the colors based on primary prop */
	background: ${(props) => (props.primary ? "palevioletred" : "white")};
	color: ${(props) => (props.primary ? "white" : "palevioletred")};
	font-size: 1em;
	margin: 1em;
	padding: 0.25em 1em;
	border: 2px solid palevioletred;
	border-radius: 3px;
`;

/* Inject CSS into the style tag of a component this way
 */
export const buttonPrimaryStyle = {
	background: ColourPalette.orange,
	color: ColourPalette.white,
	fontSize: "15px",
	padding: "0.25em 1em",
	border: "2px solid #106434",
	borderRadius: "3px",
};

export const buttonSecondaryStyle = {
	background: ColourPalette.teal,
	color: ColourPalette.orange,
	fontSize: "15px",
	padding: "0.25em 1em",
	border: "2px solid" + ColourPalette.orange,
	borderRadius: "3px",
};

/* This overrides the style of a Semantic-UI component; 
   by keeping the Semantic Components base and adding
   extra to it.
 */
export const PrimaryButton = styled(Button)({
	background: ColourPaletteNB.pink,
	color: ColourPaletteNB.white,
});

export const SecondaryButton = styled(Button)({
	background: ColourPalette.teal,
	color: ColourPaletteNB.white,
	border: "2px solid" + ColourPaletteNB.white,
	opacity: 1,
});

export const TransparentButton = styled(Button)({
	background: ColourPaletteNB.transparent,
	color: ColourPaletteNB.transparent,
});

export const customStepper = styled(Step)({
	border: "2px solid" + ColourPalette.orange,
});

/**********************
 SEGMENT STYLES
 ***********************/
export const segmentHeaderStyle = {
	background: ColourPaletteNB.light_green,
	color: ColourPaletteNB.white,
	// fontSize: "15px",
	// padding: "0.25em 1em",
	// border: "2px solid #106434",
	// borderRadius: "3px"
};

export const SegmentHeader = styled(Segment)({
	background: ColourPaletteNB.light_green,
	color: ColourPaletteNB.white,
});
