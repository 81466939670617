import React, { Component, Fragment } from "react";
import { Segment, Form, Button, Grid } from "semantic-ui-react";
import ChildInformationModal from "./myProfileModals/ChildInformationModal";
import DeliveryAddressModal from "./myProfileModals/DeliveryAddressModal";
import BirthingBuddyModal from "./myProfileModals/BirthingBuddyModal";
import "./MyProfile.css";
import { loadProfileInfo } from "./data/myProfileActions";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import LoadingComponent from "../../app/layout/LoadingComponent";
// import { Divider, DropdownDivider, Grid, GridColumn, GridRow } from "semantic-ui-carousel-react";
import PersonalInformationModal from "./myProfileModals/PersonalInformationModal";
import {
	savePerson,
	loadPerson,
} from "../../features/nav/Sidebar/data/sideBarActions";

const mapState = (state) => ({
	myProfileInformation: state.profileInfo.profileInformation,
	loading: state.profileInfo.loading,
	initialValues: state.profileInfo.profileInformation,
});

const actions = {
	loadProfileInfo,
	savePerson,
	loadPerson,
};

class MyProfileFields extends Component {
	state = {
		buttonColor: ["#0cb2a6", "White"],
		buttonTextColor: ["White", "#0cb2a6"],
		buttonBorderColor: ["White", "#0cb2a6"],
		selectedEventType: "P",
		amountBabies: 0,
		amountTodlers: 0,
	};

	async componentDidMount() {
		const { event, person } = this.props;

		if (event !== undefined) {
			let values;
			this.setState({ selectedEventType: event[0].EventTypeCode });
			if (person.PersonType === "M") {
				values = {
					EventID: event[0].EventID,
					// PersonID: null
				};
			} else {
				values = {
					EventID: event[0].EventID,
					// PersonID: null
				};
			}
			await this.props.loadProfileInfo(values);
		}
	}

	handleChangeBabyClickHandler = async (EventID, idx) => {
		const { event } = this.props;
		let values = {
			EventID: EventID,
		};
		this.setState({ selectedEventType: event[idx].EventTypeCode });
		if (idx === 1) {
			let newButtonColor = ["White", "#0cb2a6"];
			let newButtonTextColor = ["#0cb2a6", "White"];
			let newButtonBorderColor = ["#0cb2a6", "White"];
			this.setState({ buttonColor: newButtonColor });
			this.setState({ buttonTextColor: newButtonTextColor });
			this.setState({ buttonBorderColor: newButtonBorderColor });
		} else {
			let newButtonColor = ["#0cb2a6", "White"];
			let newButtonTextColor = ["White", "#0cb2a6"];
			let newButtonBorderColor = ["White", "#0cb2a6"];
			this.setState({ buttonColor: newButtonColor });
			this.setState({ buttonTextColor: newButtonTextColor });
			this.setState({ buttonBorderColor: newButtonBorderColor });
		}
		await this.props.loadProfileInfo(values);
	};

	handleChangeBabyBBClickHandler = async (EventID, idx) => {
		let values = {
			EventID: EventID,
		};
		if (idx === 1) {
			let newButtonColor = ["White", "#0cb2a6"];
			let newButtonTextColor = ["#0cb2a6", "White"];
			let newButtonBorderColor = ["#0cb2a6", "White"];
			this.setState({ buttonColor: newButtonColor });
			this.setState({ buttonTextColor: newButtonTextColor });
			this.setState({ buttonBorderColor: newButtonBorderColor });
		} else {
			let newButtonColor = ["#0cb2a6", "White"];
			let newButtonTextColor = ["White", "#0cb2a6"];
			let newButtonBorderColor = ["White", "#0cb2a6"];
			this.setState({ buttonColor: newButtonColor });
			this.setState({ buttonTextColor: newButtonTextColor });
			this.setState({ buttonBorderColor: newButtonBorderColor });
		}
		await this.props.loadProfileInfo(values);
	};

	handleSavePerson = async (values) => {
		const { person } = this.props;
		console.log(values);

		let birthType;
		if (values.NaturalBirth) {
			values.DeliveryTypeCode = "N";
		} else {
			values.DeliveryTypeCode = "C";
		}
		let sendValues = {
			...values,
			FirstName: person.FirstName,
			LastName: person.LastName,
			DOB: person.DOB,
			SchemeOption: person.SchemeOption,
			PersonType: person.PersonType,
		};

		let loadData = {
			EventID: values.EventID,
		};

		try {
			await this.props.savePerson(sendValues);
			await this.props.loadPerson();
			await this.props.loadProfileInfo(loadData);
			this.reset();
		} catch (error) {
			// An error occured - the toast was thrown in the action
		} finally {
			this.props.loadProfileInfo(values);
		}
	};

	render() {
		const {
			event,
			loading,
			myProfileInformation,
			submitting,
			handleSubmit,
			pristine,
			userView,
			person,
		} = this.props;
		return (
			<div className="mainDivMyProfile">
				{event != undefined && event.length > 1 && (
					<div>
						{person.PersonType === "M" && (
							<div>
								<Grid>
									<Grid.Row columns={2}>
										{event.map((event, idx) => (
											<Grid.Column>
												<Segment
													style={{
														color: this.state.buttonTextColor[idx],
														backgroundColor: this.state.buttonColor[idx],
														borderWidth: "2px",
														borderColor: this.state.buttonBorderColor[idx],
													}}
													onClick={() =>
														this.handleChangeBabyClickHandler(event.EventID, idx)
													}
												>
													{event.EventTypeCode == "P" && <div>Baby</div>}
													{event.EventTypeCode == "T" && <div>Toddler</div>}
												</Segment>
											</Grid.Column>
										))}
									</Grid.Row>
								</Grid>
							</div>
						)}

						{person.PersonType === "BB" && person._bbLinkedEvent.length > 1 && (
							<div>
								<Grid>
									<Grid.Row columns={2}>
										{person._bbLinkedEvent.map((event, idx) => (
											<Grid.Column>
												<Segment
													style={{
														color: this.state.buttonTextColor[idx],
														backgroundColor: this.state.buttonColor[idx],
														borderWidth: "2px",
														borderColor: this.state.buttonBorderColor[idx],
													}}
													onClick={() =>
														this.handleChangeBabyBBClickHandler(event.EventID, idx)
													}
												>
													{event.EventTypeCode == "P" && <div>Baby</div>}
													{event.EventTypeCode == "T" && <div>Toddler</div>}
												</Segment>
											</Grid.Column>
										))}
									</Grid.Row>
								</Grid>
							</div>
						)}
					</div>
				)}

				<br />
				{loading && (
					<Segment attached style={{ minHeight: 418 }}>
						<LoadingComponent />
					</Segment>
				)}
				{!loading && myProfileInformation != undefined && (
					<Form autoComplete="off" onSubmit={handleSubmit(this.handleSavePerson)}>
						{userView === "M" && (
							<div>
								<div>
									{" "}
									<ChildInformationModal />{" "}
								</div>
								<br />

								{/* <div>
                  <DeliveryAddressModal />
                </div> */}

								<br />
								<div>
									<BirthingBuddyModal selectedEventType={this.state.selectedEventType} />
								</div>
							</div>
						)}
						{userView === "BB" && (
							<div>
								<div>
									{" "}
									<ChildInformationModal />{" "}
								</div>
							</div>
						)}
						<br />
						<Button.Group floated="right">
							<Button
								disabled={submitting || pristine}
								loading={submitting}
								size="large"
								color="red"
								icon="save"
								content="Save"
								style={{ width: 120 }}
							/>
							<Button
								disabled={submitting || pristine}
								as="a"
								onClick={this.handleCancel}
								size="large"
								icon="close"
								content="Cancel"
								style={{ width: 120 }}
							/>
						</Button.Group>
					</Form>
				)}
			</div>
		);
	}
}

export default compose(
	connect(mapState, actions),
	reduxForm({
		form: "MyProfileFields",
		enableReinitialize: true,
		destroyOnUnmount: false,
	})
)(MyProfileFields);
