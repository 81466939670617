import React from "react";
import { Grid, Image } from "semantic-ui-react";
//import ReactGA from "react-ga";

//test
const RightAdvert = ({
	mrdfoodClickHandler,
	lilletsClickHandler,
	veetClickHandler,
	detolClickHandler,
	JJClickHandler,
	sweepsouthClickHandler,
	imageNow,
	linkNow,
	imageNowTwo,
	linkNowTwo,
	imageNowThree,
	linkNowThree,
	imageNowFour,
	linkNowFour,
}) => {
	return (
		<Grid stackable>
			<Grid.Row>
				<Grid.Column>
					<a
						href="https://zinplex.co.za/collections/the-zinplex-range/skincare?sort_by=created-descending"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Image src="../assets/Zinplex.jpg" size="large" verticalAlign="middle" />
					</a>
				</Grid.Column>
			</Grid.Row>

			{/* <Grid.Row>
				<Grid.Column>
					<a
						href="https://www.cherrymelon.co.za"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Image
							src="../assets/BumpAround.gif"
							size="large"
							verticalAlign="middle"
						/>
					</a>
				</Grid.Column>
			</Grid.Row> */}
			{/* <Grid.Row>
				<Grid.Column>
					{/*<Advertisement style={{maxWidth:'100%'}} unit='medium rectangle' test='Advert'/>
					<a
						href={linkNowTwo}
						target='_blank'
						rel='noopener noreferrer'
						// onClick={lilletsClickHandler}
					>
						<Image src={imageNowTwo} size='large' verticalAlign='middle' />
					</a>
				</Grid.Column>
			</Grid.Row> */}
			{/* <Grid.Row>
				<Grid.Column>
					{/*<Advertisement style={{maxWidth:'100%'}} unit='medium rectangle' test='Advert'/>
					<a href={linkNowThree} target="_blank" rel="noopener noreferrer">
						<Image
							src={imageNowThree}
							size="large"
							verticalAlign="middle"
							onClick={JJClickHandler}
						/>
					</a>
				</Grid.Column>
			</Grid.Row> */}

			<Grid.Row>
				<Grid.Column>
					<a
						href="https://www.lilyrosecollection.co.za/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Image
							src="../assets/lily-rose-bag-of-plenty.gif"
							size="large"
							verticalAlign="middle"
						/>
					</a>
				</Grid.Column>
			</Grid.Row>
			{/* <Grid.Row>
				<Grid.Column>
					<a
						href="https://web.facebook.com/beacontreats"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Image
							src="../assets/Tigerbrands.gif"
							size="large"
							verticalAlign="middle"
						/>
					</a>
				</Grid.Column>
			</Grid.Row> */}
			{/* <Grid.Row>
				<Grid.Column>
					<a
						href="https://https://cryosave.co.za/baby-yum-yum-application/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Image
							src="../assets/cryosave-profmed-discount.gif"
							size="large"
							verticalAlign="middle"
						/>
					</a>
				</Grid.Column>
			</Grid.Row> */}
			<Grid.Row>
				<Grid.Column>
					<a
						href="https://www.mom2be.co.za/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Image
							src="../assets/Mom2B_Banner.gif"
							size="large"
							verticalAlign="middle"
						/>
					</a>
				</Grid.Column>
			</Grid.Row>
			{/* <Grid.Row>
				<Grid.Column>
					<a href={linkNowFour} target="_blank" rel="noopener noreferrer">
						<Image
							src={imageNowFour}
							size="large"
							verticalAlign="middle"
							onClick={JJClickHandler}
						/>
					</a>
				</Grid.Column>
			</Grid.Row> */}
			{/* <Grid.Row>
				<Grid.Column>
					<a
						href='https://www.woolworths.co.za/cat?Ntt=AVENT%20&Dy=1'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Image
							src='../assets/Philips.gif'
							size='large'
							verticalAlign='middle'
						/>
					</a>
				</Grid.Column>
			</Grid.Row> */}
			{/* <Grid.Row>
				<Grid.Column>
					<a
						href='https://www.3m.co.za/3M/en_ZA/Nexcare-CEEMEA/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Image
							src='../assets/NEXCARE-DUO-banner.gif'
							size='large'
							verticalAlign='middle'
						/>
					</a>
				</Grid.Column>
			</Grid.Row> */}
			<Grid.Row>
				<Grid.Column>
					<a
						href="https://www.happyevent.co.za/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Image
							src="../assets/Happy_Event-Oil-GIF.gif"
							size="large"
							verticalAlign="middle"
						/>
					</a>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column>
					{/*<Advertisement style={{maxWidth:'100%'}} unit='medium rectangle' test='Advert'/>*/}
					<a href={linkNow} target="_blank" rel="noopener noreferrer">
						<Image
							src={imageNow}
							size="large"
							verticalAlign="middle"
							onClick={mrdfoodClickHandler}
						/>
					</a>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column>
					<a
						href="https://babyyumyum.com/childhood-constipation/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Image src="../assets/Dulcolax.jpg" size="large" verticalAlign="middle" />
					</a>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default RightAdvert;
