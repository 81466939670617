import {
	PROFILEINFO_LOADING,
	PROFILEINFO_LOADING_COMPLETE,
	LOAD_PROFILEINFO,
} from "./myProfileConstents";
import { httpGet, httpPost } from "../../../app/common/utils/http";
import { toastr } from "react-redux-toastr";

export const loadProfileInfo = (values) => async (dispatch) => {
	try {
		await dispatch({
			type: PROFILEINFO_LOADING,
		});
		const myProfileInformation = await httpPost(
			`Person/GetPersonProfile`,
			values
		);
		await dispatch({
			type: LOAD_PROFILEINFO,
			payload: { ...myProfileInformation },
		});
	} catch (error) {
		//toastr.error('Error', 'Could not load your details');
	} finally {
		await dispatch({ type: PROFILEINFO_LOADING_COMPLETE });
	}
};
