import React, { Component, Fragment } from "react";
import {
	loadPageContent,
	loadPageContentDetail,
} from "./data/pageContentActions";
import { compose } from "redux";
import { connect } from "react-redux";
import LoadingComponent from "../../app/layout/LoadingComponent";
//import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import TopBanner from "../pageContent/TopBanner";
import RightAdvert from "../adverts/RightAdvert";
//import TopAdvert from "../adverts/TopAdvert";
import BottomBanner from "../pageContent/BottomBanner";
import { isMobileView } from "../../app/common/utils/common";
import { Button, Grid, Segment } from "semantic-ui-react";
import { withRouter } from "react-router";
import "./PageContentPage.css";
import { isLoggedIn } from "../auth/authActions";
import { loadPerson } from "../nav/Sidebar/data/sideBarActions";
import FullBanner from "./FullBanner";
import ScrollTop from "../scrollTop/ScrollTop";
import { reset } from "redux-form";
import ReactGA from "react-ga";
//import ReactDOM from "react-dom";

const mapState = (state) => ({
	loading: state.pageContent.loading,
	content: state.pageContent.content,
	loadingDetail: state.pageContent.loadingDetail,
	contentDetail: state.pageContent.contentDetail,
	person: state.sideBar.person,
});

const actions = {
	loadPageContent,
	loadPageContentDetail,
	loadPerson,
};

class PageContentPage extends Component {
	state = {
		SideBarNavOpen: false,
		pageTitel: "",
		currentImage: 0,
		images: [
			"../assets/mr-delivery-babyyumyum-mircosite-banner.png",
			"../assets/mr-delivery-babyyumyum-mircosite-banner.png",
		],
		imagesTwo: ["../assets/BumpAround.gif", "../assets/BumpAround.gif"],
		imagesThree: [
			"../assets/J&J_Baby_Yum_Yum_Banner_(1).png",
			"../assets/J&J_Baby_Yum_Yum_Banner_(1).png",
		],
		// imagesFour: [
		// 	"../assets/Calmettes-Post1.gif",
		// 	"../assets/Calmettes-Post2-zulu.gif",
		// 	"../assets/Calmettes-Post3.gif",
		// 	"../assets/Calmettes-Post4-zulu.gif",
		// ],
		links: ["https://www.mrdfood.com/", "https://www.mrdfood.com/"],
		linksTwo: ["https://www.cherrymelon.co.za", "https://www.cherrymelon.co.za"],
		linksThree: [
			"https://web.facebook.com/JohnsonsBabySA/",
			"https://web.facebook.com/JohnsonsBabySA/",
		],
		// linksFour: [
		// 	"https://www.babyyumyum.co.za/signs-your-stress-levels-are-dangerously-high/",
		// 	"https://www.babyyumyum.co.za/signs-your-stress-levels-are-dangerously-high/",
		// 	"https://www.babyyumyum.co.za/signs-your-stress-levels-are-dangerously-high/",
		// 	"https://www.babyyumyum.co.za/signs-your-stress-levels-are-dangerously-high/",
		// ],

		buttonColor: ["#0cb2a6", "White"],
		buttonTextColor: ["White", "#0cb2a6"],
		buttonBorderColor: ["White", "#0cb2a6"],
		curentEventTypeCode: "",
		babySize: "",
	};

	async componentDidMount() {
		//reset();

		this.interval = setInterval(() => this.switchImage(), 10000);
		this.props.loadPerson();
		const { person } = this.props;
		//localStorage.setItem("WeeksPregnant", person._cpInfo[0].WeeksPregnant);
		//localStorage.setItem("BabySize", person.BabySize[0]);

		//localStorage.setItem("BabySize", person.BabySize[0]);
		if (!isLoggedIn()) {
			this.props.history.push("/");
		}
		//this.setState({curentEventTypeCode: person._event[0].EventTypeCode})
		await this.props.loadPageContent(this.props.match.params.contentPageID);
		await this.props.loadPageContentDetail(this.props.match.params.contentPageID);
		// await this.props.loadPageContent(this.props.match.params.contentPageID);
		// await this.props.loadPageContentDetail(
		// 	this.props.match.params.contentPageID

		const { match, loading, content, contentDetail } = this.props;

		//const contentPageID = person._cpInfo[0].ContentPageID;

		const contentPageID = parseInt(match.params.contentPageID);
		const contentItem = content.filter(
			(c) => c.contentPageID === contentPageID.toString()
		);
		const contentDetailItem = contentDetail.filter(
			(c) => c.contentPageID === contentPageID.toString()
		);

		if (!loading) {
			let title = contentDetailItem[0].PageTitle;
			this.setState({ pageTitel: title });
			document.title = title;
			ReactGA.set({ PageTitle: contentDetailItem[0].PageTitle });
			ReactGA.pageview(`/content/${contentDetailItem[0].PageTitle}`);
		}
	}
	componentDidUpdate() {
		const { person } = this.props;
		//localStorage.setItem("WeeksPregnant", person.WeeksPregnant);
		//localStorage.setItem("BabySize", person.BabySize);
	}

	// to switch banner add image
	switchImage() {
		if (this.state.currentImage === 0) {
			this.setState({
				currentImage: 1,
			});
		} else {
			this.setState({
				currentImage: 0,
			});
		}
	}

	getHtml(content) {
		return ReactHtmlParser(
			content.replace(/\[\[SitePrefix\]\]/g, window.CONTENT_API_URL)
		);
	}

	collapsableHeadings(headings) {
		window.setTimeout(() => {
			headings.forEach((headingItem) => {
				const heading = document.getElementById(headingItem.HeadingID);
				const section = document.getElementById(headingItem.SectionID);
				heading.onclick = () => {
					if (section.classList.contains("heading-collapse-display")) {
						section.classList.add("heading-collapse-hide");
						section.classList.remove("heading-collapse-display");
					} else {
						section.classList.add("heading-collapse-display");
						section.classList.remove("heading-collapse-hide");
					}
				};
			});
		}, 500);
	}

	babyChangeClickHandler = async (idx) => {
		//this.props.loadPerson();
		localStorage.removeItem("BabySize");
		const { person } = this.props;
		console.log(person);
		localStorage.setItem("EDB", person._event[idx].EstimatedBirthDate);
		localStorage.setItem("EDD", person._event[idx].EstimatedDeliveryDate);
		localStorage.setItem("BEDD", person._event[idx].BenefitExpectedDueDate);
		localStorage.setItem("ContentPageID", person._cpInfo[idx].ContentPageID);
		localStorage.setItem("WeeksPregnant", person._cpInfo[idx].WeeksPregnant);
		localStorage.setItem("currentPersonType", person._event[idx].EventTypeCode);
		// this.setState({babySize: person.BabySize[idx]})
		localStorage.setItem("BabySize", person._cpInfo[idx].BabySize);
		//console.log(person.BabySize);
		// await this.contentChangeHandler(person._cpInfo[idx].ContentPageID);
		this.props.history.push(`/content/${person._cpInfo[idx].ContentPageID}`);
		//console.log(localStorage.getItem("ContentPageID"));
		if (idx === 1) {
			let newButtonColor = ["White", "#0cb2a6"];
			let newButtonTextColor = ["#0cb2a6", "White"];
			let newButtonBorderColor = ["#0cb2a6", "White"];
			localStorage.setItem("buttonColor", JSON.stringify(newButtonColor));
			localStorage.setItem("buttonTextColor", JSON.stringify(newButtonTextColor));
			localStorage.setItem(
				"buttonBorderColor",
				JSON.stringify(newButtonBorderColor)
			);
		} else {
			let newButtonColor = ["#0cb2a6", "White"];
			let newButtonTextColor = ["White", "#0cb2a6"];
			let newButtonBorderColor = ["White", "#0cb2a6"];
			localStorage.setItem("buttonColor", JSON.stringify(newButtonColor));
			localStorage.setItem("buttonTextColor", JSON.stringify(newButtonTextColor));
			localStorage.setItem(
				"buttonBorderColor",
				JSON.stringify(newButtonBorderColor)
			);
		}
		//window.location.reload();
	};

	babyChangBirthingBuddyClickHandler = (idx, event) => {
		const { person } = this.props;
		let EstimatedBirthDate;
		let EstimatedDeliveryDate;
		let BenefitExpectedDueDate;
		let ContentPageID;
		let WeeksPregnant;
		localStorage.setItem("currentPersonType", person._event[idx].EventTypeCode);

		localStorage.setItem("BabySize", person._cpInfo[idx].BabySize);

		for (let i = 0; i < person._event.length; i++) {
			if (person._event[i].EventID === event.EventID) {
				EstimatedBirthDate = person._event[i].EstimatedBirthDate;
				EstimatedDeliveryDate = person._event[i].EstimatedDeliveryDate;
				BenefitExpectedDueDate = person._event[i].BenefitExpectedDueDate;
				ContentPageID = person._cpInfo[i].ContentPageID;
				WeeksPregnant = person._cpInfo[i].WeeksPregnant;
				//console.log("hit2");
			}
		}
		//console.log(person);
		if (idx === 1) {
			let newButtonColor = ["#0cb2a6", "White"];
			let newButtonTextColor = ["White", "#0cb2a6"];
			let newButtonBorderColor = ["White", "#0cb2a6"];
			localStorage.setItem("buttonColor", JSON.stringify(newButtonColor));
			localStorage.setItem("buttonTextColor", JSON.stringify(newButtonTextColor));
			localStorage.setItem(
				"buttonBorderColor",
				JSON.stringify(newButtonBorderColor)
			);
		} else {
			let newButtonColor = ["#0cb2a6", "White"];
			let newButtonTextColor = ["White", "#0cb2a6"];
			let newButtonBorderColor = ["White", "#0cb2a6"];
			localStorage.setItem("buttonColor", JSON.stringify(newButtonColor));
			localStorage.setItem("buttonTextColor", JSON.stringify(newButtonTextColor));
			localStorage.setItem(
				"buttonBorderColor",
				JSON.stringify(newButtonBorderColor)
			);
		}

		localStorage.setItem("EDB", EstimatedBirthDate);
		localStorage.setItem("EDD", EstimatedDeliveryDate);
		localStorage.setItem("BEDD", BenefitExpectedDueDate);
		localStorage.setItem("ContentPageID", ContentPageID);
		localStorage.setItem("WeeksPregnant", WeeksPregnant);

		// await this.contentChangeHandler(person._cpInfo[idx].ContentPageID);
		this.props.history.push(`/content/${ContentPageID}`);
		//window.location.reload();
	};

	contentChangeHandler = async (newContentPageID) => {
		this.interval = setInterval(() => this.switchImage(), 10000);
		this.props.loadPerson();
		const { person } = this.props;
		//localStorage.setItem("WeeksPregnant", person._cpInfo[0].WeeksPregnant);
		//localStorage.setItem("BabySize", person.BabySize);
		if (!isLoggedIn()) {
			this.props.history.push("/");
		}

		await this.props.loadPageContent(newContentPageID.toString());
		await this.props.loadPageContentDetail(newContentPageID.toString());
		// await this.props.loadPageContent(this.props.match.params.contentPageID);
		// await this.props.loadPageContentDetail(
		// 	this.props.match.params.contentPageID

		const { match, loading, content, contentDetail } = this.props;

		//const contentPageID = person._cpInfo[0].ContentPageID;

		const contentPageID = parseInt(newContentPageID);

		const contentItem = content.filter(
			(c) => c.contentPageID === contentPageID.toString()
		);

		const contentDetailItem = contentDetail.filter(
			(c) => c.contentPageID === contentPageID
		);

		if (!loading) {
			let title = contentItem[0].PageTitle;
			this.setState({ pageTitel: title });
			document.title = title;
			ReactGA.set({ PageTitle: contentItem[0].PageTitle });
			ReactGA.pageview(`/content/${contentItem[0].PageTitle}`);
		}
	};
	render() {
		const { person } = this.props;
		const { match, loading, content, contentDetail } = this.props;
		const contentPageID = match.params.contentPageID;
		const contentItem = content.filter((c) => c.contentPageID === contentPageID);
		const contentDetailItem = contentDetail.filter(
			(c) => c.contentPageID === contentPageID
		);

		//console.log(person);
		let weeks;
		let event = "";
		let buttonColor = JSON.parse(localStorage.getItem("buttonColor"));
		let buttonTextColor = JSON.parse(localStorage.getItem("buttonTextColor"));
		let buttonBorderColor = JSON.parse(localStorage.getItem("buttonBorderColor"));
		let babySize = localStorage.getItem("BabySize");

		if (40 - localStorage.getItem("WeeksPregnant") / 7 >= 0) {
			event = "pregnant";
			try {
				weeks =
					Math.floor(40 - localStorage.getItem("WeeksPregnant") / 7).toString() +
					" weeks to go";
			} catch {
				this.props.history.push(
					this.props.history.push(`/content/${localStorage.getItem("ContentID")}`)
				);
			}
		} else {
			event = "child";
			weeks = Math.floor(40 - localStorage.getItem("WeeksPregnant") / 7);
			if (weeks === -1) {
				weeks = weeks * (-1).toString() + " Week Old";
			} else {
				weeks = (Math.round(weeks / 4) + 1) * (-1).toString() + " Months Old";
				if (Math.floor(40 - localStorage.getItem("WeeksPregnant") / 7) < -52) {
					event = "toddler";
				}
			}
		}
		//console.log(person);
		return (
			<Fragment>
				{/*         
        <Helmet>
          <title>
            
              {this.state.pageTitel}
              
             
          </title>
        </Helmet> */}

				<div className="fullBannerContentPage">
					<FullBanner />
				</div>

				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={50}>
							<TopBanner weeks={weeks} event={event} babySize={babySize} />
						</Grid.Column>
					</Grid.Row>

					<Grid.Row>
						<Grid.Column width={12}>
							{contentPageID === 0 && (
								<div className="noContentToLoad">
									Welcome to the MyMomentum BabyYumYum maternity programme. You can look
									forward to viewing specific content on your baby's growth and
									development from week 3 of your pregnancy
								</div>
							)}
							{contentPageID === -1 && (
								<div className="noContentToLoad">
									Welcome to the MyMomentum BabyYumYum maternity programme. You can look
									forward to viewing specific content on your baby's growth and
									development from week 3 of your pregnancy
								</div>
							)}

							{(loading || contentItem.length === 0) &&
								(contentPageID !== 0 || contentPageID !== -1) && <LoadingComponent />}

							{!loading &&
								contentItem.length !== 0 &&
								person._event !== undefined &&
								person._cpInfo.length > 1 &&
								(contentPageID !== 0 || contentPageID !== -1) &&
								person.PersonType !== "BB" && (
									<div>
										<Grid textAlign="center">
											<Grid.Row columns={2}>
												{person._event.map((event, idx) => (
													<Grid.Column>
														<Segment
															style={{
																color: buttonTextColor[idx],
																backgroundColor: buttonColor[idx],
																borderWidth: "2px",
																borderColor: buttonBorderColor[idx],
															}}
															onClick={() => this.babyChangeClickHandler(idx)}
														>
															{event.EventTypeCode == "P" && <div>Baby</div>}
															{event.EventTypeCode == "C" && <div>Baby</div>}
															{event.EventTypeCode == "T" && <div>Toddler</div>}
														</Segment>
													</Grid.Column>
												))}
											</Grid.Row>
										</Grid>
										{/* {this.getHtml(contentItem[0].Content)}
										{this.collapsableHeadings(contentItem[0].ContentHeadings)} */}
									</div>
								)}

							{/* When a birthing buddy is logged in */}

							{!loading &&
								contentItem.length !== 0 &&
								(contentPageID !== 0 || contentPageID !== -1) && (
									<div>
										{person._bbLinkedEvent !== undefined &&
											person._bbLinkedEvent.length > 1 && (
												<Grid textAlign="center">
													<Grid.Row columns={2}>
														{person._bbLinkedEvent != undefined &&
															person._bbLinkedEvent.map((event, idx) => (
																<Grid.Column>
																	<Segment
																		style={{
																			color: buttonTextColor[idx],
																			backgroundColor: buttonColor[idx],
																			borderWidth: "2px",
																			borderColor: buttonBorderColor[idx],
																		}}
																		onClick={() =>
																			this.babyChangBirthingBuddyClickHandler(idx, event)
																		}
																	>
																		{event.EventTypeCode == "P" && <div>Baby</div>}
																		{event.EventTypeCode == "T" && <div>Toddler</div>}
																	</Segment>
																</Grid.Column>
															))}
													</Grid.Row>
												</Grid>
											)}
									</div>
								)}
							{!loading &&
								contentItem.length !== 0 &&
								(contentPageID !== 0 || contentPageID !== -1) && (
									<Fragment style={{ zIndex: -1 }}>
										{this.getHtml(contentItem[0].Content)}
										{this.collapsableHeadings(contentItem[0].ContentHeadings)}
									</Fragment>
								)}
						</Grid.Column>

						<Grid.Column width={4}>
							{this.state.currentImage !== undefined && (
								<RightAdvert
									mrdfoodClickHandler={this.mrdfoodClickHandler}
									lilletsClickHandler={this.lilletsClickHandler}
									veetClickHandler={this.veetClickHandler}
									detolClickHandler={this.detolClickHandler}
									JJClickHandler={this.JJClickHandler}
									sweepsouthClickHandler={this.sweepsouthClickHandler}
									imageNow={this.state.images[this.state.currentImage]}
									linkNow={this.state.links[this.state.currentImage]}
									imageNowTwo={this.state.imagesTwo[this.state.currentImage]}
									linkNowTwo={this.state.linksTwo[this.state.currentImage]}
									imageNowThree={this.state.imagesThree[this.state.currentImage]}
									linkNowThree={this.state.linksThree[this.state.currentImage]}
									// imageNowFour={this.state.imagesFour[this.state.currentImage]}
									// linkNowFour={this.state.linksFour[this.state.currentImage]}
								/>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<br />
				<p className="been">
					<b>WHAT'S BEEN</b>
				</p>
				<p className="come">
					<b>WHAT'S NEXT</b>
				</p>

				<Grid.Row>
					<Grid.Column>
						{loading === 0}
						{!loading !== 0 && contentDetailItem.length > 0 && (
							<Button.Group className="buttonBottom " attached="bottom">
								{contentDetailItem[0].Previous_ContentPageID !== 0 &&
									localStorage.getItem("currentPersonType") === "P" && (
										<Button
											onClick={() =>
												this.props.history.push(
													`/content/${contentDetailItem[0].Previous_ContentPageID}`
												)
											}
											className="byy-pink byy-text-white"
											labelPosition="left"
											icon="left chevron"
											size={isMobileView() ? "large" : "massive"}
											// size='massive'
											textAlign="left"
											content={contentDetailItem[0].Previous_ContentPageID_Title}
										/>
									)}
								{contentDetailItem[0].Previous_ContentPageID !== 0 &&
									localStorage.getItem("currentPersonType") === "T" &&
									contentDetailItem[0].Next_ContentPageID > 48 && (
										<Button
											onClick={() =>
												this.props.history.push(
													`/content/${contentDetailItem[0].Previous_ContentPageID}`
												)
											}
											className="byy-pink byy-text-white"
											labelPosition="left"
											icon="left chevron"
											size={isMobileView() ? "large" : "massive"}
											// size='massive'
											textAlign="left"
											content={contentDetailItem[0].Previous_ContentPageID_Title}
										/>
									)}
								{contentDetailItem[0].Next_ContentPageID !== 0 &&
									localStorage.getItem("currentPersonType") === "P" &&
									contentDetailItem[0].Next_ContentPageID <= 87 && (
										<Button
											onClick={() =>
												this.props.history.push(
													`/content/${contentDetailItem[0].Next_ContentPageID}`
												)
											}
											className="byy-pink byy-text-white"
											labelPosition="right"
											icon="right chevron"
											size={isMobileView() ? "large" : "massive"}
											// size='massive'
											textAlign="right"
											marginLeft="300"
											content={contentDetailItem[0].Next_ContentPageID_Title}
										/>
									)}
								{contentDetailItem[0].Next_ContentPageID !== 0 &&
									localStorage.getItem("currentPersonType") === "T" && (
										<Button
											onClick={() =>
												this.props.history.push(
													`/content/${contentDetailItem[0].Next_ContentPageID}`
												)
											}
											className="byy-pink byy-text-white"
											labelPosition="right"
											icon="right chevron"
											size={isMobileView() ? "large" : "massive"}
											// size='massive'
											textAlign="right"
											marginLeft="300"
											content={contentDetailItem[0].Next_ContentPageID_Title}
										/>
									)}
							</Button.Group>
						)}

						<BottomBanner />
					</Grid.Column>
				</Grid.Row>
				<ScrollTop />
			</Fragment>
		);
	}
}

export default compose(withRouter, connect(mapState, actions))(PageContentPage);
