import { createReducer } from '../../../app/store/createReducer';

import {
    PROFILEINFO_LOADING ,
    PROFILEINFO_LOADING_COMPLETE,
    LOAD_PROFILEINFO
} from './myProfileConstents';

function getInitialState() {
    return {
      loading: false,
    }
  }
  
  const initialState = getInitialState();
  
  const loading = (state) => {
    return {
      ...state,
      loading: true
    };
  };
  
  const loadingCompleted = (state) => {
    return {
      ...state,
      loading: false
    };
  };
  
  const loadProfileInformation = (state, payload) => {
    return {
      ...state,
      profileInformation: payload
    };
  };

  export default createReducer(initialState, {
    [PROFILEINFO_LOADING]: loading,
    [PROFILEINFO_LOADING_COMPLETE]: loadingCompleted,
    [LOAD_PROFILEINFO]: loadProfileInformation,
  });

