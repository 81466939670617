import {
	QUESTIONS_LOADING,
	QUESTIONS_LOADING_COMPLETE,
	LOAD_QUESTIONS,
	SUBMITTING_ANSWER,
} from "./progressiveProfilingQuestionsConstants";
import { toastr } from "react-redux-toastr";
import { httpGet, httpPost } from "../../../app/common/utils/http";

export const loadQuestion = (edd) => async (dispatch, getState) => {
	await dispatch({ type: QUESTIONS_LOADING });
	try {
		if (edd === null || edd === undefined) {
			edd = getState().questions.edd;
		}
		const questions = await httpGet(
			`ProfilingQuestions/GetProfilingQuestions?ExpectedDeliveryDate=${edd}`
		);

		await dispatch({
			type: LOAD_QUESTIONS,
			payload: questions,
		});
	} catch (error) {
	} finally {
		await dispatch({ type: QUESTIONS_LOADING_COMPLETE });
	}
};

const submitting = (answers) => {
	localStorage.setItem("SurveyQuestionID", localStorage.getItem("QuestionID"));
	localStorage.setItem("SurveyAnswerID", answers.SurveyAnswerID);
	localStorage.setItem("SurveyAnswerDesc", answers.SurveyAnswerDesc);
};

export const submitAnswer = (submitDto) => async (dispatch) => {
	await dispatch({ type: QUESTIONS_LOADING });
	try {
		const answer = await httpPost("ProfilingQuestions/SubmitAnswer", submitDto);

		await dispatch({ type: SUBMITTING_ANSWER });
		return answer.ContentPageID;
	} catch (error) {
		toastr.error("Error", error);

		throw error;
	} finally {
		await dispatch({ type: QUESTIONS_LOADING_COMPLETE });
	}
};
