import React, { Fragment } from "react";
import "./Pebbles.css";
import Counter from "../../app/common/form/Counter";
import { Field } from "redux-form";
import { Segment, Grid, Image } from "semantic-ui-react";
import _ from "lodash";
import PebbleAccordion from "./PebbleAccordion";
import PebbleAccordionBaby from "./PebbleAccordionBaby";

const PebblesFields = ({
	accordionIndicator,
	person,
	pebbles,
	values,
	changePebbleValue,
	pebbleAvailable,
	maxCounter,
	ballanceInsufficient,
	initialBasket,
	val,
	bagCheckMax,
	giftCheckMax,
	pebblesLeft,
	deliveryCheck,
}) => {
	console.log(pebbles);
	let blue = _.times(pebbleAvailable, (i) => (
		<Grid.Column key={i}>
			{/* <div className="blueCircle" /> */}
			<Image src="../assets/Pebble.png" size="medium" />
		</Grid.Column>
	));

	return (
		<Fragment>
			<Segment>
				<container className="area">
					<h1 className="title">Redeem your Pebbles</h1>
					<h3 className="byy-text-pink">
						How it works?
						<br />
					</h3>
					<p>
						As a Profmed member you receive a specific number of pebbles based on your
						benefit option. The benefits available can be redeemed by exchanging the
						specified number of pebbles. The benefit choice is entirely yours! <br />{" "}
						Once you select the redeem button at the bottom of the page, the benefit
						is allocated and where applicable delivery will be arranged.
					</p>
					<div>
						{accordionIndicator === "C" && (
							<div>
								<PebbleAccordionBaby />
							</div>
						)}
						{accordionIndicator === "P" && (
							<div>
								<PebbleAccordionBaby />
							</div>
						)}
						{accordionIndicator === "T" && (
							<div>
								<PebbleAccordion />
							</div>
						)}

						<br />
					</div>
					<container style={{ paddingBottom: 5, paddingRight: 5 }}>
						<h3 className="byy-text-pink">
							Benefit selector
							<br />
						</h3>

						<Grid relaxed columns={10}>
							{blue}
						</Grid>
					</container>
					<p className="accordion">
						<b className="byy-text-pink accordion">Number of pebbles you have:</b>{" "}
						{pebbleAvailable}
					</p>

					{pebbles.benefitsDetails.QualifiedBenefitTypes.map((benefit, index) => (
						<Grid>
							<Grid.Row>
								<Field
									//{bagCheckMax&&giftCheckMax}
									pebbles={pebbles}
									pebblesLeft={pebblesLeft}
									accordionIndicator={accordionIndicator}
									index={index}
									//name={benefit.BenefitID}
									label={benefit.BenefitDesc}
									//key={benefit.BenefitID}
									id={benefit.BenefitID}
									val={val}
									initialBasket={initialBasket}
									component={Counter}
									pebbleCost={benefit.BenefitPebbleCost}
									pebbleAvailable={pebbleAvailable}
									values={values}
									actionTypeCode={benefit.ActionTypeCode}
									changePebbleValue={changePebbleValue}
									maxCounter={maxCounter}
									deliveryCheck={deliveryCheck}
								/>
							</Grid.Row>
						</Grid>
					))}
				</container>
				<br />
			</Segment>
		</Fragment>
	);
};

export default PebblesFields;
