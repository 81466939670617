import React, { Component } from "react";
import { isMobileView } from "../../app/common/utils/common";
import { Button, Grid, Form, Header, Radio } from "semantic-ui-react";
import { GridColumn } from "semantic-ui-react";
import PopupEDD from "./PopupEDD";
import { loadPerson } from "../../features/nav/Sidebar/data/sideBarActions";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { submitDates, notPregnant } from "./data/submitNewDateAction";

import { reduxForm } from "redux-form";
import { loadQuestion } from "../progressiveProfiling/data/progressiveProfilingQuestionsActions";
import { toastr } from "react-redux-toastr";

const mapState = (state) => ({
	person: state.sideBar.person,
	questions: state.progressiveProfiling.questions,
});

const actions = {
	loadPerson,
	submitDates,
	loadQuestion,
	notPregnant,
};

class VerifyQuestion extends Component {
	componentDidMount() {
		this.props.loadPerson();
	}

	state = {
		showEDDPopup: false,
		showBDPopup: false,
		saved: false,
		notPregnant: false,
		submitNow: false,
	};

	handleSubmitDates = async (value) => {
		let sendValues;
		let sendInactive;
		const { person } = this.props;
		sendValues = {
			EstimatedDeliveryDate: localStorage.getItem("EDD"),
			EstimatedBirthDate: localStorage.getItem("EDB"),
		};
		sendInactive = {
			PersonStatusCode: "I",
		};

		try {
			this.setState({ submitting: true });
			if (this.state.value === "correct") {
				await this.props.submitDates(sendValues);
				await this.props.loadQuestion(localStorage.getItem("BEDD"));

				const { questions } = this.props;
				//await this.props.submitDates(sendValues);
				localStorage.setItem("EDD", person.EstimatedDeliveryDate);

				if (questions.ProfilingQuestions.length <= 0) {
					this.props.history.push(
						this.props.history.push(`/content/${person.ContentPageID}`)
					);
				} else {
					this.props.history.push("/progressiveProfiling");
				}
			} else if (this.state.value === "notPregnant") {
				await this.props.notPregnant(sendInactive);
				this.setState({ notPregnant: true });
			}
		} catch {
		} finally {
			this.setState({ submitting: false });
		}
	};

	handleChange = (e, { value }) => this.setState({ value });

	togglePopupEDD = () => {
		this.setState((prevState) => {
			return { showEDDPopup: !prevState.showEDDPopup };
		});
	};

	togglePopupBD = () => {
		this.setState((prevState) => {
			return { showBDPopup: !prevState.showBDPopup };
		});
	};

	submitClickHandler = () => {
		const { person, questions } = this.props;
		if (this.state.value === "correct") {
			if (questions.ProfilingQuestions.length <= 0) {
				this.props.history.push(
					this.props.history.push(`/content/${person.ContentPageID}`)
				);
			} else {
				this.props.history.push("/progressiveProfiling");
			}
		} else if (this.state.value === "wrong") {
			this.setState((prevState) => {
				return { showEDDPopup: !prevState.showEDDPopup };
			});
		} else if (this.state.value === "notPregnant") {
			this.setState((prevState) => {
				return { notPregnant: !prevState.notPregnant };
			});
		} else if (this.state.value === "givenBirth") {
			this.props.history.push("/myProfile");
			toastr.info(
				"Please fill in the section Our new bundle of joy in the profile page."
			);
		}
	};
	saveEDDClickHandler = (value) => {
		localStorage.setItem("updatedEDD", value);
		this.setState((prevState) => {
			return {
				showEDDPopup: !prevState.showEDDPopup,
			};
		});
		this.setState((prevState) => {
			return {
				saved: !prevState.saved,
			};
		});
	};

	correctClickHandler = () => {
		//  if (!notPregnant){
		this.setState({ submitNow: true });
		//}
	};

	wrongClickHandler = () => {
		this.setState({ submitNow: false });
	};

	render() {
		const { handleSubmit, submitting } = this.props;
		let notPregnant;
		let popupEDD;
		let popupDB;
		let firstPage;
		let updateDueDate;
		let weeks = Math.ceil(localStorage.getItem("WeeksPregnant") / 7);
		let fullDate = localStorage.getItem("EDD");
		let date = fullDate
			.substring(0, 10)
			.replace("-", "/")
			.replace("-", "/");
		if (this.state.showEDDPopup) {
			popupEDD = (
				<PopupEDD
					submitClickHandler={this.togglePopupEDD}
					saveEDDClickHandler={this.saveEDDClickHandler}
				/>
			);
		}

		if (!this.state.saved && !this.state.notPregnant) {
			firstPage = (
				<Grid verticalAlign='middle' stackable>
					<GridColumn>
						<Header
							as='h2'
							className='byy-text-darkblue'
							textAlign='left'
							style={{ fontWeight: "normal" }}
						>
							<p>
								<b>Congratulations</b> {localStorage.getItem("FirstName")}{" "}
								<b>!</b>
							</p>
						</Header>
						<Form
							autoComplete='off'
							onSubmit={handleSubmit(this.handleSubmitDates)}
							className='byy-text-darkblue'
							style={{ textAlign: "left", fontWeight: "bold", margin: "2" }}
						>
							<Header
								as='h5'
								className='byy-text-darkblue'
								textAlign='left'
								style={{ fontWeight: "normal" }}
							>
								<p>
									We estimate you are {weeks} weeks pregnant based on your
									<br />
									<b>Full-Term Estimated Due Date {date}</b>
								</p>
							</Header>
							<Form.Field />
							<Form.Field>
								<Radio
									label='Yes, this is correct'
									name='radioGroup'
									value='correct'
									checked={this.state.value === "correct"}
									onChange={this.handleChange}
									onClick={this.correctClickHandler}
								/>
							</Form.Field>
							<Form.Field>
								<Radio
									label='The due date has changed'
									name='radioGroup'
									value='wrong'
									checked={this.state.value === "wrong"}
									onChange={this.handleChange}
									onClick={this.wrongClickHandler}
								/>
							</Form.Field>
							<Form.Field>
								<Radio
									label='No longer pregnant'
									name='radioGroup'
									value='notPregnant'
									checked={this.state.value === "notPregnant"}
									onChange={this.handleChange}
									onClick={this.correctClickHandler}
								/>
							</Form.Field>

							<Form.Field>
								<Radio
									label='I have given birth'
									name='radioGroup'
									value='givenBirth'
									checked={this.state.value === "givenBirth"}
									onChange={this.handleChange}
									onClick={this.wrongClickHandler}
								/>
							</Form.Field>

							{this.state.submitNow && (
								<Button
									style={{ marginTop: 20, marginLeft: 0 }}
									className='ui red button'
									content='Submit'
									position='Submit'
									loading={submitting}
									disabled={submitting}
								/>
							)}

							{!this.state.submitNow && (
								<Button
									style={{ marginTop: 20, marginLeft: 0 }}
									className='ui red button'
									content='Submit'
									position='absolute'
									floated='left'
									onClick={this.submitClickHandler}
									//pass {this.state.value} to API once ready
								/>
							)}
						</Form>
					</GridColumn>
				</Grid>
			);
		}

		if (this.state.notPregnant) {
			notPregnant = (
				<Header
					as='h2'
					className='byy-text-darkblue'
					textAlign='left'
					verticalAlign='middle'
					style={{ fontWeight: "normal" }}
				>
					<br />
					<br />
					<br />
					<p>
						<b>We are so sorry!</b>
					</p>
					<p>
						<b>Please expect a phone call from us.</b>
					</p>
				</Header>
			);
		}

		return (
			<div style={{ position: "relative" }}>
				<div
					className='byy-lighterblue'
					style={{
						borderRadius: "50%",
						position: "absolute",
						width: 350,
						height: 350,
						margin: isMobileView() ? "20px 0 0 0px" : "50px 0 0 50px",
					}}
				/>
				<div
					style={{
						borderRadius: "50%",
						position: "absolute",
						width: 300,
						height: 260,
						padding: "28px 0 0 100px",
						margin: isMobileView() ? "20px 0 0 1px" : "50px 0 0 50px",
					}}
				>
					{firstPage}
					{updateDueDate}
					{notPregnant}
					{popupEDD}
					{popupDB}
				</div>
			</div>
		);
	}
}

export default compose(
	withRouter,
	connect(mapState, actions),
	reduxForm({
		form: "VerifyQuestion",
		enableReinitialize: true,
		destroyOnUnmount: false,
	})
)(VerifyQuestion);
