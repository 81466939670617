import React from "react";
import "./TermsConditionsPage.css";
//import FullBanner from "../pageContent/FullBanner";
//import ScrollTop from "../scrollTop/ScrollTop";
//import { Accordion, Icon } from "semantic-ui-react";

const PrivacyPolicyText = () => {
	return (
		<div>
			<p className="subHeadingTermsConditions">1. INTRODUCTION</p>
			<p>
				This privacy policy is complementary to, and should be read and understood
				together with, the terms and conditions of use of BabyYumYum, all of its
				stakeholders, subsidiaries the subsidiaries of BabyYumYum’s subsidiaries and
				respective holding companies, the subsidiaries of such holding companies,
				divisions and affiliates and partners (“BabyYumYum”). Reference to
				BabyYumYum in this Privacy Policy shall be deemed to be a reference to the
				relevant legal entity which operates the website concerned.
			</p>
			<p className="subHeadingTermsConditions">
				2. THE GENERAL PRINCIPLES OF OUR PRIVACY POLICY
			</p>
			<p>
				• This privacy policy covers how we treat your personal information
				collected electronically when you use the BabyYumYum websites (the “Sites”),
				register or apply online for any BabyYumYum products or services, or when
				you contact BabyYumYum electronically.
				<br />
				• BabyYumYum respects your privacy and your personal information and for
				this reason, we take care to protect your personal information and to keep
				it confidential.
				<br />
				• When dealing with your personal information we apply the following –<br />
				<ul>
					<li>
						BabyYumYum will take all reasonable care to protect your personal
						information, subject to legal obligations that we may be subject to.
					</li>
					<li>
						BabyYumYum will not use your personal information contrary to any
						enforceable legal provision. We will seek your permission where required.
					</li>
				</ul>
				• By using the Sites, registering or applying online for any BabyYumYum
				products or services, or contacting BabyYumYum electronically, you provide
				BabyYumYum with your express written permission to share your personal
				information within BabyYumYum, in the ordinary course of BabyYumYumʹs
				business, including for purposes of providing you with the relevant products
				and services. You may revoke this consent in writing at any time.
				<br />
			</p>
			<p className="subHeadingTermsConditions">
				3. WHAT WE MEAN BY PERSONAL INFORMATION
			</p>
			<p>
				Personal information refers to information that identifies or relates
				specifically to you which we acquire directly from you, for example, your
				name, age and identity number, marital status, spoken language, contact
				details or any other information you use to register for the Sites and all
				related information which you provide to us will also be regarded as
				personal information.
			</p>
			<p className="subHeadingTermsConditions">
				4. HOW WE COLLECT YOUR PERSONAL INFORMATION{" "}
			</p>
			<p>
				Whenever you use the Sites, complete an application form, contact BabyYumYum
				electronically, or use one of the products, services, facilities, tools or
				utilities offered by BabyYumYum on the Sites, BabyYumYum will collect your
				personal information.
			</p>
			<p className="subHeadingTermsConditions">
				5. WHY WE COLLECT AND USE PERSONAL INFORMATION
			</p>
			<p className="bulletPointsTermsConditions">
				• In order to make your use of the Sites and the products, services,
				facilities, tools or utilities offered on the Sites as informative and
				successful as possible, it is necessary for BabyYumYum to find out exactly
				what you need and want. The following are some of the reasons (i.e.
				disclosed reasons) why BabyYumYum would collect your personal information –
				<br />
				<ul>
					<li>for BabyYumYum to process your instructions or requests;</li>
					<li>
						for BabyYumYum to ensure that we meet your needs, BabyYumYum may collect
						and analyse your personal information and combine all the information that
						we have about you for research and statistical purposes. We may also use
						your personal information to personalise and tailor our services to meet
						your needs;
					</li>
					<li>
						for BabyYumYum to send you our newsletters, promotional material, relevant
						advertising or details which we think may be of interest to you subject to
						applicable laws, once BabyYumYum has collected and analysed your personal
						information;
					</li>
					<li>to conduct market research;</li>
					<li>
						to provide your details to the seller of the products and/or services you
						wish to inspect/ purchase;
					</li>
					<li>to contact you in the future at your request; and</li>
					<li>
						to conduct academic research. This research is conducted to evaluate and
						improve BabyYumYum’s product offerings. You are advised that information
						may be shared with third parties such as academics and researchers. All
						information collected for research purposes will be kept strictly
						confidential and all data will be depersonalised. No personal information
						will be made available to a third party without your written consent. If
						we publish the results of this research, you will not be identified by
						name.
					</li>
				</ul>
				• Your privacy is important to us and we will therefore not sell, rent or
				provide your personal information to unauthorised third parties for their
				independent use, without your consent. If at any stage after you have given
				BabyYumYum your consent, you no longer wish BabyYumYum to use or share your
				personal information, you may at any stage withdraw your consent.
				<br />
				• You accept that we may store your personal information outside of the
				region or country that you may submit or use it in.
				<br />
				• We may enter into business arrangements with third parties and as such our
				customer base belongs to us and is one of our more valued assets. If we do
				this, customer information will be one of our transferrable assets.
				<br />
				• We collect and freely use and distribute non-identifying and aggregate
				(added up) information for the purposes of upgrading our Sites and making it
				easier for you to use. This information does not include your personal
				Information.
				<br />
			</p>
			<p>
				<p className="subHeadingTermsConditions">
					6. PROTECTION OF YOUR PERSONAL INFORMATION
				</p>
				<p className="bulletPointsTermsConditions">
					• BabyYumYum values the information that you choose to provide and will
					take reasonable steps to protect your personal information from loss,
					misuse or unauthorised alteration. The information BabyYumYum has,
					concerning BabyYumYum clients, is stored in databases that have built-in
					safeguards to ensure the privacy and confidentiality of that information.
					<br />
					• When you use the products, services, facilities, tools or utilities
					provided by BabyYumYum on the Sites, you may be given an access number,
					username, password and/or personal identification number (“PIN”). You must
					always keep your username, access card, password and/or PIN a secret and
					ensure that you do not disclose it to anyone.
					<br />
					• BabyYumYum cannot be held responsible for security breaches occurring on
					your electronic device (personal computer or other electronic device used
					to browse the Sites), which may result due to the lack of adequate virus
					protection software or spyware that you may inadvertently have installed on
					your device.
					<br />
				</p>
			</p>

			<p className="subHeadingTermsConditions">
				7. CORRECTION OF PERSONAL INFORMATION
			</p>

			<p className="bulletPointsTermsConditions">
				If you ever want to update or correct any of your personal information held
				by BabyYumYum, you can e-mail us or you can phone us directly.
			</p>

			<p className="subHeadingTermsConditions">
				8. PERSONAL INFORMATION HELD BY OR DISCLOSED BY YOU OR BABYYUMYUM TO A THIRD
				PARTY
			</p>
			<p className="bulletPointsTermsConditions">
				Because BabyYumYum is not responsible for any representations, information,
				warranties or content on any third party website (including third party
				websites linked to the Sites, websites facilitated by us or websites that
				serve as social networks like Facebook or Twitter), BabyYumYum does not
				exercise control over the privacy policies of these third parties and you
				should refer to the privacy policy of these third parties to see how they
				protect your privacy.
			</p>

			<p className="subHeadingTermsConditions">9. COOKIES</p>
			<p className="bulletPointsTermsConditions">
				BabyYumYum uses cookies, including similar technologies. The word “cookie”
				refers to information that is sent from the Sites to your hard drive, where
				it is saved. In this way, the next time you use the Sites, BabyYumYum will
				know who you are and that you have visited the Sites before. We also collect
				information about how you use the website, your preferences and past
				browsing history. Cookies allow us to provide you with an enhanced
				experience and customised content and services. We are able to provide our
				advertisers with an ability to direct relevant advertisements to their
				audiences. We are able to track the responses to advertising. You are able
				to control advertising cookies and remove them through the tools on your web
				browser. If you require more information on this aspect you may visit
				www.allaboutcookies.org. <br />
			</p>

			<p className="subHeadingTermsConditions">10. LOCATION</p>
			<p className="bulletPointsTermsConditions">
				BabyYumYum may obtain or infer information about your location. We use such
				information to infer the demographics of our users and to provide you with
				enhanced services, and appropriate advertising content that may be relevant
				to you and to obtain aggregate statistics for our advertising customers.
			</p>
			<p className="subHeadingTermsConditions">
				11. CHANGES TO THIS PRIVACY POLICY
			</p>
			<p className="bulletPointsTermsConditions">
				• BabyYumYum may amend this privacy policy from time to time. BabyYumYum
				will carry details of changes to its policy on its websites. We recommend
				that you familiarise yourself with this privacy policy regularly.
				<br />
				• The current version of this privacy policy will govern the respective
				rights and obligations between you and BabyYumYum each time that you access
				and use the Sites.
				<br />
			</p>
			<p className="subHeadingTermsConditions">
				12. WHICH LAWS APPLY TO THIS PRIVACY POLICY
			</p>
			<p className="bulletPointsTermsConditions">
				This privacy policy is governed by the laws of the Republic of South Africa,
				and you consent to the jurisdiction of the South African courts in respect
				of any dispute which may arise out of or in connection with the formation,
				interpretation, substance or application of this privacy policy.
			</p>
		</div>
	);
};

export default PrivacyPolicyText;
