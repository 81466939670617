import React from "react";
import {
	Container,
	Grid,
	List,
	Segment,
	// Divider,
	// Header,
	// Image,
} from "semantic-ui-react";
import { withRouter } from "react-router";
import { compose } from "redux";

let currentYear = new Date();
currentYear = currentYear.getFullYear();

const Footer = ({ termsConditionsClickHandler }) => (
	<Segment
		className="byy-teal"
		inverted
		vertical
		style={{ margin: "15em 0em 0em" }}
	>
		<Container textAlign="center">
			<Grid divided inverted stackable>
				<Grid.Column width={5}>
					<List link inverted>
						<List.Item
							as="p"
							className="byy-text-white"
							style={{ fontWeight: "bold", opacity: 1.0, padding: "5px" }}
						>
							Profmed: BabyYumYum {currentYear}
						</List.Item>
					</List>
				</Grid.Column>
				<Grid.Column width={6}>
					<List>
						<List.Item
							as="p"
							className="byy-text-white"
							style={{ fontWeight: "bold", opacity: 1.0, padding: "5px" }}
						>
							<a onClick={termsConditionsClickHandler} style={{ color: "#ffffff" }}>
								Terms &amp; Conditions
							</a>
						</List.Item>
					</List>
				</Grid.Column>
				<Grid.Column width={5}>
					<List>
						<List.Item
							className="byy-text-white"
							style={{ fontWeight: "bold", opacity: 1.0, padding: "5px" }}
						>
							Call us: For Baby{" "}
							<a
								href="tel:087 092 9606"
								style={{ color: "#ffffff", fontWeight: "bold" }}
							>
								087 092 9606{" "}
							</a>
							<br />
							For Toddler{" "}
							<a
								href="tel:087 897 0369"
								style={{ color: "#ffffff", fontWeight: "bold" }}
							>
								087 897 0369{" "}
							</a>
						</List.Item>
					</List>
				</Grid.Column>
			</Grid>
		</Container>
	</Segment>
);

export default compose(withRouter)(Footer);
